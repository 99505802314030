import React, { Component } from 'react';
import './css/App.css';
//import styles from './css/App.css'; //for customizing reactStrap styles
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Button, ButtonToolbar, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withLocalize, Translate } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import translations from "./json/translations.json";
import SuperTreeview from 'react-super-treeview';
import './css/ReactSuperTreeViewStyle.css';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { Typography, Select, MenuItem } from '@material-ui/core';
import * as properties from './config/properties.js';
import FuzzyPicker from './FuzzyPicker'
import Slider, { createSliderWithTooltip } from 'rc-slider';
import SortOrderSelector2D from './SortOrderSelector2D';

import 'rc-slider/assets/index.css';

function PickerModel() {
  this.list = [];
  this.values = [];
}

//For tooltip for slider:
const SliderWithTooltip = createSliderWithTooltip(Slider);

const styles = theme => ({
  toggleContainer: {
    height: 151,
    width: 158,
    backgroundColor: 'transparent',
    verticalAlign: 'top',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 0}px`,
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: `${theme.spacing.unit}px 0`,
    background: theme.palette.background.default,
  },
});

const theme = createMuiTheme({
  overrides: {
    MuiToggleButton: {
      root: {
            border: '1px solid #b87333 !important',
            backgroundColor: '#303030 !important',
            fontSize: '12px !important',
            color: '#b87333 !important',
            lineHeight: '22px !important',
            height: '22px !important',
            verticalAlign: 'middle  !important',
            align: 'middle  !important',
            padding: '0px 0px 0px 0px !important',
      },
      selected: {
            border: '1px solid #b87333 !important',
            backgroundColor: '#b87333 !important',
            fontSize: '12px !important',
            color: '#303030 !important',
            lineHeight: '22px !important',
            height: '22px !important',
            verticalAlign: 'middle  !important',
            align: 'middle  !important',
            padding: '0px 0px 0px 0px !important',
      },
    },
    MuiPaper: {
      root: {
            backgroundColor: '#b87333 !important',
      },
    },
    MuiList: {
      padding: {
            padding: '0px 0px 0px 0px !important',
      },
    },
  },
  typography: { useNextVariants: true },
});

function OverridesCss() {
  return (
    <MuiThemeProvider theme={theme}>
      <Button>Overrides CSS</Button>
    </MuiThemeProvider>
  );
}

const baseSortOrderId = 0;

class RightBar extends Component {

//webServiceURL = properties.env.webServiceURL;

//export default class AppNavBar extends Component {
  constructor(props) {
    super(props);

    //this.toggle = this.toggle.bind(this);


    this.state = {
      searchOpen: false,
      topicSearchOpen: false,
      reportOpen: false,
      staticContentOpen: false,
      keilimOpen: false,

      //initialize to teiva-only search
      letters: 'L',
      nekudos: '',
      taamim: '',
      gematria: '',
      searchDefData: 'L',

      filterData : [],
      //exactnessLevel: 0
      //statisticsPicker: new PickerModel()
      //pickerList: []
    };



    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup }
    });

    this.props.addTranslation(translations);

    this.handleLetters = this.handleLetters.bind(this);
    this.handleNekudos = this.handleNekudos.bind(this);
    this.handleTaamim = this.handleTaamim.bind(this);
    this.handleGematria = this.handleGematria.bind(this);
    this.exactnessPropsSet = this.exactnessPropsSet.bind(this);
    this.toggleSortDirection = this.toggleSortDirection.bind(this);
    this.handleSortOrder = this.handleSortOrder.bind(this);
  }

    async componentDidMount() {
        const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getFilterList');
        const body = await response.json();
//        this.setState({ filterData: body });
        this.props.extSetFilterData(body);
        this.props.filterCallback(body); //do this to initialize things at Home.js
        //this.setState({pickerList: this.getPickerList()});
    }

    clearGematria(){
        if(this.state.gematria){
//            this.setState({ gematria: '' });
            this.props.extSetGematria('')
        }
    }

    clearShoresh(){
        console.log('calling clear shoresh')
        if(this.state.letters === "S"){
//            this.setState({ letters: '' });
            this.props.extSetLetters('')
        }
    }

    clearLNT(){
        if(this.state.letters){
//            this.setState({ letters: '' });
            this.props.extSetLetters('')
        }
        if(this.state.nekudos){
//            this.setState({ nekudos: '' });
            this.props.extSetNekudos('')
        }
        if(this.state.taamim){
//            this.setState({ taamim: '' });
            this.props.extSetTaamim('')
        }
    }

  async handleLetters(event, letters) {
      //localStorage.setItem("helpDialogStartupTopic", "rightBar");
      if(letters === "S"){
//            this.setState({ letters: letters, gematria: '', nekudos: '', taamim: '' }, () => {
//                this.props.searchDefCallback(letters+this.state.nekudos+this.state.taamim+this.state.gematria);
//            });
            this.props.extSetLetters(letters, this.props.extSetGematria('', this.props.extSetNekudos('', this.props.extSetTaamim('', () => {
                this.props.searchDefCallback(letters+this.props.nekudos+this.props.taamim+this.props.gematria);
            }))))
      } else if(!letters && !this.props.nekudos && !this.props.taamim){
            return
      } else {
            if(letters === null){
                letters = '';
            }
//            this.setState({ letters: letters, gematria: ''}, () => {
//                this.props.searchDefCallback(this.state.letters+this.state.nekudos+this.state.taamim+this.state.gematria);
//            });
            this.props.extSetLetters(letters, this.props.extSetGematria('', () => {
                this.props.searchDefCallback(this.props.letters+this.props.nekudos+this.props.taamim+this.props.gematria);

            }))
      }
    }
  async handleNekudos(event, nekudos) {
      //localStorage.setItem("helpDialogStartupTopic", "rightBar");
      if(!nekudos && !this.props.letters && !this.props.taamim){
            return
      } else {
            if(nekudos === null){
                nekudos = '';
            }
//            this.setState({ nekudos: nekudos, gematria: '' }, () => {
//                if (this.state.letters === "S"){
//                    this.setState({ letters: '' }, () => {
//                        this.props.searchDefCallback(this.state.letters+this.state.nekudos+this.state.taamim+this.state.gematria);
//                    })
//                } else {
//                    this.props.searchDefCallback(this.state.letters+this.state.nekudos+this.state.taamim+this.state.gematria);
//                }
//            });
            this.props.extSetNekudos(nekudos, this.props.extSetGematria('', () => {
                if(this.props.letters === "S"){
                    this.props.extSetLetters('', () => {
                        this.props.searchDefCallback(this.props.letters+this.props.nekudos+this.props.taamim+this.props.gematria);
                    })
                } else {
                    this.props.searchDefCallback(this.props.letters+this.props.nekudos+this.props.taamim+this.props.gematria);
                }
            }))
      }
  }
  async handleTaamim(event, taamim) {
      //localStorage.setItem("helpDialogStartupTopic", "rightBar");
      if(!taamim && !this.props.letters && !this.props.nekudos){
            return
      } else {
            if(taamim === null){
                taamim = '';
            }
//            this.setState({ taamim: taamim, gematria: '' }, () => {
//                if (this.state.letters === "S"){
//                    this.setState({ letters: '' }, () => {
//                        this.props.searchDefCallback(this.state.letters+this.state.nekudos+this.state.taamim+this.state.gematria);
//                    })
//                } else {
//                    this.props.searchDefCallback(this.state.letters+this.state.nekudos+this.state.taamim+this.state.gematria);
//                }
//            });
            this.props.extSetTaamim(taamim, this.props.extSetGematria('', () => {
                if(this.props.letters === "S"){
                    this.props.extSetLetters('', () => {
                        this.props.searchDefCallback(this.props.letters+this.props.nekudos+this.props.taamim+this.props.gematria);
                    })
                } else {
                    this.props.searchDefCallback(this.props.letters+this.props.nekudos+this.props.taamim+this.props.gematria);
                }
            }))
      }
  }
  async handleGematria(event, gematria) {
      //localStorage.setItem("helpDialogStartupTopic", "rightBar");
      if(gematria){
//            this.setState({ letters: '', gematria: gematria, nekudos: '', taamim: '' }, () => {
//                this.props.searchDefCallback(this.state.letters+this.state.nekudos+this.state.taamim+this.state.gematria);
//            });
            this.props.extSetLetters('', this.props.extSetGematria(gematria, this.props.extSetNekudos('', this.props.extSetTaamim('', () => {
                this.props.searchDefCallback(this.props.letters+this.props.nekudos+this.props.taamim+this.props.gematria);
            }))))
      }
  }

  exactnessPropsSet(num){
      //this.setState({exactnessLevel: num});
      this.props.exactnessLevel(num);
  }

  toggleSortDirection(num) {
      this.props.extSetSortDirection(num);
  }

  handleSortOrder(e) {
      this.props.extSetSortOrder(e.target.value-1, e.target.id);
  }

  getPickerList() { //ids should correspond to grid definitions
    var terms = [ {id: baseSortOrderId, colName:["byAppearance"]},
                  {id: 1, colName:["parshaName"]},
                  {id: 3, colName:["otherKriosName"]},
                  {id: 4, colName:["namedSections"]},
                  {id: 5, colName:["yearOccurred"]}, //tanachYear
                  {id: 6, colName:["words"]},
                  {id: 7, colName:["letters"]},
                  {id: 8, colName:["letters", "perWord"]},
                  {id: 9, colName:["byUniqueLetters"]},
                  {id: 11, colName:["byUniqueLettersCS"]}, // id:10 is letter coverage in searchResults and it is not an option here.
                  {id: 12, colName:["tenuos"]},
                  {id: 13, colName:["tenuos", "perWord"]},
                  {id: 14, colName:["tenuos", "perLetter"]},
                  {id: 15, colName:["taamim"]},
                  {id: 16, colName:["taamim", "perWord"]},
                  {id: 17, colName:["taamim", "perTenua"]},
                  {id: 18, colName:["gematria"]},
                  {id: 19, colName:["gematria", "perWord"]},
                  {id: 20, colName:["gematria", "perLetter"]},
                  {id: 21, colName:["atbash"]},
                  {id: 22, colName:["atbash", "perWord"]},
                  {id: 23, colName:["atbash", "perLetter"]} ];

    var i, j, term, term_he, term_en, pickerList = [];

    for (i=0; i < terms.length; i++) {
         term_he = "";
         term_en = "";
         term = terms[i].colName;
         for (j=0; j < term.length; j++) {
             term_he += (this.props.translate(term[j], null, {language: "he"}) + " ");
             term_en += (this.props.translate(term[j], null, {language: "en"}) + " ");
         }
         pickerList.push({id: i+1, statisticID: terms[i].id, name: term_he.trimEnd(), name_en: term_en.trimEnd()});
    }

    return pickerList;
  }

  render() {
//add refresh button or just indicate that filter will be applied upon next search?
    var directionStyling = {direction: 'ltr', textAlign: 'left'}
    if(this.props.activeLanguage && this.props.activeLanguage.code === "he"){
        directionStyling = {direction: 'rtl', textAlign: 'right'}
    }

    const { classes } = this.props;
    const {gematria, letters, nekudos, taamim} = this.props;
    const sortBy = this.props.translate("sortBy");
    const sort = this.props.translate("sort");
    const by = this.props.translate("by");

    const sliderStyle = {width: 40, height: 4, left: 0, color: '#b87333 !important', backgroundColor: '#b87333 !important'};
    const dotStyle = { width: 0, height: 0, bottom: -9999, marginLeft: -9999};

    const isHebrew = localStorage.getItem("language") === 'he';
    const menuItemStyle = {direction: isHebrew ? "rtl" : "ltr", backgroundColor: '#b87333', fontWeight: 'normal', fontSize: '12px', height: '16px', padding: '3px'};

    const marks = {
      0: {label: <Translate id="ascend" />},
      100: {label: <Translate id="descend" />},
    };

    return       <MuiThemeProvider theme={theme}>
<div className = "rightbar" expand="md">
    <table border="0">
        <tbody>
            <tr>
                <td className = "rightbar-header"><Translate id="definitions" /></td>
            </tr>
            <tr className = "rightbar-content" >
                <td>
                    <div className= "simpleFuzzyPickerDiv">
                        <FuzzyPicker setSlider={this.props.exactnessLevel} sliderValue={this.props.ssExactnessLevel} />
                    </div>
                    <div className={classes.toggleContainer} style={{marginTop: '15px'}}>

                        <div className="toggleButton-div" style={{textAlign:'center', marginBottom: '8px'}}>לפי תיבה</div>
                        <ToggleButtonGroup value={taamim} exclusive onChange={this.handleTaamim} style={{backgroundColor: '#b87332', marginBottom: '8px'}}>
                          <ToggleButton className="toggleButton-small" value="T">טעמים</ToggleButton>
                        </ToggleButtonGroup>
                        <ToggleButtonGroup value={nekudos} exclusive onChange={this.handleNekudos} style={{backgroundColor: '#b87332', marginBottom: '8px'}}>
                          <ToggleButton className="toggleButton-medium" value="N">נקודות</ToggleButton>
                        </ToggleButtonGroup>
                        <ToggleButtonGroup value={letters} exclusive onChange={this.handleLetters} style={{backgroundColor: '#b87332', marginBottom: '8px'}}>
                          <ToggleButton className="toggleButton-small" value="L">אותיות</ToggleButton>
                        </ToggleButtonGroup>
                        <div className="toggleButton-div" style={{textAlign:'center', marginBottom: '8px'}}>לפי</div>
                        <ToggleButtonGroup value={letters} exclusive onChange={this.handleLetters} style={{backgroundColor: '#b87332', marginBottom: '8px'}}>
                          <ToggleButton className="toggleButton-small" value="S">שורש</ToggleButton>
                        </ToggleButtonGroup>
                        <div className="toggleButton-div" style={{textAlign:'center', marginBottom: '8px'}}>לפי גמטריא</div>
                        <ToggleButtonGroup value={gematria} exclusive onChange={this.handleGematria} style={{backgroundColor: '#b87332', marginBottom: '8px'}}>
                          <ToggleButton className="toggleButton-small" value="G">רגיל</ToggleButton>
                          <ToggleButton className="toggleButton-small" value="A">אתבש</ToggleButton>
                        </ToggleButtonGroup>

                    </div>
                        <div>
                            <table width={'100%'}>
                                <tbody>
                                    <tr>
                                        <td align={'center'}>
                                            <div style={{height: '12px', fontSize: '16px', fontWeight: 'bold'}}>{sortBy}</div>
                                            <div style={{height: '11px'}} />
                                            <SortOrderSelector2D value={this.props.sortOrder} onChange={this.handleSortOrder} menuItems={this.getPickerList()} />
                                            <div style={{height: '6px'}} />
                                            <div className="rightBarSlider">
                                                <Slider
                                                  style = {sliderStyle}
                                                  dotStyle = {dotStyle}
                                                  step={null}
                                                  included={false}
                                                  value={this.props.sortDirection}
                                                  marks={marks}
                                                  onChange={this.toggleSortDirection}
                                                  reverse={true}
                                                 />
                                             </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                </td>
            </tr>
            <tr className = "rightbar-content">
                <td>
                        <div style={{height: '7px'}} />
                        <div className="toggleButton-div" />
                        <div className="toggleButton-div" />
                    <SuperTreeview className = "rightbar-content"
                        data={ this.props.filterData }
                        isDeletable={() => { return false; }}
                        //getStyleClassCb	= {() => { return ' '; }}
                        onUpdateCb={(updatedData) => {
//                            this.setState({filterData: updatedData});
                            this.props.extSetFilterData(updatedData);
                            this.props.filterCallback(updatedData);
                        }}
                        isExpandable={(node)=> { return node.isExpandable; }}
                        onCheckToggleCb={(nodes)=>{
                            //localStorage.setItem("helpDialogStartupTopic", "rightBar");
                            const checkState = nodes[0].isChecked;
                            applyCheckStateTo(nodes);
                            function applyCheckStateTo(nodes){
                                nodes.forEach((node)=>{
                                    node.isChecked = checkState
                                    if(node.children){
                                        applyCheckStateTo(node.children);
                                    }
                                })
                            }
                        }}
                    />
                </td>
            </tr>
          </tbody>
      </table>
    </div></MuiThemeProvider>;

  }
}

RightBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withLocalize(RightBar));


/*

                        <div className='sortOrderSelect' style={{direction: directionStyling.direction}}>
                            <Typography className='result-statistics-select' variant="subtitle2">
                                <StatPicker2D pickerModel={statisticsPicker} title={this.props.sortOrder} extSetValues={this.handleSortOrder}
                                              selectStyle={"General"} extClassName={'statisticsPickerDropdownButton'} displayMultiple={false} />
                            </Typography>
                        </div>
                                            <div className='sortOrderSelect'>
                                                <Select
                                                    value={this.props.sortOrder}
                                                    onChange={(e) => {this.handleSortOrder(e)}}
                                                    name="sortOrderSelect"
                                                    displayEmpty
                                                    style={{
                                                        width: '155px',
                                                        textAlign: 'right',
                                                        backgroundColor: '#b87333',
                                                        fontWeight: 'normal',
                                                        fontSize: '12px',
                                                        paddingTop: '0px',
                                                        paddingBottom: '0px'
                                                    }}
                                                >
                                                    <MenuItem style={menuItemStyle} value={0}>{byAppearance}</MenuItem>
                                                    <MenuItem style={menuItemStyle} value={1}>{byTanachYear}</MenuItem>
                                                    <MenuItem style={menuItemStyle} value={2}>{byYearComposed}</MenuItem>
                                                    <MenuItem style={menuItemStyle} value={3}>{byWords}</MenuItem>
                                                    <MenuItem style={menuItemStyle} value={4}>{byLetters}</MenuItem>
                                                    <MenuItem style={menuItemStyle} value={5}>{byUniqueLetters}</MenuItem>
                                                    <MenuItem style={menuItemStyle} value={6}>{byUniqueLettersCS}</MenuItem>
                                                    <MenuItem style={menuItemStyle} value={7}>{byGematria}</MenuItem>
                                                    <MenuItem style={menuItemStyle} value={8}>{byAtbash}</MenuItem>
                                                </Select>
                                            </div>
*/