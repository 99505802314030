import React, { Component, useState } from 'react';
import './css/App.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import { Button, Paper, InputBase, IconButton, Fade, Typography, Popper, AppBar, Tabs, Tab, InputLabel, MenuItem, FormControl, Select, Switch } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import translations from "./json/translations.json";
import * as properties from './config/properties.js';
import PlacementSelector2D from './PlacementSelector2D';

function hasNumericValue(x, testVal) {
     var test = testVal == null ? 0 : testVal;
     return x != null & x > test;
}

function isEmptyList(list) {
    return list == null || list.length == 0 || list[0].id == null;
}

class TanachViewerPlacementSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isLoading: true,
          valList1: this.props.data.valList1, //[],
          valList2: this.props.data.valList2, //[],
          valList3: this.props.data.valList3, //[],
          valList4: this.props.data.valList4, //[],
          valList5: this.props.data.valList5,
          valList6: this.props.data.valList6,
          type: this.props.data.type,//this.props.placementValues.type,
          val1: this.props.data.val1,//this.props.placementValues.val1,
          val2: this.props.data.val2,//this.props.placementValues.val2,
          val3: this.props.data.val3,//this.props.placementValues.val3,
          val4: this.props.data.val4,//this.props.placementValues.val4,
          val5: this.props.data.val5,//this.props.placementValues.val5,
          val6: this.props.data.val6,//this.props.placementValues.val5,
          kriaSequenceNum: this.props.data.kriaSequenceNum,
          lastTenSelections:util.getFromLocalStorage("lastTenSelections"),

          //need to set the disabled stuff local to the component each time loads it in a tab
          disabled2: !hasNumericValue(this.props.data.val1) || (this.props.data.type && isEmptyList(this.props.data.valList2)),
          disabled3: !hasNumericValue(this.props.data.val2) || (this.props.data.type && isEmptyList(this.props.data.valList3)),
          disabled4: this.props.data.type && isEmptyList(this.props.data.valList4),
//          disabled2: !hasNumericValue(this.props.data.val1),
//          disabled3: !hasNumericValue(this.props.data.val2) || (this.props.data.type && isEmptyList(this.props.data.valList3)),
//          disabled4: this.props.data.type && (!isEmptyList(this.props.data.valList3) && !hasNumericValue(this.props.data.val3) ||
//                                          (isEmptyList(this.props.data.valList3) && !hasNumericValue(this.props.data.val2))),
          disabled5: !hasNumericValue(this.props.data.val4),
          disabled6: this.props.data.type || !hasNumericValue(this.props.data.val5),
          selectOrLoading: ['select', 'select', 'select', 'select', 'select', 'select'],
          firstOpened:true
        };

        this.handleSelChange = this.handleSelChange.bind(this);
        this.handleSelChange2 = this.handleSelChange2.bind(this);
        this.handleSelChange3 = this.handleSelChange3.bind(this);
        this.handleSelChange4 = this.handleSelChange4.bind(this);
        this.handleSelChange5 = this.handleSelChange5.bind(this);
        this.handleCloseLine = this.handleCloseLine.bind(this);
        this.toggleType = this.toggleType.bind(this);
//these are not used
        // this.handleRangeChange1 = this.handleRangeChange1.bind(this);
        // this.handleRangeChange2 = this.handleRangeChange2.bind(this);
        // this.handleRangeChange3 = this.handleRangeChange3.bind(this);
        // this.handleRangeChange4 = this.handleRangeChange4.bind(this);
        // this.handleRangeChange5 = this.handleRangeChange5.bind(this);
        // this.handleRangeChange6 = this.handleRangeChange6.bind(this);
        this.addFromLastTenSelections = this.addFromLastTenSelections.bind(this);
        this.modifyPlacementRow = this.modifyPlacementRow.bind(this);

        this.props.initialize({
          languages: [
            { name: "עברית", code: "he" },
            { name: "English", code: "en" }
          ],
          translation: translations,
          options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
        });

        this.props.addTranslation(translations);
        this.setLanguage = (code) => {props.setActiveLanguage(code)};
    }


//webServiceURL = properties.env.webServiceURL



  async componentDidMount() {
      if (this.state.valList1.length == 0) {

          const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPlacementOccasions?source=bhsWebApp');
          const body = await response.json();
          var lastTenSelections = util.getFromLocalStorage("lastTenSelections")?util.getFromLocalStorage("lastTenSelections"):[]
          this.setState({ valList1: body, isLoading: false, lastTenSelections});
      }
  }

  async loadList2(val) {
      if (this.state.type) {
          var selectOrLoading = this.state.selectOrLoading;
          selectOrLoading[1] = 'loading';
          this.setState({selectOrLoading: selectOrLoading})

          const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPlacementOccasions?source=bhsWebApp&grandParentId='+val);
          const body = await response.json();
          var isEmptyList = (body.length == 0 || body[0].id == null);

          selectOrLoading[1] = 'select';

          this.setState({ val1: val, val2: 0, val3: 0, val4: 0, val5: 0, kriaSequenceNum: 0, valList2: body, selectOrLoading: selectOrLoading,
                          valList3: [], valList4: [], valList5: [], valList6: [], 
                          disabled2: isEmptyList, disabled3: true, disabled4: true, disabled5: true, isLoading: false}, () => { //must do things this way to force sync since setState is async
              if (isEmptyList){
                this.loadList3(0);
              }else{
                this.modifyPlacementRow(this.state,true);
              }
          });
      }
      else {
          const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPerekInfo?source=bhsWebApp&seferId='+val);
          const body = await response.json();
          const perakim = body.map(function(e) {return {perekNum: e.perek, perekValue: util.gematriya(e.perek, {punctuate: false}), pesukim: e.pesukim }});
          this.setState({ val1: val, val2: 0, val3: 0, valList2: perakim, disabled2: false, disabled3: true}, () => { //must do things this way to force sync since setState is async
            var rowValues = this.state;
            rowValues.valList1 = this.props.data.valList1;
            this.modifyPlacementRow(rowValues);
          });
      }
  }

  async loadList3(val) {
      if (this.state.type) {
          var selectOrLoading = this.state.selectOrLoading;
          selectOrLoading[2] = 'loading';

          this.setState({selectOrLoading: selectOrLoading})

          const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPlacementOccasions?source=bhsWebApp&grandParentId='+this.state.val1+'&parentId='+val);
          var body = await response.json();
          const isEmptyList = (body.length == 0 || body[0].id == null);
          if(isEmptyList){
            body = []
          }

          selectOrLoading[2] = 'select';

          this.setState({ val2: val, val3: 0, val4: 0, val5: 0, kriaSequenceNum: 0, valList3: body,  selectOrLoading: selectOrLoading, disabled3: isEmptyList, disabled4: true, disabled5: true, isLoading: false,
                        valList4: [], valList5: [], valList6: [], 
          }, () => { //must do things this way to force sync since setState is async
              if (isEmptyList){
                this.loadList4(0);
              }else{
                this.modifyPlacementRow(this.state,true);
              }
//              this.modifyPlacementRow(this.state);
//              if (isEmptyList)
//                  this.loadList4(0);
          });
      }
      else {
          const perek = this.state.valList2.find(function(e){return e.perekNum == val;});
          const body = Array(perek.pesukim).fill().map((x,i)=>({pasukNum: i+1, pasukValue: util.gematriya(i+1, {punctuate: false}) }));

          this.setState({ val2: val, val3: 0, valList3: body, disabled3: false}, () => { //must do things this way to force sync since setState is async
              this.modifyPlacementRow(this.state);
          });
      }
  }

  async loadList4(val) {
        var selectOrLoading = this.state.selectOrLoading;
        selectOrLoading[3] = 'loading';
        this.setState({selectOrLoading: selectOrLoading})

      const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPlacementOccasions?source=bhsWebApp&grandParentId='+this.state.val1+'&parentId='+this.state.val2+'&occasionId='+val);
      const body = await response.json();

        selectOrLoading[3] = 'select';

      this.setState({ val3: val, val4: 0, val5: 0, kriaSequenceNum: 0, valList4: body, selectOrLoading: selectOrLoading, disabled4: false, disabled5: true, isLoading: false,
        valList5: [], valList6: [], 
      }, () => { //must do things this way to force sync since setState is async
          this.modifyPlacementRow(this.state);
      });
  }

  async loadList5(val) {
      if (this.state.type) {

          var selectOrLoading = this.state.selectOrLoading;
          selectOrLoading[4] = 'loading';

          this.setState({selectOrLoading: selectOrLoading})

          const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPlacementOccasions?source=bhsWebApp&grandParentId='+this.state.val1+'&parentId='+this.state.val2+'&occasionId='+this.state.val3+'&kriaId='+val);
          const body = await response.json();
          for(let i = 0; i < body.length; i++){ // add kriaSequenceNums
            body[i].kriaSequenceNum = i+1;
          }

        selectOrLoading[4] = 'select';

          this.setState({ val4: val, val5: 0, kriaSequenceNum: 0, valList5: body, valList6: [], selectOrLoading: selectOrLoading, disabled5: false, isLoading: false}, () => { //must do things this way to force sync since setState is async
              this.modifyPlacementRow(this.state);
              if(body.length === 1){
                var event = {target: {value: 1}}
                this.handleSelChange5(event)
              }
          });
      }
      else {
          const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPerekInfo?source=bhsWebApp&seferId='+val);
          const body = await response.json();
          const perakim = body.map(function(e) {return {perekNum: e.perek, perekValue: util.gematriya(e.perek, {punctuate: false}), pesukim: e.pesukim }});

          this.setState({ val4: val, val5: 0, kriaSequenceNum: 0, val6: 0, valList5: perakim, disabled5: false, disabled6: true}, () => { //must do things this way to force sync since setState is async
              this.modifyPlacementRow(this.state);
          });
      }
  }

  async loadList6(val) {
      const perek = this.state.valList5.find(function(e){return e.perekNum == val;});
      const body = Array(perek.pesukim).fill().map((x,i)=>({pasukNum: i+1, pasukValue: util.gematriya(i+1, {punctuate: false}) }));

      this.setState({ val5: val, val6: 0, valList6: body, disabled6: false}, () => { //must do things this way to force sync since setState is async
          this.modifyPlacementRow(this.state);
      });
  }

      handleSelChange(event) {
         if (event.target.value == this.state.val1) return false;
         this.loadList2(event.target.value);
       }

      handleSelChange2(event) {
         if (event.target.value == this.state.val2) return false;
         this.loadList3(event.target.value);
       }

      handleSelChange3(event) {
         if (event.target.value == this.state.val3) return false;
         this.loadList4(event.target.value);
       }

      handleSelChange4(event) {
         if (event.target.value == this.state.val4) return false;
         this.loadList5(event.target.value);
       }

      handleSelChange5(event) {
        var value = event.target.value
         if (value == this.state.kriaSequenceNum) return false;
         this.setState({
          kriaSequenceNum: value,
        }, () => {
          this.modifyPlacementRow(this.state,false,false,null,this.state.firstOpened?true:false)
          this.setState({firstOpened:false})
        });
       }

      // handleRangeChange1(event) {
      //    if (event.target.value == this.state.val1) return false;
      //    this.loadList2(event.target.value);
      //  }

      // handleRangeChange2(event) {
      //    if (event.target.value == this.state.val2) return false;
      //    this.loadList3(event.target.value);
      //  }

      // handleRangeChange3(event) {
      //   if (event.target.value == this.state.val3) return false;
      //   var value = event.target.value;
      //   var willFetchPesukim = value === 0 ? false : true;

      //   this.setState({ val3: event.target.value}, () => { 
      //     this.modifyPlacementRow(this.state, willFetchPesukim,true); 
      //   });
      // }

      // handleRangeChange4(event) {
      //    if (event.target.value == this.state.val4) return false;
      //    this.loadList5(event.target.value);
      //  }

      // handleRangeChange5(event) {
      //    if (event.target.value == this.state.val5) return false;
      //    this.loadList6(event.target.value);
      //  }

      // handleRangeChange6(event) {
      //    if (event.target.value == this.state.val6) return false;
      //    this.setState({ val6: event.target.value }, () => {this.modifyPlacementRow(this.state,true)});
      //  }

      handleCloseLine() {
         this.props.node.setSelected(true, true); //make sure the row is selected for deletion

         this.setState({ type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, kriaSequenceNum: 0, val6: 0 }, () => {this.props.extDeletePlacementRow(this.state)}); //do this in case only clearing the line and not actually deleting
       }

      addFromLastTenSelections(event, willFetchPesukim, isRefresh, oldState){
        this.setState({
          val1:event.target.value.val1,
          val2:event.target.value.val2,
          val3:event.target.value.val3,
          val4:event.target.value.val4,
          val5:0,
          val6:0,
          kriaSequenceNum:0,
          valList1:event.target.value.valList1,
          valList2:event.target.value.valList2,
          valList3:event.target.value.valList3,
          valList4:event.target.value.valList4,
          valList5:event.target.value.valList5,
          valList6:event.target.value.valList6,
        },()=>{
          this.modifyPlacementRow(event.target.value, willFetchPesukim, isRefresh, oldState,false,event.target.value.type?true:false)
        })
      }

      modifyPlacementRow(row, willFetchPesukim, isRefresh, oldState,addRow,reloadPesukim){
        if(addRow){//take rowValues and add it to a local storage object to build the 20 last selections
          var rowValues = {type: row.type, val1: row.val1, val2: row.val2, val3: row.val3, val4: row.val4, val5: 0, val6: 0, kriaSequenceNum: 0,
              valList1: row.valList1, valList2: row.valList2, valList3: row.valList3, valList4: row.valList4, valList5: row.valList5, valList6: row.valList6 }
          var lastTenSelections = util.getFromLocalStorage("lastTenSelections")?util.getFromLocalStorage("lastTenSelections"):[]
          lastTenSelections.unshift(rowValues)//add to the top
          if(lastTenSelections.length>20)lastTenSelections.pop()
          this.setState({lastTenSelections})
          util.saveToLocalStorage("lastTenSelections", lastTenSelections);
        }
        this.props.extModifyPlacementData(row, willFetchPesukim, isRefresh, oldState,reloadPesukim)
      }

      async toggleType(event) {
          // var oldState = {};
          // for (var attr in this.state) {
          //     if (this.state.hasOwnProperty(attr)) oldState[attr] = this.state[attr];
          // }
          var type= this.props.type
          var val1= this.props.val1
          var val2= this.props.val2
          var val3= this.props.val3
          var val4= this.props.val4
          var val5= this.props.val4
          var kriaSequenceNum= this.props.kriaSequenceNum
          var val6= this.props.val6
          var valList2= this.props.valList2
          var valList3= this.props.valList3
          var valList1= this.props.valList1
          var valList4= this.props.valList4
          var disabled2= false
          var disabled3= false
          var disabled4= false
          var disabled5= false
          var disabled6= false
          var oldState = { type, val1, val2, val3, val4, val5, kriaSequenceNum, val6, valList1, valList4, valList2, valList3, disabled2, disabled3, disabled4, disabled5, disabled6}
          const wasNamedType = this.state.type;
          const url = properties.env.webServiceURL + "/BaseHaSefer/" + ((wasNamedType) ? "getSeferPerekInfo" : "getPlacementOccasions") + "?source=bhsWebApp";

          const response = await fetch(url);
          const body = await response.json();
          const body2 = (wasNamedType) ? body : [];
            this.setState({ type: !wasNamedType, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, kriaSequenceNum: 0, val6: 0, valList1: body, valList2:[],valList3:[], valList4: body2,
                          disabled2: true, disabled3: true, disabled4: !wasNamedType, disabled5: true, disabled6: true}, () => { //must do things this way to force sync since setState is async
              this.modifyPlacementRow(this.state,null, null,oldState);
          });
          //this.props.api.refreshCells();
      }

      componentDidUpdate(prevProps, prevState, snapshot){
        const isAtNamedSection = this.props.isAtNamedSection;
        if(this.props.type !== this.state.type){
          this.setState({type:this.props.type})
        }
        if(isAtNamedSection && prevProps.type === false && this.props.type === true){ // maybe can use this for pasuk selector side as well
          var loadedSectionVals = this.props.loadedSectionVals
          this.setState({
            val1: loadedSectionVals.val1,
            val2: loadedSectionVals.val2,
            val3: loadedSectionVals.val3,
            val4: loadedSectionVals.val4,
            val5: loadedSectionVals.val5,
            val6: loadedSectionVals.val6,
            kriaSequenceNum: loadedSectionVals.kriaSequenceNum,
            valList1: loadedSectionVals.valList1,
            valList2: loadedSectionVals.valList2,
            valList3: loadedSectionVals.valList3,
            valList4: loadedSectionVals.valList4,
            valList5: loadedSectionVals.valList5,
            valList6: loadedSectionVals.valList6,
          }, () => {
            this.modifyPlacementRow(this.state, false, true)
          })
        }
        if(this.props.tanachViewerOpen && !prevProps.tanachViewerOpen){
          var lastTenSelections = util.getFromLocalStorage("lastTenSelections")?util.getFromLocalStorage("lastTenSelections"):[]
          this.setState({lastTenSelections, firstOpened:true});
        }
      }

    render() {
        var list1 = this.state.valList1;
        var list2 = this.state.valList2;
        var list3 = this.state.valList3;
        var list4 = this.state.valList4;
        var list5 = this.state.valList5;
        var list6 = this.state.valList6;
        var val1 = this.state.val1;
        var val2 = this.state.val2;
        var val3 = this.state.val3;
        var val4 = this.state.val4;
        var val5 = this.state.val5;
        var kriaSequenceNum = this.state.kriaSequenceNum;
        var val6 = this.state.val6;
        const del = this.props.translate("delete"); //this.props.transWords.del; //this.props.translate("delete"); // - todo language doesn't immediately auto-change on fly w/i ag-grid when toggle the lang
        const sel = this.props.translate("select");
        const smallLoadingMessage = <b style={{color: '#0000ff'}}>{this.props.translate('smallLoading')}</b>;
        const sel1 = this.state.selectOrLoading[0] === 'select' ? sel : smallLoadingMessage;
        const sel2 = this.state.selectOrLoading[1] === 'select' ? sel : smallLoadingMessage;
        const sel3 = this.state.selectOrLoading[2] === 'select' ? sel : smallLoadingMessage;
        const sel4 = this.state.selectOrLoading[3] === 'select' ? sel : smallLoadingMessage;
        const sel5 = this.state.selectOrLoading[4] === 'select' ? sel : smallLoadingMessage;
        const loading = this.props.translate("smallLoading");
        const selectPad = this.props.translate("selectPad.Placement");
        const loadSection = this.props.translate("loadSection");
        const blankPadEN = <span style={{fontSize:'1px'}}>c</span>; //tiny c
        var isHebrew = false;
        if(this.props.activeLanguage && this.props.activeLanguage.code === 'he'){
          isHebrew = true;
        }

        list1 = this.props.valList1;
        list2 = this.props.valList2;
        list3 = this.props.valList3;
        list4 = this.props.valList4;
        list5 = this.props.valList5;
        list6 = this.props.valList6;
        val1 = this.props.val1;
        val2 = this.props.val2;
        val3 = this.props.val3;
        val4 = this.props.val4;
        kriaSequenceNum = this.props.kriaSequenceNum;
        val6 = this.props.val6;
        var type = this.props.type;

        var namedSectionLoadDisabled = !this.props.canFetchSection;

        var typeSwitchParentClassName = (this.props.activeLanguage && this.props.activeLanguage.code === 'en') ? "tanach-viewer-type-switch-parent-span-en" : "";
        const lastTwentyDropdown = this.props.translate("lastTwentyDropdown")
        const dropdownInstructions = this.props.translate("dropdownInstructions")
        var lastTwentySelection = this.props.tanachViewerOpen ? <Select title={lastTwentyDropdown} disabled={this.state.lastTenSelections?this.state.lastTenSelections.length==0:true} style={{maxWidth:'25px', height: '20px'}} value={""} onChange={this.addFromLastTenSelections} name="pageSelect" displayEmpty autoWidth={true}>
                                    <MenuItem style={{height: '10px',backgroundColor:'#ababab', opacity:'1',color:'black',padding:isHebrew?'11px 28%':'11px 20%'}} disabled><b>{dropdownInstructions}</b></MenuItem>
                                        {this.state.lastTenSelections?this.state.lastTenSelections.map(selection=>{
                                            return selection.type? 
                                                <MenuItem style={{flexDirection: 'row-reverse',height: '10px'}} value={selection}><Typography style={{lineHeight: 1}} variant="subtitle1"><small>
                                                    {selection.val1>0?selection.valList1.find(row=>row.id===selection.val1)?selection.valList1.find(row=>row.id===selection.val1).value:"":""} {selection.val2>0 && selection.valList2.find(row=>row.id===selection.val2)?">"+selection.valList2.find(row=>row.id===selection.val2).value:""}
                                                    {selection.val3>0 && selection.valList3.find(row=>row.id===selection.val3)?">"+selection.valList3.find(row=>row.id===selection.val3).value+">":">"} </small><b>{selection.val4>0?selection.valList4.find(row=>row.id===selection.val4)?selection.valList4.find(row=>row.id===selection.val4).value:"":""}
                                                    {selection.val5>0 && selection.valList5.find(row=>row.id===selection.val5)?">"+selection.valList5.find(row=>row.id===selection.val5).value:""}</b></Typography></MenuItem>
                                                :
                                                <MenuItem style={{flexDirection: 'row-reverse',height: '10px'}} value={selection}><Typography style={{lineHeight: 1}} variant="subtitle1"><b>
                                                    {`${selection.val1>0?selection.valList1.find(row=>row.id===selection.val1)?selection.valList1.find(row=>row.id===selection.val1).value :"" : ''} ${selection.val2>0?selection.valList2.find(row=>row.id===selection.val2)?selection.valList2.find(row=>row.id===selection.val2).value:"" : ''}:
                                                    ${selection.val3>0?selection.valList3.find(row=>row.id===selection.val3)?selection.valList3.find(row=>row.id===selection.val3).value :"" : ''}`}</b><small> עד </small><b>
                                                    {`${selection.val4>0?selection.valList4.find(row=>row.id===selection.val4)?selection.valList4.find(row=>row.id===selection.val4).value:"": ''} ${selection.val5>0?selection.valList5.find(row=>row.id===selection.val5)?selection.valList5.find(row=>row.id===selection.val5).value :"": ''}:
                                                    ${selection.val6>0?selection.valList6.find(row=>row.id===selection.val6)?selection.valList6.find(row=>row.id===selection.val6).value :"" : ''}`}</b></Typography></MenuItem>                     
                                        }):<MenuItem></MenuItem>}
                                      </Select>:<></>


      return (
        <span className="tanach-viewer-placement-selector" style={{backgroundColor: `${type ? "#E0F7FF" : "#FFE0B0"}`}}>
          {lastTwentySelection }
          <span>
          {this.props.translate("tanachViewerNamedSection")}
              &nbsp;
              <span className={typeSwitchParentClassName}>
                <Switch className="gridSwitch"
                    checked={type}
                    onChange={this.toggleType}
                    value="type"
                    color="primary"
                    size="small"
                    disabled={this.props.isLoading}
                    classes={{
                      switchBase: 'tanach-viewer-switchBase',
                    }}
                  />
              </span>
              &nbsp;
              {this.props.translate("tanachViewerPasukRange")}<span>:</span>
                  &nbsp;&nbsp;&nbsp;
          </span>
          {type && <>
            <span className="tanach-viewer-placement-selector-select-parent">
              {this.props.translate("sectionType")}
              <PlacementSelector2D onChange={this.handleSelChange} value={this.state.val1} menuItems={list1} activeLanguageCode={localStorage.getItem("language")}
                disabled={false} selectOrLoading={sel1} isTanachViewer={true} isNamedSection={true}/>

            </span>
            <span className="tanach-viewer-placement-selector-select-parent">
              {this.props.translate("eventType")}
              <PlacementSelector2D onChange={this.handleSelChange2} value={this.state.val2} menuItems={list2} activeLanguageCode={localStorage.getItem("language")}
                disabled={this.state.disabled2} selectOrLoading={sel2} isTanachViewer={true} isNamedSection={true}/>
            </span>
            <span className="tanach-viewer-placement-selector-select-parent">
              {this.props.translate("eventName")}
              <PlacementSelector2D onChange={this.handleSelChange3} value={this.state.val3} menuItems={list3} activeLanguageCode={localStorage.getItem("language")}
                disabled={this.state.disabled3} selectOrLoading={sel3} isTanachViewer={true} isNamedSection={true}/>
            </span>
            <span className="tanach-viewer-placement-selector-select-parent">
              {this.props.translate("sectionName")}
              <PlacementSelector2D onChange={this.handleSelChange4} value={this.state.val4} menuItems={list4} activeLanguageCode={localStorage.getItem("language")}
                disabled={this.state.disabled4} selectOrLoading={sel4} isTanachViewer={true} isNamedSection={true}/>
            </span>
            <span className="tanach-viewer-placement-selector-select-parent">
              {this.props.translate("aliya")}
              <PlacementSelector2D onChange={this.handleSelChange5} value={this.state.kriaSequenceNum} menuItems={list5} activeLanguageCode={localStorage.getItem("language")}
                disabled={this.state.disabled5} selectOrLoading={sel5} isTanachViewer={true} isNamedSection={true}/>
            </span>
            <IconButton
              aria-label="delete"
              className='tanach-viewer-selector-button'
              size="small"
              disabled={namedSectionLoadDisabled}
              style={{
                color: `${namedSectionLoadDisabled ? "grey" : "blue"}`
              }}
              onClick={() => {this.props.extFetchSection()}}
              title={loadSection}
            >
              {isHebrew &&
                            <ArrowBackIcon fontSize="small" className='tanach-viewer-selector-button-icon'/>
              }
              {!isHebrew &&
                            <ArrowForwardIcon fontSize="small" className='tanach-viewer-selector-button-icon'/>

              }
            </IconButton>
          </>}
          {!type && <>
            <span className="tanach-viewer-placement-selector-select-parent">
              {this.props.translate("sefer")}
              <PlacementSelector2D onChange={this.props.extHandleRangeChange1} value={val1} menuItems={list1} activeLanguageCode={localStorage.getItem("language")}
                disabled={false} selectOrLoading={sel1} type='placementRendererRangeSefer'/>

            </span>
            <span className="tanach-viewer-placement-selector-select-parent">
              {this.props.translate("perek")}
              <PlacementSelector2D onChange={this.props.extHandleRangeChange2} value={val2} menuItems={list2} activeLanguageCode={localStorage.getItem("language")}
                disabled={list2.length === 0} selectOrLoading={sel2} type='placementRendererRangePerek'/>

            </span>
            <span className="tanach-viewer-placement-selector-select-parent">
              {this.props.translate("pasuk")}
              <PlacementSelector2D onChange={this.props.extHandleRangeChange3} value={val3} menuItems={list3} activeLanguageCode={localStorage.getItem("language")}
                disabled={list3.length === 0} selectOrLoading={sel3} type='placementRendererRangePasuk'/>

            </span>
          </>}
        </span>
      )
    }
}

export default withLocalize(TanachViewerPlacementSelector);
