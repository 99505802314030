import React, { Component, MenuItem } from 'react';
import './css/App.css';
import styles from './css/App.css'; //for customizing reactStrap styles
import userManual from './images/userManual.svg';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Button, ButtonToolbar, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withLocalize, Translate } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import translations from "./json/translations.json";
import SearchCriteria from "./SearchCriteria.js";

class LeftBar extends Component {
//export default class AppNavBar extends Component {
  constructor(props) {
    super(props);

    //this.toggle = this.toggle.bind(this);


    this.state = {
      searchOpen: false,
      topicSearchOpen: false,
      reportOpen: false,
      staticContentOpen: false,
      keilimOpen: false,
      criteriaX:700,
      criteriaY: 200,
      criteriaHeightNum:500,
      criteriaWidthNum:450,
    };
    this.setCriteriaPosition = this.setCriteriaPosition.bind(this);
    this.resetCriteriaPosition = this.resetCriteriaPosition.bind(this);
    this.setCriteriaSize = this.setCriteriaSize.bind(this);
    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup }
    });

    this.props.addTranslation(translations);
  }

  setCriteriaSize(widthNum, heightNum, x, y){
    this.setState({
      criteriaWidthNum: widthNum,
      criteriaHeightNum: heightNum,
      criteriaX: x,
      criteriaY: y
    })
  }
  setCriteriaPosition(x, y){
    this.setState({
      criteriaX: x,
      criteriaY: y
    })
  }
  resetCriteriaPosition(callback){
    const widthNum = window.innerWidth > 1000 ? 800 : window.innerWidth - 200;
    const heightNum = window.innerHeight > 700 ? 650 : window.innerHeight - 50;
    const homeScrollContainer = document.getElementById("home-scroll-container");
    this.setState({
      criteriaWidthNum: widthNum,
      criteriaHeightNum: window.innerHeight > 700 ? 650 : window.innerHeight - 50,
      criteriaX: ((window.innerWidth - widthNum) / 2) + homeScrollContainer.scrollLeft,
      criteriaY: (window.innerHeight - heightNum) / 2,
    }, () => {
      if(callback){
        callback();
      }
    })
  }


  render() {
    var userManualHref = (this.props.activeLanguage && this.props.activeLanguage.code === "he") ? "./userManual_he.pdf" : "./userManual.pdf";
    const siteMap = this.props.translate("siteMap");

    return <table width="100%" border="0">
      <div style={{textAlign:"start"}}>{(this.props.searchCriteria?<SearchCriteria superTreeData={this.props.superTreeData} 
                                        updateLeftBar={this.props.updateLeftBar}
                                        updateData={this.props.resetSuperTreeData} inLeftBar={this.props.inLeftBar} searchCriteria={this.props.searchCriteria}
                                        placementRowData={this.props.placementRowData} filterData={this.props.filterData}
                                        translate={this.props.translate} activeLanguage={this.props.activeLanguage} 
                                        onClose={() => {this.toggleHelpDialog(false)}}
                                        setSize={(widthNum, heightNum, x, y) => {this.setCriteriaSize(widthNum, heightNum, x, y)}}
                                        setPosition={(x, y) => {this.setCriteriaPosition(x, y)}} 
                                        resetPosition={() => {this.resetCriteriaPosition()}}
                                        x={this.state.criteriaX} y={this.state.criteriaY} widthNum={this.state.criteriaWidthNum}
                                        heightNum={this.state.criteriaHeightNum}
                            ></SearchCriteria>:<div></div>)}
      </div>
        <tbody>
            <tr className = "leftbar-header">
                <td className = "leftbar-header">
                        <div className="toggleButton-div" />
                        <div className="toggleButton-div" />
                        <Translate id="searchInstructionsHeader" />
                        <div className="toggleButton-div" />
                </td>
            </tr>
            <tr className = "leftbar-content">
                <td className = "leftbar-content">
                        <div><Translate id="searchInstructionsContent" /></div>
                        <div className="toggleButton-div" />
                        <div className="toggleButton-div" />
                </td>
            </tr>
            <tr className = "leftbar-header">
                <td className = "leftbar-header">
                        <div className="toggleButton-div" />
                        <div className="toggleButton-div" />
                        <Translate id="pageInstructions" />
                        <div className="toggleButton-div" />
                </td>
            </tr>
            <tr className = "leftbar-content">
                <td className = "leftbar-content">
                        <div>{this.props.leftBarText}</div>
                        <div className="toggleButton-div" />
                        <div className="toggleButton-div" />
                </td>
            </tr>
            <tr className = "leftbar-header">
                <td className = "leftbar-header">
                        <div className="toggleButton-div" />
                        <Translate id="userManual" />
                </td>
            </tr>
            <tr>
                <td>
                      <NavLink title={this.props.translate("userManual")} href={userManualHref} target="_blank">
                          <img src={userManual} height="48" width="88" className="company-logo" alt="userManual" align="center" />
                      </NavLink>
                </td>
            </tr>
            <tr>
                <td>
                      <NavLink href="/#/sitemap">
                          <span style={{fontSize: "14px"}}><i>{siteMap}</i></span>
                      </NavLink> <br /><br /> <br /><br />
                </td>
            </tr>
          </tbody>
      </table>;

  }
}

export default withLocalize(LeftBar);


/*
tried PDF.js with the eventual goal of launching the user manual PDF in a popup dialogue
1st encountered workerSrc issuel got over that and ran into issue where it seems can't open local filesystem PDF
then tried hitting a remote PDF and got CORS issue; then used the cors-anywhere URL and got it so load a sample publicly available
PDF but it would load a PDF shared link from my Google Drive so gave up for now

import { Document, Page, pdfjs } from "react-pdf";
import * as properties from './config/properties.js';

      numPages: null,
      pageNumber: 1,

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  componentDidMount() {
    pdfjs.GlobalWorkerOptions.workerSrc = "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.1.266/pdf.worker.js";
  }

    const { pageNumber, numPages } = this.state;

                      <div>
                        <Document
                          file= 'http://localhost:3000/userManual.pdf'
                          onLoadSuccess={this.onDocumentLoadSuccess}
                          options={{
                            cMapUrl: "//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/",
                            cMapPacked: true,
                          }}
                        >
                          <Page pageNumber={pageNumber} />
                        </Document>
                        <p>Page {pageNumber} of {numPages}</p>
                      </div>
*/