import React, { Component, PureComponent, useState } from 'react';
import './css/App.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import AppNavBar from './AppNavBar';
import RightBar from './RightBar';
import LeftBar from './LeftBar';
import SimpleSearch from './SimpleSearch';
import AdvancedSearch from './AdvancedSearch';
import SearchResults from './SearchResults';
import ParshaViewer from './ParshaViewer';
import FrequencyViewer from './FrequencyViewer';
import TextComparatorViewer from './TextComparatorViewer';
import TanachViewer from './TanachViewer';
import ObjectCloudViewer from './ObjectCloudViewer';
import YuchsinTreeViewer from './YuchsinTreeViewer';
import SiteMap from './SiteMap';
import HebrewKeyboard from './HebrewKeyboard';
import { Link, withRouter } from 'react-router-dom';
import { Container, Form, FormGroup, FormControl, Input, Label, ListGroup, ListGroupItem, Table, UncontrolledCollapse } from 'reactstrap';
import { Button as BootstrapButton } from 'reactstrap';
import CloseIcon from '@material-ui/icons/Link';
import LinkIcon from '@material-ui/icons/Link';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import SplitPane from 'react-split-pane';
import { ReactGridLayout } from "react-grid-layout";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import * as properties from './config/properties.js';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import TaamParser from './TaamParser';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import PlacementRenderer from './PlacementRenderer';
import StartMenu from './StartMenu';
import TaamParserContextMenu from './TaamParserContextMenu';
import Lexicon from './Lexicon';
import KodeshMode from './KodeshMode';
import NotificationSystem from 'react-notification-system';

//mje - Oct 29, 2019 - for obsolete code pertinent to trial and error changes to get login/authorization to work with Auth) and Okta in Oct 2019, see version history of this file in Git

const bcrypt = require('bcryptjs');
const HE_ad = "עד";

const letterCoverageGridPos = 11;

function encodeFilterIds(nodes, nodeString) {
    var nodeStr = (nodeString != undefined) ? nodeString : "0".repeat(44);
    nodes.forEach((node)=>{
        if (node.tableName == "Sefer" && node.isChecked)
            nodeStr = util.stuff(nodeStr, "1", node.id-1, 1);
        if(node.children){
            nodeStr = encodeFilterIds(node.children, nodeStr);
        }
    })
    return nodeStr;
}

function PickerModel() {
  this.list = [];
  this.values = [];
  this.renderValue = "";
}

function DualPickerModel() {
  this.masterList = [];
  this.pickerModel1 = new PickerModel();
  this.pickerModel2 = new PickerModel();

  this.getUnselected = function(selectedValues) {
      var result = [];
      this.masterList.map(function(e){
         var i = selectedValues.findIndex((v)=>{return v == e.id;});
         if (i == -1) //current master list element id not found in selected values of opposing list
             result.push(e);
      });
      return result;
  }
}

//object to handle settings that are per-bitui
function BituiSettings() {
  this.isNegative =  null;
  this.isCaseSensitive = null;
  this.repetitions = 1;
  this.transformation = 0;
  this.letterNekudaPickers = [];
  this.generalNekudaPickers = [];
  this.generalTaamPickers = [];
  this.madreigos = [2, 2, 2, 2, 2, 2]; //for sof and rosh of pasuk/parsha/sefer
  this.containerDistances = [[0,100], [0,100], [0,100], [0,100], [0,100], [0,100]]; //for sof and rosh of pasuk/parsha/sefer
  this.containerDistDirections = ["ltr", "rtl", "ltr", "rtl", "ltr", "rtl"];
  var defaultDisplay = "0" + " עד " + "∞";
  this.containerDistDisplays = [defaultDisplay, defaultDisplay, defaultDisplay, defaultDisplay, defaultDisplay, defaultDisplay];
  this.letterNikkud = {value: ""};
  this.generalNikkud = {value: ""};
  this.generalHataama = {value: ""};
  this.isSingleOpenQuote = false;
  this.isDoubleOpenQuote = false;
  this.isSingleCloseQuote = false;
  this.isDoubleCloseQuote = false;
  this.isInQuote = false;
  this.binyanim = [];
  this.zmanim = [];
  this.misparim = [];
  this.gufim = [];
  this.genders = [];
  this.misparimSof = [];
  this.gufimSof = [];
  this.gendersSof = [];
  this.prefixes = [];
  this.languages = [];
  this.wordTypes = [];
  this.objectCategories = [];
  this.numberCategories = [];
  this.hasInfix = null;
  this.hasSuffix = null;
  this.exactnessLevel = 0;
  this.frequencyRange = [1,1000];
  this.gematriaRange = [3,1640];
  this.atbashRange = [2,1390];
  this.lettersQtyRange = [1,11];
  this.vowelsQtyRange = [0,9];
  this.tenuosQtyRange = [0,6];
  this.wordsPerPasukRange = [2, 64];
  this.lettersPerPasukRange = [6, 254];
  this.lettersPerWordRange = [2.5, 8];
  this.uniqueLetterRange = [5, 22];
  this.uniqueLetterCSRange = [5, 27];
  this.tenuosPerPasukRange = [4, 96];
  this.tenuosPerWordRange = [1.25, 4.5];
  this.tenuosPerLetterRange = [0.31, 0.75];
  this.taamimPerPasukRange = [2, 35];
  this.taamimPerWordRange = [0.43, 1.5];
  this.taamimPerTenuaRange = [0.22, 0.82];
  this.gematriaPerPasukRange = [160, 18377];
  this.gematriaPerWordRange = [48.8, 731.3];
  this.gematriaPerLetterRange = [13.8, 164.8];
  this.atbashPerPasukRange = [268, 19145];
  this.atbashPerWordRange = [69.5, 791];
  this.atbashPerLetterRange = [20.2, 173.8];
  this.frequency = [1,1000];
  this.gematria = [3,1640];
  this.atbash = [2,1390];
  this.lettersQty = [1,11];
  this.vowelsQty = [0,9];
  this.tenuosQty = [0,6];
  this.wordsPerPasuk = [2, 64];
  this.lettersPerPasuk = [6, 254];
  this.lettersPerWord = [2.5, 8];
  this.uniqueLetter = [5, 22];
  this.uniqueLetterCS = [5, 27];
  this.tenuosPerPasuk = [4, 96];
  this.tenuosPerWord = [1.25, 4.5];
  this.tenuosPerLetter = [0.31, 0.75];
  this.taamimPerPasuk = [2, 35];
  this.taamimPerWord = [0.43, 1.5];
  this.taamimPerTenua = [0.22, 0.82];
  this.gematriaPerPasuk = [160, 18377];
  this.gematriaPerWord = [48.8, 731.3];
  this.gematriaPerLetter = [13.8, 164.8];
  this.atbashPerPasuk = [268, 19145];
  this.atbashPerWord = [69.5, 791];
  this.atbashPerLetter = [20.2, 173.8 ];
  this.frequencyDisplay = "1" + " עד " + "∞"; //if run into problems mixing hebrew and english numeric characters and use HE_ad defined above instead of " עד "
  this.gematriaDisplay = "3" + " עד " + "∞";
  this.atbashDisplay = "2" + " עד " + "∞";
  this.lettersQtyDisplay = "1" + " עד " + "∞";
  this.vowelsQtyDisplay = "0" + " עד " + "∞";
  this.tenuosQtyDisplay = "0" + " עד " + "∞";
  this.wordsPerPasukDisplay = "2" + " עד " + "∞";
  this.lettersPerPasukDisplay = "6" + " עד " + "∞";
  this.lettersPerWordDisplay = "2.50" + " עד " + "∞";
  this.uniqueLetterDisplay = "5" + " עד " + "∞";
  this.uniqueLetterCSDisplay = "5" + " עד " + "∞";
  this.tenuosPerPasukDisplay = "4" + " עד " + "∞";
  this.tenuosPerWordDisplay = "1.25" + " עד " + "∞";
  this.tenuosPerLetterDisplay = "0.31" + " עד " + "∞";
  this.taamimPerPasukDisplay = "2" + " עד " + "∞";
  this.taamimPerWordDisplay = "0.43" + " עד " + "∞";
  this.taamimPerTenuaDisplay = "0.22" + " עד " + "∞";
  this.gematriaPerPasukDisplay = "160" + " עד " + "∞";
  this.gematriaPerWordDisplay = "48.8" + " עד " + "∞";
  this.gematriaPerLetterDisplay = "13.8" + " עד " + "∞";
  this.atbashPerPasukDisplay = "268" + " עד " + "∞";
  this.atbashPerWordDisplay = "69.5" + " עד " + "∞";
  this.atbashPerLetterDisplay = "20.2" + " עד " + "∞";
}

function TermDistances(bituiSettingsList) {
    var quotesList = [];
    var quoteIndexList = [];
    var singleQuotesList = [];
    var doubleQuotesList = [];
    var singleQuoteIndexList = [];
    var doubleQuoteIndexList = [];
    var isInQuote = false;
    var isInSingleQuote = false;
    var isInDoubleQuote = false;
    for(let i = bituiSettingsList.length-1; i >= 0; i--){
        var currBituiSettings = bituiSettingsList[i]
        currBituiSettings.index = i;
        //find quotes so distances can be set for them
        if(currBituiSettings.isSingleCloseQuote && isInSingleQuote === false){
            isInSingleQuote = true;
            singleQuotesList.unshift({
                isValidQuote: false, // using this to determine if there are both open and close quotes
                indexList: [currBituiSettings.index]
            })
        } else if (isInSingleQuote) {
            singleQuotesList[0].indexList.unshift(currBituiSettings.index);
        }

        if(currBituiSettings.isDoubleCloseQuote && isInDoubleQuote === false){
            isInDoubleQuote = true;
            doubleQuotesList.unshift({
                isValidQuote: false, // using this to determine if there are both open and close quotes
                indexList: [currBituiSettings.index]
            })
        } else if (isInDoubleQuote) {
            doubleQuotesList[0].indexList.unshift(currBituiSettings.index);
        }

        if (currBituiSettings.isSingleOpenQuote && isInSingleQuote === true) {
            singleQuotesList[0].isValidQuote = true;
            singleQuoteIndexList = singleQuoteIndexList.concat(singleQuotesList[0].indexList);
            isInSingleQuote = false;
        }
        if (currBituiSettings.isDoubleOpenQuote && isInDoubleQuote === true) {
            doubleQuotesList[0].isValidQuote = true;
            doubleQuoteIndexList = doubleQuoteIndexList.concat(doubleQuotesList[0].indexList);
            isInDoubleQuote = false;
        }

        for(let j = i-1; j >= 0; j--){
            var termPairKey = `${i}-${j}`;
            this[termPairKey] = {};
            this[termPairKey].termPosition1 = j;
            this[termPairKey].termPosition2 = i;
            this[termPairKey].distance = [0,100];
            this[termPairKey].direction = 0;
            this[termPairKey].distDisplay = "0" + " עד " + "∞";
            this[termPairKey].madreiga = 2;
            this[termPairKey].termsInSameQuote = false;
        }
    }

    var fullQuotesList = singleQuotesList.concat(doubleQuotesList)

    //set distances for words in quotes
    for (let i = 0; i < Object.keys(this).length; i++){
        var currKey = Object.keys(this)[i]
        var termPosition1 = this[currKey].termPosition1;
        var termPosition2 = this[currKey].termPosition2;

        var quoteIndex = fullQuotesList.findIndex((quoteObject) => {
            if(quoteObject.isValidQuote && quoteObject.indexList.indexOf(termPosition1) !== -1 && quoteObject.indexList.indexOf(termPosition2) !== -1){
                return (quoteObject.isValidQuote && quoteObject.indexList.indexOf(termPosition1) !== -1 && quoteObject.indexList.indexOf(termPosition2) !== -1)
            }
        });

        if(quoteIndex !== -1){ //if there is a valid quote in fullQuotesList that has both terms in it
            var distance = Math.abs(termPosition1-termPosition2)-1;
            this[currKey].distance = [100-distance, 100-distance];
            this[currKey].distDisplay = `${distance} עד ${distance}`;
            this[currKey].direction = -1;
            this[currKey].termsInSameQuote = true;
        }
    }
}

var defaultGeneralNekudaList = [0,0,0,0,0,0,0,0,0,0,0,0,0]; //array len = 13
var defaultGeneralTaamList = [0,0,0,0,0,0,0,0]; //array len = 8

  /*
      package what's relevant - remove what's not necessary

      public Integer getSearchLevel() {return getInt("searchLevel");} //eg. simple, advanced, etc.
      public Boolean isInSequence() {return getBoolean("isInSequence");} //do the terms need to be in sequence?
      public String getMorphInflections() {return getStr("morphInflections");} //LNTGS
      public ArrayList<IncludeExcludeLNT> getIncludeExcludeLNTs() {return (ArrayList<IncludeExcludeLNT>)getValue("includeExcludeLNTs");}
      public ArrayList<NamedLocation> getNamedLocations() {return (ArrayList<NamedLocation>)getValue("namedLocations");}
      public ArrayList<IdRange> getPasukRangeLocations() {return (ArrayList<IdRange>)getValue("pasukRangeLocations");}
      //per term settings
      public ArrayList<TermParameters> getBituiParameters() {return (ArrayList<TermParameters>)getValue("bituiParameters");}

IncludeExcludeLNT
      public Boolean isInclude() {return getBoolean("isInclude");} //include vs. exclude?
      public String getLNT() {return getStr("lnt");} //eg. L, N, T
      public ArrayList<Integer> getIds() {return (ArrayList<Integer>)getValue("ids");}
NamedLocation
      public Integer getKriaId() {return getInt("kriaId");}
      public Integer getAliyaId() {return getInt("aliyaId");}
IdRange
      public Integer getId1() {return getInt("id1");}
      public Integer getId2() {return getInt("id2");}
TermParameters
      public String getTerm() {return getStr("term");}
      public Boolean isNegative() {return getBoolean("isNegative");}
      public Boolean isCaseSensitive() {return getBoolean("isCaseSensitive");}
      public Integer getRepetitions() {return getInt("repetitions");}
      public Integer getTransformation() {return getInt("transformation");}
      public ArrayList<ArrayList<Integer>> getLetterNekudos() {return (ArrayList<ArrayList<Integer>>)getValue("letterNekudos");}
      public ArrayList<Integer> getNekudos() {return (ArrayList<Integer>)getValue("nekudos");}
      public ArrayList<Integer> getTaamim() {return (ArrayList<Integer>)getValue("taamim");}
      public ArrayList<DistanceRange> getDistances() {return (ArrayList<DistanceRange>)getValue("distances");}
DistanceRange
      public Integer getContainerLevelId() {return getInt("containerLevelId");} //eg. pasuk||parsha||sefer, etc.
      public Integer getUnitLevelId() {return getInt("unitLevelId");} //eg. letter||pasuk||parsha, etc.
      public Integer getDistance1() {return getInt("distance1");}
      public Integer getDistance2() {return getInt("distance2");}
    // pos for +start, neg for -end
   */

function SearchParameters() {
  this.textSource=1;
  this.searchLevel =  null; //int
  this.isInSequence = null; //bool
  this.morphInflections = null; //str
  this.scope = null; //
  this.sortDirection = 0;
  this.sortOrder = 0;
  this.filterIds = null; //str
  this.includeExcludeLNTs = []; //IncludeExcludeLNT()
  this.pasukSettings = new PasukSettings();
  this.placementSettings = new PlacementSettings();
  this.tanachYear = []; // Range() - temp here till restructure
  this.wordsPerPasuk = []; // Range() - temp here till restructure
  this.lettersPerPasuk = []; // Range() - temp here till restructure
  this.lettersPerWord = []; // Range() - temp here till restructure
  this.uniqueLetter = []; // Range() - temp here till restructure
  this.uniqueLetterCS = []; // Range() - temp here till restructure
  this.tenuosPerPasuk = []; // Range() - temp here till restructure
  this.tenuosPerWord = []; // Range() - temp here till restructure
  this.tenuosPerLetter = []; // Range() - temp here till restructure
  this.taamimPerPasuk = []; // Range() - temp here till restructure
  this.taamimPerWord = []; // Range() - temp here till restructure
  this.taamimPerTenua = []; // Range() - temp here till restructure
  this.gematriaPerPasuk = []; // Range() - temp here till restructure
  this.gematriaPerWord = []; // Range() - temp here till restructure
  this.gematriaPerLetter = []; // Range() - temp here till restructure
  this.atbashPerPasuk = []; // Range() - temp here till restructure
  this.atbashPerWord = []; // Range() - temp here till restructure
  this.atbashPerLetter = []; // Range() - temp here till restructure
  this.hasParshios = null;
  this.hasKrios = null;
  this.hasSederTefila = null;
  this.hasShiros = null;
  this.hasBrachos = null;
  this.hasTefilos = null;
  this.hasMitzvos = null;
  this.hasShonos = null;
  this.locationIncludeFlag = true;
  this.namedLocations =  []; //NamedLocation()
  this.pasukRangeLocations = []; //PerekPasukRange()
  this.bituiParameters = []; //TermParameters()
  this.termDistances = [];
  this.pasukIds = [];
  this.searchWithinResults = false;
}

function PasukSettings() {
  this.tanachYear = [0, 3438]; //Range()
  this.wordsPerPasuk = [2, 64]; //Range()
  this.lettersPerPasuk = [6, 254]; //Range()
  this.lettersPerWord = [2.5, 8]; //Range()
  this.uniqueLetter = [5, 22]; //Range()
  this.uniqueLetterCS = [5, 27]; //Range()
  this.tenuosPerPasuk = [4, 96]; //Range()
  this.tenuosPerWord = [1.25, 4.5]; //Range()
  this.tenuosPerLetter = [0.31, 0.75]; //Range()
  this.taamimPerPasuk = [2, 35]; //Range()
  this.taamimPerWord = [0.43, 1.5]; //Range()
  this.taamimPerTenua = [0.22, 0.82]; //Range()
  this.gematriaPerPasuk = [160, 18377]; //Range()
  this.gematriaPerWord = [48.8, 731.3]; //Range()
  this.gematriaPerLetter = [13.8, 164.8]; //Range()
  this.atbashPerPasuk = [268, 19145]; //Range()
  this.atbashPerWord = [69.5, 791]; //Range()
  this.atbashPerLetter = [20.2, 173.8]; //Range()
  this.tanachYearDisplay =  "0" + " עד " + "∞";
  this.wordsPerPasukDisplay =  "2" + " עד " + "∞";
  this.lettersPerPasukDisplay = "6" + " עד " + "∞";
  this.lettersPerWordDisplay = "2.50" + " עד " + "∞";
  this.uniqueLetterDisplay = "5" + " עד " + "∞";
  this.uniqueLetterCSDisplay = "5" + " עד " + "∞";
  this.tenuosPerPasukDisplay = "4" + " עד " + "∞";
  this.tenuosPerWordDisplay = "1.25" + " עד " + "∞";
  this.tenuosPerLetterDisplay = "0.31" + " עד " + "∞";
  this.taamimPerPasukDisplay = "2" + " עד " + "∞";
  this.taamimPerWordDisplay = "0.43" + " עד " + "∞";
  this.taamimPerTenuaDisplay = "0.22" + " עד " + "∞";
  this.gematriaPerPasukDisplay = "160" + " עד " + "∞";
  this.gematriaPerWordDisplay = "48.8" + " עד " + "∞";
  this.gematriaPerLetterDisplay = "13.8" + " עד " + "∞";
  this.atbashPerPasukDisplay = "268" + " עד " + "∞";
  this.atbashPerWordDisplay = "69.5" + " עד " + "∞";
  this.atbashPerLetterDisplay = "20.2" + " עד " + "∞";
  this.tanachYearRange = [0, 3438]; //Range()
  this.wordsPerPasukRange = [2, 64]; //Range()
  this.lettersPerPasukRange = [6, 254]; //Range()
  this.lettersPerWordRange = [2.5, 8]; //Range()
  this.uniqueLetterRange = [5, 22]; //Range()
  this.uniqueLetterCSRange = [5, 27]; //Range()
  this.tenuosPerPasukRange = [4, 96]; //Range()
  this.tenuosPerWordRange = [1.25, 4.5]; //Range()
  this.tenuosPerLetterRange = [0.31, 0.75]; //Range()
  this.taamimPerPasukRange = [2, 35]; //Range()
  this.taamimPerWordRange = [0.43, 1.5]; //Range()
  this.taamimPerTenuaRange = [0.22, 0.82]; //Range()
  this.gematriaPerPasukRange = [160, 18377]; //Range()
  this.gematriaPerWordRange = [48.8, 731.3]; //Range()
  this.gematriaPerLetterRange = [13.8, 164.8]; //Range()
  this.atbashPerPasukRange = [268, 19145]; //Range()
  this.atbashPerWordRange = [69.5, 791]; //Range()
  this.atbashPerLetterRange = [20.2, 173.8]; //Range()
}

function PlacementSettings() {
  this.hasParshios = null;
  this.hasKrios = null;
  this.hasSederTefila = null;
  this.hasShiros = null;
  this.hasBrachos = null;
  this.hasTefilos = null;
  this.hasMitzvos = null;
  this.hasShonos = null;
  this.placementIncludeExclude = 100;
}


function IncludeExcludeLNT(isInclude, lnt, ids) {
  this.isInclude =  isInclude; //bool
  this.lnt = lnt; //str
  this.ids = ids; //int
}

function NamedLocation(kriaId, aliyaId) {
  this.kriaId =  kriaId; //int
  this.aliyaId = aliyaId; //int
}

function PerekPasukRange(seferId1, perekNum1, pasukNum1, seferId2, perekNum2, pasukNum2) {
  this.seferId1 =  seferId1; //int
  this.perekNum1 = perekNum1;  //int
  this.pasukNum1 =  pasukNum1; //int
  this.seferId2 = seferId2;  //int
  this.perekNum2 =  perekNum2; //int
  this.pasukNum2 = pasukNum2;  //int
}

function TermParameters() {
  this.term =  null; //str
  this.isNegative = null; //bool
  this.isCaseSensitive = null; //bool
  this.repetitions = 1; //int
  this.transformation =  null; //int
  this.letterNekudos = []; //array of arrays ids
  this.nekudos = []; //ids
  this.taamim =  []; //ids
  this.containerDistances = []; //DistanceRange()

  //the following are all from the POS tab
  this.binyanim = []; //ids
  this.zmanim = []; //ids
  this.misparim = []; //ids
  this.gufim = []; //ids
  this.genders = []; //ids
  this.misparimSof = []; //ids
  this.gufimSof = []; //ids
  this.gendersSof = []; //ids
  this.prefixes = []; //ids
  this.languages = []; //ids
  this.wordTypes = []; //ids
  this.objectCategories = []; //ids
  this.numberCategories = []; //ids
  this.hasInfix = null; //bool
  this.hasSuffix = null; //bool
  this.frequency = []; //Range()
  this.gematria = []; //Range()
  this.atbash = []; //Range()
  this.lettersQty = []; //Range()
  this.vowelsQty = []; //Range()
  this.tenuosQty = []; //Range()
}

function Range(min, max) {
  this.min = min; //int
  this.max = max; //int
}

function ContainerDistanceRange(containerLevelId, unitLevelId, distance1, distance2) {
  this.containerLevelId = containerLevelId; //int
  this.unitLevelId = unitLevelId; //int
  this.distance1 = distance1; //int pos for +start, neg for -end
  this.distance2 = distance2; //int pos for +start, neg for -end
}

const baseSortOrderId = 0;

class Home extends PureComponent {

//webServiceURL = properties.env.webServiceURL;
////webServiceURL = "http://localhost:8080" - it originally was this but found out that when hitting site from outside, this wouldn't work sinc I guess this is loaded to client browser
baseURL = properties.env.applicationURL;

 constructor(props) {
    super(props);
    this.state = {
      viewer: this.props.viewer,
      serverMessage: "",
      menuValue: 1,
      isLoading: true,
      searchIsLoading: true,
      clientIP: '',
      getPesukimWithWords: [],
      columnDefs: this.gridColumns,
      rowData: [],
      columnDefs2: this.gridColumns2,
      rowData2: [],
      bituim: [{term: " "}],
      searchParams: new SearchParameters(),
      bituiSettingsList: [new BituiSettings()],
      termDistances: {},
      dualLetterPicker: new DualPickerModel(),
      dualNekudaPicker: new DualPickerModel(),
      dualTaamPicker: new DualPickerModel(),
      nekudaList: [],
      taamList: [],
      placementRowData: [{type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []}],
      text1Data: [{ type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []}],
      text2Data: [{ type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []}],
      textComparatorText1Api: null,
      textComparatorText1ColumnApi: null,
      textComparatorText2Api: null,
      textComparatorText2ColumnApi: null,
      textComparatorWordCountData: {
        totalTerms1: 0,
        totalTerms2: 0,
        termTotal1Accurate: true,
        termTotal2Accurate: true,
      },
      isFillingComparatorSelector: false,
      defaultLanguage: '',
      extSearchDefData: 'L', //initialize to teiva-only search
      extFilterData: [{id: 1, name: "junk", tableName: "junk"},{id: 2, name: "junk", tableName: "junk"}],
      extBituimText: '',
      advancedSearchHeight: 0,
      searchResultsHeight: '0px',
      viewerHeight: '620px',
      extKeyboardData: '',
      advancedSearchTabValue: 4,
      renderAdvancedSearch: true,
      lastSearchInputTime: Date.now(),
      resultsReceived: false,
      //moved up from simpleSearch so settings download/upload can work properly
      terms: '',
      scope: 'all',
      seq: 'seq',
      //moved up from rightBar so settings download/upload can work properly
      filterData: [],
      letters: 'L',
      nekudos: '',
      taamim: '',
      gematria: '',
      sortDirection: 0,
      sortOrder: 0,
      sortedColumn: baseSortOrderId, //this matches the id of the automatic sort(appearence)
      invalidFileDialogOpen: false,
      lastSearchTerms: '',
      aboutTabIndex: 0,
      forceOpenAboutDialog: 0,
      openResults: [],
      searchResultsOpen: this.props.searchOpen,
      keepResultsOpen: false,
      searchWithinResults: false,
      searchResultsTabValue: 0,
      showLastSearchResults: false,
      closeSearchResultsDialogOpen: false,
      taamParserOpen: false,
      taamParseData: {},
      taamParserPasukId: 1,
      taamParserLocation: null,
      hasInvalidQuote: false,
      tanachViewerOpen: false,
      contextualizedPasukId: null,
      searchResultsPerPage: 10,
      parshaViewerResultsApi: null,
      isLexiconOpenedFromURL: false,
      isFirstLexiconOpenToggle: true,
      isFirstLexiconCloseToggle: true,
      tanachViewerPosition: {
          x: 0,
          y: 0,
          widthNum: 0,
          heightNum: 0
      },
      taamParserPosition: {
        x: 0,
        y: 0,
        widthNum: 0,//window.innerWidth-150,
        heightNum: 0,//window.innerHeight-150,
    },
    lexiconPosition: {
        x: 0,
        y: 0,
        widthNum: 0,
        heightNum: 0,
    },
      helpStartupTopic: "layout",
      objectCloudViewerOpen: false,
      objectCloudViewerPosition: {
        x: 0,
        y: 0,
        widthNum: 0,
        heightNum: 0
      },
      ssExactnessLevel: 0,
      yuchsinTreeViewerOpen: false,
      yuchsinTreeViewerPosition: {
        x: 0,
        y: 0,
        widthNum: 0,
        heightNum: 0
      },
      updateLeftBar:true,
      wordTooltipCoordinates:{x:100,y:100},
      deletedResults:false,
      textSource:'tanach',
      textSourceSearched:['tanach'],
      isFirstTimeOpened:true,
      isInitializedPasukFetched:false,
      isJustOpened:true,
      lexiconOpen:false,
      simpleSearchKb:false,
      lexiconKeyboard:false,
      rowDataForTotalTooltip:[[]],
      includeOtherStats:false,
      changedStatsArray:[],
      changedStatsArrayBool:false,
      premiumServicesDialogOpen:false,
      aboutDialogOpen: false,
      helpDialogOpen: false,
    };

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });

    this.notificationSystem = React.createRef();

    this.props.addTranslation(translations);
    this.setLanguage = (code) => {props.setActiveLanguage(code)}

    this.closeAdvancedSearch = this.closeAdvancedSearch.bind(this);
    this.closeSearchResults = this.closeSearchResults.bind(this);
    this.setStateSynchronous = this.setStateSynchronous.bind(this);
    this.resetPlacementState = this.resetPlacementState.bind(this);
    this.resetIncludeExcludeState = this.resetIncludeExcludeState.bind(this);
    this.resetAllNikudTaamimState = this.resetAllNikudTaamimState.bind(this);
    this.resetBituiNikudTaamimState = this.resetBituiNikudTaamimState.bind(this);
    this.resetDistancesState = this.resetDistancesState.bind(this);
    this.resetIsCaseSensitive = this.resetIsCaseSensitive.bind(this);
    this.resetIsNegative = this.resetIsNegative.bind(this);
    this.resetRepetitions = this.resetRepetitions.bind(this);
    this.resetTransformations = this.resetTransformations.bind(this);
    this.resetTermContainerDistances = this.resetTermContainerDistances.bind(this);
    this.resetTermPairDistances = this.resetTermPairDistances.bind(this);
    this.resetPartOfSpeechState = this.resetPartOfSpeechState.bind(this);
    this.resetAllAdvancedSearchSettings = this.resetAllAdvancedSearchSettings.bind(this);
    this.passGridApiToHome = this.passGridApiToHome.bind(this)
    this.setAdvancedSearchTabValue = this.setAdvancedSearchTabValue.bind(this);
    this.renderLetterNikkud = this.renderLetterNikkud.bind(this);
    this.renderGeneralNikkud = this.renderGeneralNikkud.bind(this);
    this.renderGeneralHataama = this.renderGeneralHataama.bind(this);
    this.submitSearch = this.submitSearch.bind(this);
    this.getSearchSettings = this.getSearchSettings.bind(this);
    this.loadSearchParams = this.loadSearchParams.bind(this);
    this.loadSearchResults = this.loadSearchResults.bind(this);
    this.handleOpenInvalidFileDialog = this.handleOpenInvalidFileDialog.bind(this);
    this.handleCloseInvalidFileDialog = this.handleCloseInvalidFileDialog.bind(this);
    this.toggleKeepResultsOpen = this.toggleKeepResultsOpen.bind(this);
    this.toggleSearchWithinResults = this.toggleSearchWithinResults.bind(this);
    this.toggleYuchsinTreeViewer = this.toggleYuchsinTreeViewer.bind(this);
    this.toggleLexicon = this.toggleLexicon.bind(this);
    this.togglePremiumServices = this.togglePremiumServices.bind(this);
    this.toggleAboutDialog = this.toggleAboutDialog.bind(this);
    this.toggleHelpDialog = this.toggleHelpDialog.bind(this);
    this.setLexiconPosition = this.setLexiconPosition.bind(this);
    this.setLexiconSize = this.setLexiconSize.bind(this);
    this.handleLexiconFullscreen = this.handleLexiconFullscreen.bind(this);
    this.handleLexiconSplitScreen = this.handleLexiconSplitScreen.bind(this);

    this.closeResultsTab = this.closeResultsTab.bind(this);
    this.handleSearchResultsTabChange = this.handleSearchResultsTabChange.bind(this);
    this.handleCloseSearchResultsDialogOpen = this.handleCloseSearchResultsDialogOpen.bind(this);
    this.handleCloseSearchResultsDialogClose = this.handleCloseSearchResultsDialogClose.bind(this);

    this.deleteSelectedResults = this.deleteSelectedResults.bind(this);

    //moving simple search settings into home so settings download/upload can work properly
    this.setTerms = this.setTerms.bind(this);
    this.setScope = this.setScope.bind(this);
    this.setSeq = this.setSeq.bind(this);
    //moving rightBar settings into home from rightBar so settings download/upload can work properly
    this.setFilterData = this.setFilterData.bind(this);
    this.setLetters = this.setLetters.bind(this);
    this.setNekudos = this.setNekudos.bind(this);
    this.setTaamim = this.setTaamim.bind(this);
    this.setGematria = this.setGematria.bind(this);
    this.setSortDirection = this.setSortDirection.bind(this);
    this.setSortOrder = this.setSortOrder.bind(this);

    //moving distance settings into home from advance search so settings download/upload can work properly
    this.setContainerDistances = this.setContainerDistances.bind(this);
    this.setContainerMadreigos = this.setContainerMadreigos.bind(this);
    this.setTermDistances = this.setTermDistances.bind(this);

    this.setCoordinatesWordTooltip = this.setCoordinatesWordTooltip.bind(this);
    this.openSearchResultsHeight = this.openSearchResultsHeight.bind(this);
    this.setWordTooltipOff = this.setWordTooltipOff.bind(this);
    this.colorWord = this.colorWord.bind(this);
    this.setUpTaam = this.setUpTaam.bind(this);
    this.setUpTargum = this.setUpTargum.bind(this);
    this.setUpWordType = this.setUpWordType.bind(this);
    this.setUpShoresh = this.setUpShoresh.bind(this);
    this.setUpAppearancesShoresh = this.setUpAppearancesShoresh.bind(this);

    //setters for POS tab sliders
//    this.setPartOfSpeechSliders = this.setPartOfSpeechSliders.bind(this);
//    this.setFrequency = this.setFrequency.bind(this);
//    this.setGematria = this.setGematria.bind(this);
//    this.setAtbash = this.setAtbash.bind(this);
//    this.setLettersQty = this.setLettersQty.bind(this);

    this.onPaneChange = this.onPaneChange.bind(this);
    this.getCurrentPasukIds = this.getCurrentPasukIds.bind(this);

    this.copySelectedToSearchbar = this.copySelectedToSearchbar.bind(this);
    this.searchWithSelected = this.searchWithSelected.bind(this);

    this.openTaamParser = this.openTaamParser.bind(this);
    this.closeTaamParser = this.closeTaamParser.bind(this);
    this.fetchTaamParseData = this.fetchTaamParseData.bind(this);

    this.validateQuotes = this.validateQuotes.bind(this);
    this.openDockedViewer = this.openDockedViewer.bind(this);
    this.closeDockedViewer = this.closeDockedViewer.bind(this);
    this.toggleTanachViewer = this.toggleTanachViewer.bind(this);
    this.toggleTaamParser = this.toggleTaamParser.bind(this);
    this.resetTaamParserPosition = this.resetTaamParserPosition.bind(this);
    this.extUpdateOpenResults = this.extUpdateOpenResults.bind(this);
    this.extUpdateSearchResultsPerPage = this.extUpdateSearchResultsPerPage.bind(this);

    this.updateTextComparatorWordCountData = this.updateTextComparatorWordCountData.bind(this);
    this.textComparatorText1GridReady = this.textComparatorText1GridReady.bind(this);
    this.modifyTextComparatorText1Row = this.modifyTextComparatorText1Row.bind(this);
    this.addTextComparatorText1Row = this.addTextComparatorText1Row.bind(this);
    this.deleteTextComparatorText1Row = this.deleteTextComparatorText1Row.bind(this);
    this.textComparatorText2GridReady = this.textComparatorText2GridReady.bind(this);
    this.modifyTextComparatorText2Row = this.modifyTextComparatorText2Row.bind(this);
    this.addTextComparatorText2Row = this.addTextComparatorText2Row.bind(this);
    this.deleteTextComparatorText2Row = this.deleteTextComparatorText2Row.bind(this);
    this.fillTextComparator = this.fillTextComparator.bind(this);
    this.setTextSource = this.setTextSource.bind(this);

    this.setExactnessLevel = this.setExactnessLevel.bind(this);
    this.sliderValueSet = this.sliderValueSet.bind(this);

    this.resetExactnessLevel = this.resetExactnessLevel.bind(this);

    this.initializeSearchState = this.initializeSearchState.bind(this);

    this.setAboutTabIndex = this.setAboutTabIndex.bind(this);

    this.addNotification = this.addNotification.bind(this);

    this.openLexiconViewer = this.openLexiconViewer.bind(this);
    this.handleClearRBChangesFromLexicon = this.handleClearRBChangesFromLexicon.bind(this);
    this.handleClearSourceChangesFromLexicon = this.handleClearSourceChangesFromLexicon.bind(this);
    this.setBeta = this.setBeta.bind(this);
    this.toggleIncludeOtherStats = this.toggleIncludeOtherStats.bind(this);
    this.toggleIsLexiconOpenedFromURL = this.toggleIsLexiconOpenedFromURL.bind(this);
    this.handleCopyLink = this.handleCopyLink.bind(this);
    this.setHelpTopic = this.setHelpTopic.bind(this);

    // this.openContextMenu = this.openContextMenu.bind(this);
    // this.setContextMenuOff = this.setContextMenuOff.bind(this);

    // this.resetSuperTreeData = this.resetSuperTreeData.bind(this);

    var language = this.props.language;
    if (language && ["he", "en", "hebrew", "english"].indexOf(language.toLowerCase()) >= 0) { //language manually set via URL parameter
        language = ["he", "hebrew"].indexOf(language.toLowerCase()) >= 0 ? "he" : "en";
        this.setLanguage(language);
        localStorage.setItem("language", language);
    }

        //this done for when initialize site in taamParser page
    if(this.props.taamParserOpen) {
        //localStorage.setItem("helpDialogStartupTopic", "taamParser"); //for some reason, clicking help button after this still does not load correct page - maybe need to force focus on this page as maybe clicking the help button sets focus to the nav bar?
        this.openTaamParser(this.props.pasukLocation);
    }

    if(this.props.yuchsinTreeViewerOpen) {
        this.state.yuchsinTreeViewerOpen = this.props.yuchsinTreeViewerOpen;
        //this.handleYuchsinTreeViewerFullscreen()
        this.state.yuchsinTreeViewerPosition = {
                x: 0,
                y: 0,
                heightNum: window.innerHeight,
                widthNum: window.innerWidth
            }
        }
    }

    toggleIsLexiconOpenedFromURL(isOpened) {
        this.setState({isLexiconOpenedFromURL: isOpened});
    }

    addNotification ( message,dismiss){
            const notification = this.notificationSystem.current;
            notification.addNotification({
            message: <div style={{direction:this.props.activeLanguage && this.props.activeLanguage.code === 'he'?'rtl':'ltr',textAlign:this.props.activeLanguage && this.props.activeLanguage.code === 'he'?'right':'left'}}>{message}</div>,//,float:'right'
            title:<span style={{direction:this.props.activeLanguage && this.props.activeLanguage.code === 'he'?'rtl':'ltr'}}>{this.props.translate('notice')}</span>,
            level: 'info',
            position:'tc',
            autoDismiss:dismiss
            });            
      };
    

  setHelpTopic(topic) {
      this.setState({helpStartupTopic: topic});
  }

  setHelpStartupTopic(e){
    var clickedElem = e.target;
    while(clickedElem.parentElement && !clickedElem.attributes.helpTopic){
        clickedElem = clickedElem.parentElement;
    }
    if(clickedElem.attributes && clickedElem.attributes.helpTopic){
        var clickedHelpTopic = clickedElem.attributes.helpTopic.value;
        if(!["helpButton", "menuButton", this.state.helpStartupTopic].includes(clickedHelpTopic)){ // ignore doubles and clicks on help, menu buttons
            this.setState({helpStartupTopic: clickedHelpTopic})
        }
    }
  }
    setTaamParserSize(widthNum, heightNum, x, y){
        var taamParserPosition = {
            x: x,
            y: y,
            widthNum: widthNum,
            heightNum: heightNum
        }
        this.setState({taamParserPosition})
    }

    setTanachViewerSize(widthNum, heightNum, x, y){
        var tanachViewerPosition = {
            x: x,
            y: y,
            widthNum: widthNum,
            heightNum: heightNum
        }
        this.setState({tanachViewerPosition})
    }

    setObjectCloudViewerSize(widthNum, heightNum, x, y){
        var objectCloudViewerPosition = {
            x: x,
            y: y,
            widthNum: widthNum,
            heightNum: heightNum
        }
        this.setState({objectCloudViewerPosition})
    }

    setYuchsinTreeViewerSize(widthNum, heightNum, x, y){
        var yuchsinTreeViewerPosition = {
            x: x,
            y: y,
            widthNum: widthNum,
            heightNum: heightNum
        }
        this.setState({yuchsinTreeViewerPosition})
    }
    setLexiconSize(widthNum, heightNum, x, y){
        var lexiconPosition = {
            x: x,
            y: y,
            widthNum: widthNum,
            heightNum: heightNum
        }
        this.setState({lexiconPosition})
    }

    setLexiconPosition(x, y){
        var newLexiconPosition = {
            x: x,
            y: y,
            heightNum: this.state.lexiconPosition.heightNum,
            widthNum: this.state.lexiconPosition.widthNum,
        };
        this.setState({lexiconPosition: newLexiconPosition})
    }


    setTaamParserPosition(x, y){
        var newTaamParserPosition = {
            x: x,
            y: y,
            heightNum: this.state.taamParserPosition.heightNum,
            widthNum: this.state.taamParserPosition.widthNum,
        };
        this.setState({taamParserPosition: newTaamParserPosition})
    }

    setTanachViewerPosition(x, y){
        var newTanachViewerPosition = {
            x: x,
            y: y,
            heightNum: this.state.tanachViewerPosition.heightNum,
            widthNum: this.state.tanachViewerPosition.widthNum,
        };
        this.setState({tanachViewerPosition: newTanachViewerPosition})
    }

    setObjectCloudViewerPosition(x, y){
        var newObjectCloudviewerPosition = {
            x: x,
            y: y,
            heightNum: this.state.objectCloudViewerPosition.heightNum,
            widthNum: this.state.objectCloudViewerPosition.widthNum,
        };
        this.setState({objectCloudViewerPosition: newObjectCloudviewerPosition})
    }
    setYuchsinTreeViewerPosition(x, y){
        var newYuchsinTreeViewerPosition = {
            x: x,
            y: y,
            heightNum: this.state.yuchsinTreeViewerPosition.heightNum,
            widthNum: this.state.yuchsinTreeViewerPosition.widthNum,
        };
        this.setState({yuchsinTreeViewerPosition: newYuchsinTreeViewerPosition})
    }

    resetTanachViewerPosition(callback){
        const widthNum = window.innerWidth > 1000 ? 800 : window.innerWidth - 200;
        const heightNum = window.innerHeight > 600 ? 400 : window.innerHeight - 200;
        const homeScrollContainer = document.getElementById("home-scroll-container");
        const scrollLeft = homeScrollContainer ? homeScrollContainer.scrollLeft : 0;
        this.setState({
            tanachViewerPosition: {
                widthNum: widthNum,
                heightNum: window.innerHeight > 600 ? 400 : window.innerHeight - 200,
                x: ((window.innerWidth - widthNum) / 2) + scrollLeft,
                y: (window.innerHeight - heightNum) / 2,
            }
        }, () => {
          if(callback){
            callback();
          }
        })
      }
      resetTaamParserPosition(callback){
        const widthNum = window.innerWidth-150;
        const heightNum = window.innerHeight-150;
        const homeScrollContainer = document.getElementById("home-scroll-container");
        this.setState({
            taamParserPosition: {
                widthNum: widthNum,
                heightNum: heightNum,
                x: ((window.innerWidth - widthNum) / 2) + homeScrollContainer.scrollLeft,
                y: (window.innerHeight - heightNum) / 2,
            }
        }, () => {
          if(callback){
            callback();
          }
        })
      }

      resetObjectCloudViewerPosition(callback){
        const widthNum = window.innerWidth > 1000 ? 800 : window.innerWidth - 200;
        const heightNum = window.innerHeight > 600 ? 400 : window.innerHeight - 200;
        const homeScrollContainer = document.getElementById("home-scroll-container");
        this.setState({
            objectCloudViewerPosition: {
                widthNum: widthNum,
                heightNum: window.innerHeight > 600 ? 480 : window.innerHeight - 250,
                x: ((window.innerWidth - widthNum) / 2) + homeScrollContainer.scrollLeft,
                y: (window.innerHeight - heightNum) / 2,
            }
        }, () => {
          if(callback){
            callback();
          }
        })
      }
      resetLexiconPosition(callback){
        const widthNum = window.innerWidth > 1100 ? 1075 : window.innerWidth - 200;
        const heightNum = window.innerHeight > 700 ? 620 : window.innerHeight - 200;
        const homeScrollContainer = document.getElementById("home-scroll-container");
        this.setState({
            lexiconPosition: {
                widthNum: widthNum,
                heightNum: window.innerHeight > 700 ? 620 : window.innerHeight - 250,
                x: ((window.innerWidth - widthNum) / 2), //+ homeScrollContainer.scrollLeft,
                y: (window.innerHeight - heightNum) / 2,
            }
        }, () => {
          if(callback){
            callback();
          }
        })
      }

      resetYuchsinTreeViewerPosition(callback){
        const widthNum = window.innerWidth > 1300 ? 1300 : window.innerWidth - 200; //switched from 1200 to 1300 to allow for an extra corner button
        const heightNum = window.innerHeight > 800 ? 800 : window.innerHeight - 200;
        const homeScrollContainer = document.getElementById("home-scroll-container");
        this.setState({
            yuchsinTreeViewerPosition: {
                widthNum: widthNum,
                heightNum: window.innerHeight > 800 ? 800 : window.innerHeight - 200,
                x: ((window.innerWidth - widthNum) / 2) + homeScrollContainer.scrollLeft,
                y: (window.innerHeight - heightNum) / 2,
            }
        }, () => {
          if(callback){
            callback();
            callback();
          }
        })
      }

    isFullScreen(x, y, heightNum, widthNum) {
        //alert(Object.is(position, {x: 0, y: 0, heightNum: window.innerHeight, widthNum: window.innerWidth})); - not sure why this didn't work
        return x == 0 && y == 0 && heightNum == window.innerHeight && widthNum == window.innerWidth;
    }

    handleCopyLink(pageName, params, isFullScreen) {
        var url = properties.env.applicationURL + "/#/" + pageName + "?language=" + this.props.activeLanguage.code;

        if (isFullScreen)
            url += "&fullscreen=T";

        var i;
        if (params) {
            for (i=0; i < params.length; i++)
                url += "&"+params[i];
        }

        navigator.clipboard.writeText(url);
    }

    handleTaamParserFullscreen(){
        this.setState({
            taamParserPosition: {
                x: 0,
                y: 0,
                heightNum: window.innerHeight,
                widthNum: window.innerWidth
            }
        })
    }

    handleTanachViewerFullscreen(){
        this.setState({
            tanachViewerPosition: {
                x: 0,
                y: 0,
                heightNum: window.innerHeight,
                widthNum: window.innerWidth
            }
        })
    }

    handleLexiconFullscreen(){
        this.setState({
            lexiconPosition: {
                x: 0,
                y: 0,
                heightNum: window.innerHeight,
                widthNum: window.innerWidth
            }
        })
    }


    handleObjectCloudViewerFullscreen(){
        this.setState({
            objectCloudViewerPosition: {
                x: 0,
                y: 0,
                heightNum: window.innerHeight,
                widthNum: window.innerWidth
            }
        })
    }

    handleYuchsinTreeViewerFullscreen(){
        this.setState({
            yuchsinTreeViewerPosition: {
                x: 0,
                y: 0,
                heightNum: window.innerHeight,
                widthNum: window.innerWidth
            }
        })
    }
    handleTaamParserSplitScreen(){
        const viewerDiv = document.getElementById("viewer")
        const mainBody = document.getElementsByClassName("main-body")[0];
        const homeScrollContainer = document.getElementById("home-scroll-container");
        var viewerPos = viewerDiv.getBoundingClientRect();
        var viewerLeft = viewerPos.left < 0 ? 0 : viewerPos.left; 
        var mainBodyWidth = mainBody.offsetWidth
        var homeScrollIsOverflowing = homeScrollContainer.clientHeight - homeScrollContainer.scrollHeight < 0;
        var x = viewerLeft + homeScrollContainer.scrollLeft
        if(!homeScrollIsOverflowing){
          x = x - 11;
        } else {
          x = x - 28;
        }
    
        this.setState({
          taamParserPosition: {
            x: x,
            y: viewerPos.top + homeScrollContainer.scrollTop,
            heightNum: window.innerHeight - viewerPos.top,
            widthNum: mainBodyWidth
          }
        })
      }

    handleTanachViewerSplitScreen(){
        const viewerDiv = document.getElementById("viewer")
        const mainBody = document.getElementsByClassName("main-body")[0];
        const homeScrollContainer = document.getElementById("home-scroll-container");
        var viewerPos = viewerDiv.getBoundingClientRect();
        var viewerLeft = viewerPos.left < 0 ? 0 : viewerPos.left; 
        var mainBodyWidth = mainBody.offsetWidth
        var homeScrollIsOverflowing = homeScrollContainer.clientHeight - homeScrollContainer.scrollHeight < 0;
        var x = viewerLeft + homeScrollContainer.scrollLeft
        if(!homeScrollIsOverflowing){
          x = x - 11;
        } else {
          x = x - 28;
        }
    
        this.setState({
          tanachViewerPosition: {
            x: x,
            y: viewerPos.top + homeScrollContainer.scrollTop,
            heightNum: window.innerHeight - viewerPos.top,
            widthNum: mainBodyWidth
          }
        })
      }
      handleLexiconSplitScreen(){
        const viewerDiv = document.getElementById("viewer")
        const mainBody = document.getElementsByClassName("main-body")[0];
        const homeScrollContainer = document.getElementById("home-scroll-container");
        var viewerPos = viewerDiv.getBoundingClientRect();
        var viewerLeft = viewerPos.left < 0 ? 0 : viewerPos.left; 
        var mainBodyWidth = mainBody.offsetWidth
        var homeScrollIsOverflowing = homeScrollContainer.clientHeight - homeScrollContainer.scrollHeight < 0;
        var x = viewerLeft + homeScrollContainer.scrollLeft
        if(!homeScrollIsOverflowing){
          x = x - 11;
        } else {
          x = x - 28;
        }
    
        this.setState({
          lexiconPosition: {
            x: x,
            y: viewerPos.top + homeScrollContainer.scrollTop,
            heightNum: window.innerHeight - viewerPos.top,
            widthNum: mainBodyWidth
          }
        })
      }

      handleObjectCloudViewerSplitScreen(){
        const viewerDiv = document.getElementById("viewer")
        const mainBody = document.getElementsByClassName("main-body")[0];
        const homeScrollContainer = document.getElementById("home-scroll-container");
        var viewerPos = viewerDiv.getBoundingClientRect();
        var viewerLeft = viewerPos.left < 0 ? 0 : viewerPos.left; 
        var mainBodyWidth = mainBody.offsetWidth
        var homeScrollIsOverflowing = homeScrollContainer.clientHeight - homeScrollContainer.scrollHeight < 0;
        var x = viewerLeft + homeScrollContainer.scrollLeft
        if(!homeScrollIsOverflowing){
          x = x - 11;
        } else {
          x = x - 28;
        }
    
        this.setState({
          objectCloudViewerPosition: {
            x: x,
            y: viewerPos.top + homeScrollContainer.scrollTop,
            heightNum: window.innerHeight - viewerPos.top,
            widthNum: mainBodyWidth
          }
        })
      }

      handleYuchsinTreeViewerSplitScreen(){
        const viewerDiv = document.getElementById("viewer")
        const mainBody = document.getElementsByClassName("main-body")[0];
        const homeScrollContainer = document.getElementById("home-scroll-container");
        var viewerPos = viewerDiv.getBoundingClientRect();
        var viewerLeft = viewerPos.left < 0 ? 0 : viewerPos.left;
        var mainBodyWidth = mainBody.offsetWidth
        var homeScrollIsOverflowing = homeScrollContainer.clientHeight - homeScrollContainer.scrollHeight < 0;
        var x = viewerLeft + homeScrollContainer.scrollLeft
        if(!homeScrollIsOverflowing){
          x = x - 11;
        } else {
          x = x - 28;
        }

        //alert(viewerPos.top + ":" + homeScrollContainer.scrollTop);
        //alert(this.state.searchResultsHeight);
        var yShift = (this.state.searchResultsHeight == "0px" && this.state.openResults.length == 0) ? 231 : 0; //if no results open yet then viewerPos.top will he too high

        this.setState({
          yuchsinTreeViewerPosition: {
            x: x,
            y: viewerPos.top + homeScrollContainer.scrollTop + yShift,
            heightNum: window.innerHeight - viewerPos.top - yShift,
            widthNum: mainBodyWidth
          }
        })
      }

      isTanachViewerFullyVisible () {
        const el = document.getElementsByClassName("tanach-viewer-rnd")[0]
        const percentVisible = 100;
        //check vertical
        var isVertVisible = false;
        let
          rect = el.getBoundingClientRect(),
          windowHeight = (window.innerHeight || document.documentElement.clientHeight);
        if(!(
          Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-(rect.height / 1)) * 100)) < percentVisible ||
          Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
        )){
          isVertVisible = true;
        }
        //check horizontal
        var isHorizVisible = true;

        if(rect.x + this.state.tanachViewerPosition.widthNum > window.innerWidth){
          isHorizVisible = false;
        }
        
        return isVertVisible && isHorizVisible;
      };
  
      resetTanachViewerOnWindowResize(){
        const isFullyVisible = this.isTanachViewerFullyVisible();
        if(!isFullyVisible){
          this.resetTanachViewerPosition()
        }
      }

    openDockedViewer(viewerName) {
        this.setState({viewer: viewerName});
    }

    closeDockedViewer() {
        this.setState({viewer: ""});
    }

    toggleTanachViewer (boolean, pasukId, resultsApi, columnClicked) {
        if(!resultsApi) { resultsApi = null }
        if(boolean === true){
            this.resetTanachViewerPosition();
        }
        var helpStartupTopic = boolean ? "tanachViewer" : this.state.helpStartupTopic;
        this.setState({
            tanachViewerOpen: boolean,
            helpStartupTopic: helpStartupTopic,
            contextualizedPasukId: pasukId,
            parshaViewerResultsApi: resultsApi,
            columnClicked,
            isJustOpened:true
        });
    }
    toggleTaamParser (boolean, pasukId, resultsApi, columnClicked) {
        if(!resultsApi) { resultsApi = null }
        if(boolean === true && (!this.props.isFirstTimeOpened || !this.props.initialFullScreen || "TY".indexOf(this.props.initialFullScreen.toUpperCase()) < 0)){
            this.resetTaamParserPosition();
        }
        var helpStartupTopic = boolean ? "taamParser" : this.state.helpStartupTopic;
        this.setState({
            taamParserOpen: boolean,
            helpStartupTopic: helpStartupTopic,
            contextualizedPasukId: pasukId,
            parshaViewerResultsApi: resultsApi,
            columnClicked,
            isJustOpened:true
        });
    }

    setBeta(){
        this.setState({beta:true},()=>{this.setState({beta:false})})
    }

    toggleObjectCloudViewer(boolean,parshaId){
        if(boolean === true){
            if(this.state.extSearchDefData==="S") this.setBeta()
            if(!this.state.objectCloudViewerOpen)this.resetObjectCloudViewerPosition();//only reset the position if it wasn't open before
            this.setState({helpStartupTopic: "objectCloudViewer"});
        }
        this.setState({
            objectCloudViewerOpen: boolean,
            objectCloudParshaId:parshaId
        });
    }
    toggleLexicon(boolean){
        var isOpenFromPageLoad = this.state.isLexiconOpenedFromURL;
        var firstLexiconOpenToggle = this.state.isFirstLexiconOpenToggle;
        var firstLexiconCloseToggle = this.state.isFirstLexiconCloseToggle;

        if(boolean === true){
            this.resetLexiconPosition();
        }else{
            this.setState({lexiconSearchText:"",lexiconSrcType:""})
        }
        if(boolean && !firstLexiconOpenToggle){
            this.setState({seforimBeforeLexicon:this.state.filterData,textSourceBeforeLexicon:this.state.textSource})
        }
        else if (!boolean) {
            if (!firstLexiconCloseToggle) {
//                this.toggleIsLexiconOpenedFromURL(false);
//            }
//            else {
                if(this.state.seforimBeforeLexicon && this.state.seforimBeforeLexicon!==this.state.filterData ){
                    this.setState({clearRBChangesFromLexicon:true})
                }
                if(this.state.textSourceBeforeLexicon && this.state.textSourceBeforeLexicon!==this.state.textSource){
                    this.setState({clearSourceChangesFromLexicon:true})
                }
            }
        }
        this.setState({lexiconOpen: boolean, isFirstLexiconOpenToggle: false, isFirstLexiconCloseToggle: firstLexiconCloseToggle && boolean});
    }

    togglePremiumServices(boolean) {
       this.setState({ premiumServicesDialogOpen: boolean });
    }

    toggleAboutDialog(boolean, tab) {
       var tab = parseInt(tab);
       this.setState({ aboutDialogOpen: boolean, aboutTabIndex: tab && tab >=0 && tab < util.totalAboutTabs ? tab : this.state.aboutTabIndex });
    }

    toggleHelpDialog(boolean, topic) {
       this.setState({ helpDialogOpen: boolean, helpStartupTopic: topic ? topic : this.state.helpStartupTopic });
    }

    handleClearRBChangesFromLexicon(){
        this.setState({ filterData:this.state.seforimBeforeLexicon,extFilterData:this.state.seforimBeforeLexicon},()=>{
            this.setState({clearRBChangesFromLexicon:false,})
        })
    }
    handleClearSourceChangesFromLexicon(){
        this.setState({  textSource:this.state.textSourceBeforeLexicon},()=>{
            this.setState({clearSourceChangesFromLexicon:false})
        })
    }

    toggleYuchsinTreeViewer(boolean){
        if(boolean === true){
            this.setBeta()
            this.resetYuchsinTreeViewerPosition();
        }
        this.setState({
            yuchsinTreeViewerOpen: boolean,
        });
    }

    setStateSynchronous(stateUpdate) {
        return new Promise(resolve => {
            this.setState(stateUpdate, () => resolve());
        });
    }

//  async checkAuthentication() {
//    const authenticated = await this.props.auth.isAuthenticated();
//    const userinfo = await this.props.auth.getUser();
//    if (userinfo != null)
//        alert("yoozerInfoe:"+userinfo);
//    if (authenticated !== this.state.authenticated) {
//      this.setStateSynchronous({ authenticated, userinfo });
//    }
//    return userinfo;
//  }
//
////  async componentDidMount() {
////    this.checkAuthentication();
////  }
//
////  async componentDidUpdate() {
////    this.checkAuthentication();
////  }
//
//  async login() {
//    // Redirect to '/' after login
//    await this.props.auth.login('/');
//    this.checkAuthentication();
//  }
//
//  async logout() {
//    // Redirect to '/' after logout
//    await this.props.auth.logout('/');
//    this.checkAuthentication();
//  }
    componentDidUpdate(prevProps,prevState){
        // if((prevState.textSource==="tanach" && this.state.textSource==="targum")){
        //     this.setState({beta:true}, ()=>{this.setState({beta:false})})
        // }
        if(this.state.rowData1Length !== prevState.rowData1Length){
            this.state.textComparatorText1Api.gridOptionsWrapper.gridOptions.api.ensureIndexVisible(this.state.rowData1Length,'bottom')
        }
        if(this.state.rowData2Length !== prevState.rowData2Length){
            this.state.textComparatorText2Api.gridOptionsWrapper.gridOptions.api.ensureIndexVisible(this.state.rowData2Length,'bottom')
        }
    
    }

    setSearchIsLoading = (booleanValue) => {
        this.setState({ searchIsLoading: booleanValue });
    }

    extSearchDefCallback = (data) => {
        this.setState({ extSearchDefData: data });
    }

    extFilterCallback = (data) => {
        this.setState({ extFilterData: data });
    }

    passGridApiToHome(params){
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    extDelayUpdateBituim = (data) => { //prevent lag when entering search terms
        let currentLastInputTime = this.state.lastSearchInputTime;
        if(Date.now() - this.state.lastSearchInputTime >= 500){
            this.setState({ lastSearchInputTime: Date.now() })
            this.updateBituim(data)
        } else {
            this.setState({ lastSearchInputTime: Date.now() })
            setTimeout(function(){
                this.updateBituim(data)
            }.bind(this), (500 - (Date.now() - this.state.lastSearchInputTime)));
        }
    }

    deleteSelectedResults(selectedResults){//, callback){
        var openResults = this.state.openResults
        var focusedResults = openResults[this.state.searchResultsTabValue]
        var focusedRowData = focusedResults.rowData

        //for each selected row, find match in focusedRowData, then remove
        selectedResults.forEach(selectedResult => {
            var selectedResultIndex = null;
            for(let i = 0; i < focusedRowData.length; i += 1) {
                if(focusedRowData[i].rowNum === selectedResult.rowNum) {
                    selectedResultIndex = i;
                }
            }
            focusedRowData = focusedRowData.slice(0,selectedResultIndex).concat(focusedRowData.slice(selectedResultIndex+1,focusedRowData.length))
        })

        focusedResults.rowData = focusedRowData;
        openResults[this.state.searchResultsTabValue] = focusedResults;

        this.setState({openResults: openResults, deletedResults:!this.state.deletedResults})//, () => {
        //     callback(this.state.openResults);
        // })
    }

    handleCloseSearchResultsDialogOpen(){
        //skip dialogue if only one search result open
        if(this.state.openResults.length === 1){
            this.closeSearchResults()
        } else {
            this.setState({ closeSearchResultsDialogOpen: true })
        }
    }

    handleCloseSearchResultsDialogClose(){
        this.setState({ closeSearchResultsDialogOpen: false })
    }

    handleSearchResultsTabChange(newValue){
        this.setState({searchResultsTabValue: newValue}, () => {
            if(this.state.openResults[this.state.searchResultsTabValue]){
                //if set to show all results and client only has 200 result batch in this tab, get full set of results
                var openResultsTabData = this.state.openResults[this.state.searchResultsTabValue];
                var totalResultsCount = openResultsTabData?openResultsTabData.rowData?openResultsTabData.rowData[0]?openResultsTabData.rowData[0].totalResults:0:0:0;
                var resultsReceivedCount = openResultsTabData.rowData?openResultsTabData.rowData.length:0;
                var allResultsWereRecieved = totalResultsCount === resultsReceivedCount;
                var willFetchFullResultSet = !allResultsWereRecieved && this.state.searchResultsPerPage === "showAll";
                if(willFetchFullResultSet){
                    this.fetchFullResultsSet(this.state.searchResultsTabValue);
                }
            }
        });
    }

    fetchFullResultsSet(tabValue){
        this.setState({
            searchIsLoading: true
        }, async () => {
            var searchParams = this.state.openResults[tabValue].searchParams;
            var url = properties.env.webServiceURL + '/BaseHaSefer/getPesukimWithTerms';
            var willReturnAllResults = this.state.searchResultsPerPage === "showAll";
            url += "?willReturnAllResults=" + willReturnAllResults;
            const response = await fetch(url, {method: 'post', body: JSON.stringify(searchParams)});
            const body = await response.json();

            var openResults = this.state.openResults;
            openResults[tabValue].rowData = body;
            this.setState({
                searchIsLoading: false, openResults
            })
        })
    }

    toggleKeepResultsOpen(){
        this.setState({keepResultsOpen: !this.state.keepResultsOpen});
    }

    toggleSearchWithinResults(){
        this.setState({searchWithinResults: !this.state.searchWithinResults});
    }

    closeResultsTab(index){
        // closing last tab of search results

        //from earlier version where latest search was saved in state separately from previous searches:
//        if((!this.state.showLastSearchResults && this.state.openResults.length === 1) || (!!this.state.showLastSearchResults && this.state.openResults.length === 0)){
        if(this.state.openResults.length === 1){
            this.setState({openResults: []}, () => {
                this.closeSearchResults()
            })
//            return
        } else {
            // closing latest search results
            if(index === this.state.openResults.length) {
                this.setState({showLastSearchResults: false})
            }

            var oldTabValue = this.state.searchResultsTabValue;
            var openResults = this.state.openResults
            var newOpenResults = openResults.slice(0,index).concat(openResults.slice(index+1,openResults.length))
            var rowDataForTotalTooltip = this.state.rowDataForTotalTooltip.slice(0,index).concat(this.state.rowDataForTotalTooltip.slice(index+1,this.state.rowDataForTotalTooltip.length))
            var newTabValue = (oldTabValue-1 >= 0 && oldTabValue >= index) ? oldTabValue-1 : oldTabValue

            this.setState({openResults: newOpenResults, rowDataForTotalTooltip}, () => {
                this.handleSearchResultsTabChange(newTabValue)
            })
        }
    }

    sliderValueSet(num){
        this.setState({ssSliderValue: num});
    }


    handleOpenInvalidFileDialog(){
        this.setState({invalidFileDialogOpen: true})
    }

    handleCloseInvalidFileDialog(){
        this.setState({invalidFileDialogOpen: false})
    }

    //moving simple search settings into home so settings download/upload can work properly
    setTerms(terms){
        this.setState({terms: terms});
    }

    setScope(scope){
        this.setState({scope: scope});
    }

    setSeq(seq){
        this.setState({seq: seq});
    }

    //moving rightBar settings into home from rightBar so settings download/upload can work properly
    setFilterData(filterData){
        this.setState({filterData: filterData});
    }
    setLetters(letters, callback){
        if(letters=="S"){this.setBeta()}
        this.setState({letters: letters}, () => {
            if(callback){
                callback();
            }
        });
    }
    setTaamim(taamim, callback){
        this.setState({taamim: taamim}, () => {
            if(callback){
                callback();
            }
        });
    }
    setNekudos(nekudos, callback){
        this.setState({nekudos: nekudos}, () => {
            if(callback){
                callback();
            }
        });
    }
    setGematria(gematria, callback){
        this.setState({gematria: gematria}, () => {
            if(callback){
                callback();
            }
        });
    }
    setSortDirection(sortDirection){
        this.setState({sortDirection: sortDirection});
    }
    setSortOrder(sortOrder, sortedColumn){
        this.setState({sortOrder: sortOrder, sortedColumn: parseInt(sortedColumn)});
    }

    //moving distance settings into home from advance search so settings download/upload can work properly
    setContainerDistances(bituiIndex, containerDistances, containerDistDisplays){
        this.setState(Object.assign(this.state.bituiSettingsList[bituiIndex].containerDistances, containerDistances));
        this.setState(Object.assign(this.state.bituiSettingsList[bituiIndex].containerDistDisplays, containerDistDisplays));
    }
    setContainerMadreigos(bituiIndex, madreigos){
        this.setState(Object.assign(this.state.bituiSettingsList[bituiIndex].madreigos, madreigos));
    }
    setTermDistances(termDistances){
        this.setState({termDistances})
    }

//    setPartOfSpeechSliders(frequency, gematria, atbash, lettersQty){
//        this.setFrequency(frequency);
//        this.setGematria(gematria);
//        this.setAtbash(atbash);
//        this.setLettersQty(lettersQty);
//    }
//
//    setFrequency(frequency){
//        this.setState(Object.assign(this.state.bituiSettingsList[bituiIndex].frequency, frequency));
//    }
//    setGematria(gematria){
//        this.setState(Object.assign(this.state.bituiSettingsList[bituiIndex].gematria, gematria));
//    }
//    setAtbash(atbash){
//        this.setState(Object.assign(this.state.bituiSettingsList[bituiIndex].atbash, atbash));
//    }
//    setLettersQty(lettersQty){
//        this.setState(Object.assign(this.state.bituiSettingsList[bituiIndex].lettersQty, lettersQty));
//    }

    onPaneChange(e){
        //setting min height for viewer here to prevent bug when resizing search results panel
        var viewerHeightNum = parseInt(e[3].substring(0, e[3].length - 2));
        if(viewerHeightNum < 520){
            e[3] = '520px';
        }

        //prevent search results panel from opening when there are no results open
        var resultsHeightNum = parseInt(e[2].substring(0, e[2].length - 2));
        if(resultsHeightNum > 0 && this.state.openResults.length === 0){
            e[2] = '0px';
        }

        this.setState({ searchResultsHeight: e[2], viewerHeight: e[3] });
    }

    validateQuotes(data){
        var hasInvalidQuote = false;
        var isInQuote = false;

        for(let i = 0; i < data.length; i++){
            if(data[i] === "\""){
                if(i === 0 || data[i-1] === " "){ // is open quote
                    if(isInQuote || [" ", "\""].includes(data[i+1])){
                        hasInvalidQuote = true;
                    }
                    isInQuote = true;
                } else if (i === data.length-1 || data[i+1] === " ") {// is close quote
                    if([" ","\""].includes(data[i-1]) || !isInQuote){
                        hasInvalidQuote = true;
                    }
                    isInQuote = false;
                }
            }
        }

        // originally wrote check this way assuming that single quotes would be used:
        // for(let i = 0; i < data.length; i++){
        //     if(["\'","\""].includes(data[i])){
        //         if(i === 0 || data[i-1] === " "){ // is open quote
        //             if(isInQuote || [" ","\'","\""].includes(data[i+1])){
        //                 hasInvalidQuote = true;
        //             }
        //             isInQuote = true;
        //             currQuoteMark = data[i]
        //         } if (i === data.length-1 || data[i+1] === " ") {// is close quote
        //             if(data[i] !== currQuoteMark || [" ","\'","\""].includes(data[i-1])){
        //                 hasInvalidQuote = true;
        //             }
        //             isInQuote = false;
        //         }
        //     }
        // }
        if(isInQuote === true){
            hasInvalidQuote = true;
        }

        //check for invalid single quotes
        var singleQuoteCheckerStr = this.removeNikudAndTaamim(data);
        while(-1 !== singleQuoteCheckerStr.indexOf("ה'")){
            singleQuoteCheckerStr = singleQuoteCheckerStr.replace("ה'", "");
        }
        if(singleQuoteCheckerStr.indexOf("\'") !== -1){
            hasInvalidQuote = true;
        }

        this.setState({hasInvalidQuote})
    }

    updateBituim = (data, callback) => {
        if (data == null)
            return;
        this.validateQuotes(data);
        var terms = data.trim(); //get rid of whitespace on both sides
        var termList = terms.split(/\s+/); //regex to split by any length of whitespace in between
        var bituiList = termList.map(function(e) {
            //remove quotes from terms
            for(let i = e.length-1; i >=0; i--){
                if(["\""].includes(e[i])){
                    e = e.slice(0, i) + e.slice(i+1);
                }
            }
            return {"term" : e} //for each element of termlist replace it with a JSON object with the term prop = the term string
        });
        var { nekudaList, taamList } = this.state;
        var isInQuote = false;
        //using this to clear distance pickers before resetting them
        var blankSettingsList = termList.map(function(term){
            return new BituiSettings();
        })
        var newSettingsList = termList.map(function(term) { //populate settings for each term
            var b = new BituiSettings();
//            var i;
//            var newNekudaPicker;
//            var pickers = [];
//            for (i=0; i < e.length; i++) { //create nekuda picker for each letter of the bitui
//                newNekudaPicker = new PickerModel();
//                newNekudaPicker.list = nekudaList;
//                pickers[i] = newNekudaPicker;
//            }
            b.letterNekudaPickers = term.split("").map(function(e) {
                var newNekudaPicker = new PickerModel();
                newNekudaPicker.list = nekudaList;
                return newNekudaPicker;
            });
            //b.nekudaPicker.list = nekudaList;
            b.generalNekudaPickers = defaultGeneralNekudaList.map(function(e) {
                var newNekudaPicker = new PickerModel();
                newNekudaPicker.list = nekudaList;
                return newNekudaPicker;
            });

            //b.taamPicker.list = taamList;
            b.generalTaamPickers = defaultGeneralTaamList.map(function(e) {
                var newTaamPicker = new PickerModel();
                newTaamPicker.list = taamList;
                return newTaamPicker;
            });

            // if(["\'"].includes(term[0])){ // no longer using single quotation marks to mark quotes
            //     isInQuote = true
            //     b.isSingleOpenQuote = true;
            // }
            if(["\""].includes(term[0])){
                isInQuote = true
                b.isDoubleOpenQuote = true;
            }

            b.isInQuote = isInQuote;

            // if(["\'"].includes(term[term.length-1])){ // no longer using single quotation marks to mark quotes
            //     isInQuote = false;
            //     b.isSingleCloseQuote = true;
            // }
            if(["\""].includes(term[term.length-1])){
                isInQuote = false;
                b.isDoubleCloseQuote = true;
            }
            return b;
        });
        var newTermDistances = new TermDistances(newSettingsList)
        var updatedSettingsList = this.state.bituiSettingsList;
        var updatedTermDistances = this.state.termDistances;

        //save bituiSettings, termDistances for words that have not changed

        //if number of word is the same --> update words, nekudot, NOT distances
        if(bituiList.length === this.state.bituim.length){
            for (let i = 0; i < bituiList.length; i++){
                if(this.state.bituim[i].term !== bituiList[i].term) {
                    updatedSettingsList[i].letterNekudaPickers = newSettingsList[i].letterNekudaPickers
                    updatedSettingsList[i].letterNikkud = newSettingsList[i].letterNikkud

                    updatedSettingsList[i].isInQuote = newSettingsList[i].isInQuote
                    updatedSettingsList[i].isSingleOpenQuote = newSettingsList[i].isSingleOpenQuote
                    updatedSettingsList[i].isDoubleOpenQuote = newSettingsList[i].isDoubleOpenQuote
                    updatedSettingsList[i].isSingleCloseQuote = newSettingsList[i].isSingleCloseQuote
                    updatedSettingsList[i].isDoubleCloseQuote = newSettingsList[i].isDoubleCloseQuote
                }
            }
            this.setState({ bituim: bituiList, extBituimText: data, bituiSettingsList: updatedSettingsList, termDistances: new TermDistances(blankSettingsList)}, () => {
                this.setState({termDistances: newTermDistances}, () => {
                    if(callback){
                        callback()
                    }
                });
            });


        //if single new word added at the end --> update words, add bituiSettings object, add new termDistances (but keep old ones)
        } else if (bituiList.length === this.state.bituim.length+1) {
            var isLastWordAddition = true;
            for(let i = 0; i < this.state.bituim.length; i++){
                if (bituiList[i].term !== this.state.bituim[i].term){
                    isLastWordAddition = false;
                }
            }
            if(isLastWordAddition){
                var b = new BituiSettings();
                b.letterNekudaPickers = bituiList[bituiList.length-1].term.split("").map(function(e) {
                    var newNekudaPicker = new PickerModel();
                    newNekudaPicker.list = nekudaList;
                    return newNekudaPicker;
                });
                //b.nekudaPicker.list = nekudaList;
                b.generalNekudaPickers = defaultGeneralNekudaList.map(function(e) {
                    var newNekudaPicker = new PickerModel();
                    newNekudaPicker.list = nekudaList;
                    return newNekudaPicker;
                });

                //b.taamPicker.list = taamList;
                b.generalTaamPickers = defaultGeneralTaamList.map(function(e) {
                    var newTaamPicker = new PickerModel();
                    newTaamPicker.list = taamList;
                    return newTaamPicker;
                });
                updatedSettingsList.push(b)
                for(let i = 0; i < Object.keys(newTermDistances).length; i ++){
                    var currBlankTermPairSettings = newTermDistances[Object.keys(newTermDistances)[i]]
                    if (currBlankTermPairSettings.termPosition1 === bituiList.length-1 || currBlankTermPairSettings.termPosition2 === bituiList.length-1){
                        updatedTermDistances[Object.keys(newTermDistances)[i]] = newTermDistances[Object.keys(newTermDistances)[i]]
                    }
                }
                this.setState({ bituim: bituiList, extBituimText: data, bituiSettingsList: updatedSettingsList, termDistances: new TermDistances(blankSettingsList)}, () => {
                    this.setState({termDistances: newTermDistances}, () => {
                        if(callback){
                            callback()
                        }
                    });
                })
            } else {

                //todo - deal with additions in other positions, multiple word additions (i.e. copy and paste)
                //new word vs split word: find first non-matching term --> does next one match?
//                var newOrSplit = 'new';
//                for (let i = 0; i < bituiList.length; i++){
//                    find first non-matching term --> does next one match?
//                    if (this.state.bituim[i] && this.state.bituim[i].term !== bituiList[i].term && this.state.bituim[i] && this.state.bituim[i].term !== bituiList[i+1].term){
//                        newOrSplit = 'split'
//                        break
//                    }
//                }
//                console.log(newOrSplit)
//                if(newOrSplit === 'new'){
                    //add new bitui settings
                    //deal with termDistances
//                } else if (newOrSplit === 'split'){
//
//                }

                this.setState({ bituim: bituiList, extBituimText: data, bituiSettingsList: newSettingsList, termDistances: new TermDistances(blankSettingsList) }, () => {
                    this.setState({termDistances: newTermDistances}, () => {
                        if(callback){
                            callback()
                        }
                    })
                });
            }
        } else if (bituiList.length === this.state.bituim.length-1) {
              var isLastWordDeletion = true;
              for(let i = 0; i < this.state.bituim.length-1; i++){
                  if (bituiList[i].term !== this.state.bituim[i].term){
                      isLastWordDeletion = false;
                  }
              }
              if(isLastWordDeletion){
                  updatedSettingsList.pop();

                  for(let i = 0; i < Object.keys(newTermDistances).length; i ++){
                      var currBlankTermPairSettings = newTermDistances[Object.keys(newTermDistances)[i]]
                      if (currBlankTermPairSettings.termPosition1 === bituiList.length || currBlankTermPairSettings.termPosition2 === bituiList.length){
                          delete updatedTermDistances[Object.keys(newTermDistances)[i]];
                      }
                  }
                  this.setState({ bituim: bituiList, extBituimText: data, bituiSettingsList: updatedSettingsList, termDistances: new TermDistances(blankSettingsList)}, () => {
                    this.setState({termDistances: newTermDistances}, () => {
                        if(callback){
                            callback()
                        }
                    })
                  })
              } else {
                  //todo - deal with deletions in other positions, multiple word deletions (i.e. highlight and delete)
                  this.setState({ bituim: bituiList, extBituimText: data, bituiSettingsList: newSettingsList, termDistances: new TermDistances(blankSettingsList) }, () => {
                    this.setState({termDistances: newTermDistances}, () => {
                        if(callback){
                            callback()
                        }
                    })
                  });
              }
          }
        else {
            this.setState({ bituim: bituiList, extBituimText: data, bituiSettingsList: newSettingsList, termDistances: new TermDistances(blankSettingsList) }, () => {
                this.setState({termDistances: newTermDistances}, () => {
                    if(callback){
                        callback()
                    }
                })
            });
        }

//        if (this.gridApi.getFocusedCell()){
//          this.gridApi.setFocusedCell(0, "term", null)
//          --> then place cursor back in search bar at right position
//        }

    }



//    extKeyboardCallback = (data) => {
//        this.setState({ extKeyboardData: data });
//    }

//the following two functions have klujy code that has to go deep into DOM to identify hidden DIV that gets added by react-split-pane (in the neck :-) )

    extOpenAdvancedSearch = () => {
        var advancedSearch = document.getElementById("advancedSearch");
        var searchResults = document.getElementById("searchResults");
        var viewer = document.getElementById("viewer");
        var paneContainerNodes = document.getElementById("paneContainer").childNodes[0];
        var advancedSearchDivider = paneContainerNodes.childNodes[1];
        var advancedSearchPane = paneContainerNodes.childNodes[2];

        advancedSearch.className = "visible";
        advancedSearch.style.height = "289px";

        //advancedSearchDivider.style.position = "relative";
        //advancedSearchDivider.style.top = "0px";
        //advancedSearchPane.style.position = "relative";
        //advancedSearchPane.style.top = "0px";
        advancedSearchPane.style.height = "289px";
        this.setState({ advancedSearchHeight: 289 });
    }

    extCloseAdvancedSearch = () => {
        this.closeAdvancedSearch();
    }

    extOpenSearchResults = (data, nonSearchBarTerms) => {

        var terms = this.state.lastSearchTerms;
        if(nonSearchBarTerms){
            terms = nonSearchBarTerms
        }

        var openResults = this.state.openResults;
        var rowDataForTotalTooltip=this.state.rowDataForTotalTooltip;
        if(this.state.keepResultsOpen){
            if(!data){
                openResults.push({});
                rowDataForTotalTooltip[openResults.length-1]={}
            } else if(data){
                openResults = this.state.openResults;
                var newResults = {
                    rowData: data.results,
                    searchParams: data.searchParams,
                    terms: terms,
                    currentPage: 1
                }
                openResults[openResults.length-1] = newResults
                rowDataForTotalTooltip[openResults.length-1]=newResults.rowData[0]
            }
            var textSourceSearched=this.state.textSourceSearched
            textSourceSearched[this.state.searchResultsTabValue]=this.state.textSource        

            this.setState({ openResults: [], resultsReceived: true ,searchResultsOpen: true,textSourceSearched},() => {
                this.setState({openResults,searchResultsTabValue: openResults.length-1,rowDataForTotalTooltip})
            });
        } else {
            if(!data){
                let lastResults = {};
                if(this.state.openResults[this.state.searchResultsTabValue]){
                    lastResults = {
                        rowData: this.state.openResults[this.state.searchResultsTabValue].rowData,
                        searchParams: this.state.openResults[this.state.searchResultsTabValue].searchParams,
                        terms: this.state.openResults[this.state.searchResultsTabValue].terms,
                        currentPage: this.state.openResults[this.state.searchResultsTabValue].currentPage,
                    }
                }
                openResults[this.state.searchResultsTabValue] = lastResults;
                rowDataForTotalTooltip[this.state.searchResultsTabValue]=lastResults.rowData?lastResults.rowData[0]:{}
            } else if(data){
                openResults = this.state.openResults;
                var newResults = {
                    rowData: data.results,
                    searchParams: data.searchParams,
                    terms: terms,
                    currentPage: 1
                }
                openResults[this.state.searchResultsTabValue] = newResults
                rowDataForTotalTooltip[this.state.searchResultsTabValue] = newResults.rowData[0]
            }
            var textSourceSearched=this.state.textSourceSearched
            textSourceSearched[this.state.searchResultsTabValue]=this.state.textSource        
            this.setState({ openResults:[],textSourceSearched},()=>{this.setState({openResults,changedStatsArray:[]},()=>{
                this.setState({ resultsReceived: true, searchResultsOpen: true,changedStatsArrayBool:!this.state.changedStatsArrayBool, updateLeftBar:!this.state.updateLeftBar,rowDataForTotalTooltip})
            })});
        }

        var advancedSearch = document.getElementById("advancedSearch");
        var searchResults = document.getElementById("searchResults");
        var viewer = document.getElementById("viewer");
        var paneContainerNodes = document.getElementById("paneContainer").childNodes[0];
        var searchResultsDivider = paneContainerNodes.childNodes[3];
        var searchResultsPane = paneContainerNodes.childNodes[4];

        searchResults.className = "visible";
        searchResults.style.height = "243px";
        searchResults.style.display = "block";
//        var viewerDivider = document.getElementsByClassName("css-glamorous-div--hvdu2p main-body")[0].childNodes[3];
//        var viewerPane = document.getElementsByClassName("css-glamorous-div--hvdu2p main-body")[0].childNodes[4];
/*
        //the following is now done since looks like autoprefixer/postcss (https://github.com/postcss/autoprefixer) is changing the class names for prod on flexboxes - while there
        //is a way to turn this off by adding a postcss.config.js to the root dir and putting

const autoprefixer = require('autoprefixer');

module.exports = {
  plugins: {
    autoprefixer: ({ flexbox: false })
  },
};

it doesn't seem to work and I don't know how to configure that stuff
therefore going one level out in the DOM to get an identifiable/unchanged class name of
*/
//        var paneContainerNodes = document.getElementById("paneContainer").childNodes[0];
//        var viewerDivider = paneContainerNodes.childNodes[5];
//        var viewerPane = paneContainerNodes.childNodes[6];
//        viewerDivider.style.position = "relative";
//        viewerDivider.style.top = "800px";
//        viewerPane.style.position = "relative";
//        viewerPane.style.top = "800px";
        searchResultsPane.style.height = "243px";
    }

    extUpdateOpenResults(openResults){
        this.setState({
            openResults
        })
    }

    extUpdateSearchResultsPerPage(num){
        var wasShowAll = this.state.searchResultsPerPage = "showAll"
        this.setState({
            searchResultsPerPage: num
        }, () => {
            //if multiple tabs are open and going from "showAll" to num, jump tabs then go back
            //without this ag grid doesn't paginate the results properly
            if(this.state.openResults.length > 1 && wasShowAll){
                var currentTab = this.state.searchResultsTabValue;
                var toggleTab = 0;
                if(currentTab === 0){
                    toggleTab = 1;
                }
                this.setState({
                    searchResultsTabValue: toggleTab
                }, () => {
                    this.setState({
                        searchResultsTabValue: currentTab
                    })
                })
            }
        })
    }

    async closeAdvancedSearch() {
//        var paneContainerNodes = document.getElementById("paneContainer").childNodes[0];
//        var viewerDivider = paneContainerNodes.childNodes[3];
//        var viewerPane = paneContainerNodes.childNodes[4];
//        viewerDivider.style.position = "relative";
//        viewerDivider.style.top = "-200px";
//        viewerPane.style.position = "relative";
//        viewerPane.style.top = "0px";
//        viewerPane.style.height = "0px";
//        var sr = document.getElementById("advancedSearch");
//        sr.className = "invisible";
//        sr.style.height = "0px";
//        window.confirm("Are you sure? This will reset all advanced search settings.")

        var advancedSearch = document.getElementById("advancedSearch");
        var searchResults = document.getElementById("searchResults");
        var viewer = document.getElementById("viewer");
        var paneContainerNodes = document.getElementById("paneContainer").childNodes[0];
        var advancedSearchDivider = paneContainerNodes.childNodes[1];
        var advancedSearchPane = paneContainerNodes.childNodes[2];

//        advancedSearchDivider.style.position = "relative";
//        advancedSearchDivider.style.top = "0px";
//        advancedSearchPane.style.position = "relative";
//        advancedSearchPane.style.top = "0px";
        advancedSearchPane.style.height = "0px";

        advancedSearch.className = "invisible";
        advancedSearch.style.height = "0px";

        this.resetAllAdvancedSearchSettings()

        this.setAdvancedSearchTabValue(4)

        this.gridApi.setFocusedCell(0, "term", null)

        this.setState({ advancedSearchHeight: 0 });
    }

    setAdvancedSearchTabValue(tabValue, callback){
        if(tabValue==2 && this.state.advancedSearchTabValue!==2) this.setBeta()
        this.setState({ advancedSearchTabValue: tabValue }, () => {
            if(callback){
                callback();
            }
        });
    }

    async closeSearchResults() {
//        var viewerDivider = document.getElementsByClassName("css-glamorous-div--hvdu2p main-body")[0].childNodes[3];
//        var viewerPane = document.getElementsByClassName("css-glamorous-div--hvdu2p main-body")[0].childNodes[4];
        //var viewerDivider = document.getElementsByClassName("container-fluid")[0].childNodes[0].childNodes[3];
        //var viewerPane = document.getElementsByClassName("container-fluid")[0].childNodes[0].childNodes[4];

//        var paneContainerNodes = document.getElementById("paneContainer").childNodes[0];
//        var viewerDivider = paneContainerNodes.childNodes[5]; //todo - MIGHT NEED TO BUMP THIS UP DUE TO ADV SEARCH PANE!!!!!
//        var viewerPane = paneContainerNodes.childNodes[6];
//        viewerDivider.style.position = "relative";
//        viewerDivider.style.top = "-800px";
//        viewerPane.style.position = "relative";
//        viewerPane.style.top = "0px";
//        viewerPane.style.height = "0px";
//        var sr = document.getElementById("searchResults");
//        sr.className = "invisible";
//        sr.style.height = "0px";
//
        this.handleCloseSearchResultsDialogClose()

        var advancedSearch = document.getElementById("advancedSearch");
        var searchResults = document.getElementById("searchResults");
        var viewer = document.getElementById("viewer");
        var paneContainerNodes = document.getElementById("paneContainer").childNodes[0];
        var searchResultsDivider = paneContainerNodes.childNodes[3];
        var searchResultsPane = paneContainerNodes.childNodes[4];
        searchResultsPane.style.height = "0px";
//        searchResults.className = "invisible";
        searchResults.style.height = "0px";
        searchResults.style.display = "none";

        this.setState({ searchResultsHeight: '0px', openResults: [], dataForTree:null, searchResultsOpen: false,rowDataForTotalTooltip:[[]]})
    }

    async fetchServerStatus(){
        const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getServerStatus');
        const body = await response.json();
        //const message = this.props.activeLanguage.code === 'he' ? body.he : body.en;
        //alert("msg="+message);
        this.setState({
            serverMessage: this.props.activeLanguage.code === 'he' ? body.he : body.en
        })
    }

  async componentDidMount() {
          //this done for when initialize site from search parameters
      var isForceUpdateBituim = true; //assume need to stuff an empty 1st entry into the bituim list for starters unless somehow determine below that starting with prefilled bituim

      if(this.props.searchOpen) {
          //localStorage.setItem("helpDialogStartupTopic", "searchResults");
          //todo - the search results grid currently opens to partial height and only after results return does the ag-grid go to full height - fix this at some point
          var terms = null;
          var termsParam = this.props.terms;
          var i;

          var inSequence = "seq";
          var isExactQuoteParam = this.props.isExactQuote;
          var isExactQuote = false;
          var ssExactnessLevel = 0;
          var precision = Number(this.props.precision);
          var format = this.props.format; //assume letter-only
          var extSearchDefData = "L"; //assume letter-only
          var letters = "L"; //assume letter-only - can be changed if format param exists in URL
          var nekudos = "";
          var taamim = "";
          var gematria = "";
          var numberOfTerms = 0;

          var body;
          if (termsParam) {
              isForceUpdateBituim =  termsParam.trim().length == 0;

              numberOfTerms = termsParam.trim().split(" ").length;
              numberOfTerms = isForceUpdateBituim ? 0 : numberOfTerms;

              var isSurroundedByQuotes = util.left(termsParam, 1) == '"' && util.right(termsParam, 1) == '"';

              isExactQuote = numberOfTerms > 1 && ((isExactQuoteParam && "YT".indexOf(isExactQuoteParam.toUpperCase())) >= 0 || isSurroundedByQuotes);

              if (isExactQuote && !isSurroundedByQuotes)
                  termsParam = '"' + termsParam + '"';

              this.updateBituim(termsParam);

              if (numberOfTerms > 1 && this.props.inOrder && "NF".indexOf(this.props.inOrder.toUpperCase()) >= 0)
                  inSequence = "noseq";

              if (Number.isInteger(precision) && precision >= 0 && precision <= 3)
                  ssExactnessLevel = precision;

              if (format) {
                  letters = ""; //if bad format param, this will be set to L later
                  if (format == "LNT" || format == "LN" || format == "LT")
                      letters = "L";

                  if (format == "S")
                      letters = "S";

                  if (format == "LNT" || format == "LN" || format == "NT" || format == "N")
                      nekudos = "N";

                  if (format == "LNT" || format == "NT" || format == "LT" || format == "T")
                      taamim = "T";

                  if (format == "G" || format == "A" )
                      gematria = format;

                  if (letters+nekudos+taamim+gematria != "")
                      extSearchDefData = format;
                  else
                      letters = "L"; //if bad format, default to L
              }

              this.setState({terms: termsParam, seq: inSequence, ssExactnessLevel: ssExactnessLevel, extSearchDefData: extSearchDefData,
                             letters: letters, nekudos: nekudos, taamim: taamim, gematria: gematria}, async () => {
                  this.extOpenSearchResults();
                  body = await this.submitSearch(terms, "all", inSequence, "FFFFFFFFFFF");
                  this.extOpenSearchResults(body); //do this to update search results grid governed by Home.js
                  this.setSearchIsLoading(false);
              });
          }

          var pasukIds = [];
          if (numberOfTerms == 0) {
              var pasukId = 1;
              if (this.props.pasukLocation) {
                  const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPasukIdFromLocation?location=' + this.props.pasukLocation);
                  pasukId = await response.json();
              }
              if (pasukId > 0)
                  pasukIds = [pasukId];

              this.extOpenSearchResults();
              body = await this.submitSearch(terms, "all", inSequence, "FFFFFFFFFFF", pasukIds);

              this.setState({isInitializedPasukFetched: true}, () => {
                  this.extOpenSearchResults(body); //do this to update search results grid governed by Home.js
                  this.setSearchIsLoading(false);
              });
          }

      }

    if(this.props.tanachViewerOpen) { //this will not work if placed in the constructor above like Taam Parser
        //localStorage.setItem("helpDialogStartupTopic", "tanachViewer"); //for some reason, clicking help button after this still does not load correct page - maybe need to force focus on this page as maybe clicking the help button sets focus to the nav bar?
        var pasukId = 0;
        if (this.props.pasukLocation) {
              const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPasukIdFromLocation?location=' + this.props.pasukLocation);
              const body = await response.json();
              pasukId = body;
        }
        this.toggleTanachViewer(true, pasukId > 0 ? pasukId : 1);
    }
    //this done for when initialize site in lexicon page
    if(this.props.lexiconOpen) {
        var precision = Number(this.props.precision);
        var ssExactnessLevel = 0;

        //localStorage.setItem("helpDialogStartupTopic", "lexicon"); //for some reason, clicking help button after this still does not load correct page - maybe need to force focus on this page as maybe clicking the help button sets focus to the nav bar?
        this.toggleIsLexiconOpenedFromURL(true);

        if (Number.isInteger(precision) && precision >= 0 && precision <= 3)
            ssExactnessLevel = precision;

        if (this.props.word)
            this.setState({ssExactnessLevel: ssExactnessLevel, lexiconSrcType: "Teiva", lexiconSearchText: this.props.word});
        else if (this.props.root)
            this.setState({ssExactnessLevel: ssExactnessLevel, lexiconSrcType: "Shoresh", lexiconSearchText: this.props.root});
        else if (this.props.gematria)
            this.setState({ssExactnessLevel: ssExactnessLevel, lexiconSrcType: "Gematria", lexiconSearchText: this.props.gematria});
        else if (this.props.atbash)
            this.setState({ssExactnessLevel: ssExactnessLevel, lexiconSrcType: "Atbash", lexiconSearchText: this.props.atbash});
        else if (this.props.targum)
            this.setState({ssExactnessLevel: ssExactnessLevel, lexiconSrcType: "Targum", lexiconSearchText: this.props.targum});
        this.toggleLexicon(true); //srcType,text params
    }
    //this done for when initialize site in objectCloudViewer page
    else if(this.props.objectCloudViewerOpen) {
         //localStorage.setItem("helpDialogStartupTopic", "objectCloudViewer"); //for some reason, clicking help button after this still does not load correct page - maybe need to force focus on this page as maybe clicking the help button sets focus to the nav bar?
         this.toggleObjectCloudViewer(true, 1);
    }
    //else
    //    localStorage.setItem("helpDialogStartupTopic", "layout");

    if (this.props.premiumServicesOpen)
        this.togglePremiumServices(true);

    if (this.props.aboutOpen)
        this.toggleAboutDialog(true, this.props.tab);

    if (this.props.helpOpen)
        this.toggleHelpDialog(true, this.props.topic);

    this.fetchServerStatus();

      //this.checkAuthentication();

    //console.log('about to fetch stuff upon screen load:');
      let language = localStorage.getItem("language"); //this is working more smoothly than sessionStorage

      if (language == null || language == '') {
          const response3 = this.state.defaultLanguage == '' ? await fetch(properties.env.webServiceURL + '/BaseHaSefer/getLocale') : null;
          const ipInfo = this.state.defaultLanguage == '' ? await response3.json() : null;
          language = (ipInfo.country == "Israel" || ["07012", "07055"].includes(ipInfo.zip) ? "he" : "en");
          localStorage.setItem("language", language);
          this.setLanguage(language); //need to do this here for the site - individual child pages will do this on their own as they load
      }

      //const response2 = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getParshiosTanach');
      //const body2 = await response2.json();
      var nekudaResponse = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getNekudos?source=bhsWebApp');
      var nekudaBody = await nekudaResponse.json();
      await this.setStateSynchronous( {nekudaList: nekudaBody} );


      var taamResponse = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getTaamim?source=bhsWebApp');
      var taamBody = await taamResponse.json();
      var emetIndex = taamBody.findIndex((e)=>{return e.seferEmetFlag;});
      taamBody.splice(emetIndex, 0, {seferEmetFlag: true, taamType: '', name: 'טעמי אמ"ת', icon: ''});
      await this.setStateSynchronous( {taamList: taamBody} );

      ////const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPesukimWithWords');
      ////const body = await response.json();

    //console.log('completed fetch.');

      //this.setState({ getPesukimWithWords: body, isLoading: false });
      //this.setState({ rowData: body, rowData2: body2, isLoading: false });
      ////this.setState({ rowData: body, isLoading: false });
      if (isForceUpdateBituim)
          this.updateBituim(''); //force something into bituiSelection object for now
      window.addEventListener("resize", () => {this.resetTanachViewerOnWindowResize()});
  }

  resetPlacementState(){
    this.setState(Object.assign(this.state.searchParams.placementSettings, new PlacementSettings()));

    this.setState({placementRowData: [{
        type: true,
        val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0,
        valList1:[], valList2:[], valList3:[], valList4:[], valList5:[], valList6:[]
    }]}, () => {
        //flipping tabs if palcement tab was open so view refreshes
        if(this.state.advancedSearchTabValue === 0){
            this.setAdvancedSearchTabValue(1, () => {
                this.setAdvancedSearchTabValue(0)
            })
        }
    })
  }

  resetIncludeExcludeState(){
      this.setState(Object.assign(this.state.dualLetterPicker.pickerModel1, {values: [], renderValue: "", list: this.state.dualLetterPicker.masterList }));
      this.setState(Object.assign(this.state.dualLetterPicker.pickerModel2, {values: [], renderValue: "", list: this.state.dualLetterPicker.masterList }));
      this.setState(Object.assign(this.state.dualNekudaPicker.pickerModel1, {values: [], renderValue: "", list: this.state.dualNekudaPicker.masterList }));
      this.setState(Object.assign(this.state.dualNekudaPicker.pickerModel2, {values: [], renderValue: "", list: this.state.dualNekudaPicker.masterList }));
      this.setState(Object.assign(this.state.dualTaamPicker.pickerModel1, {values: [], renderValue: "", list: this.state.dualTaamPicker.masterList }));
      this.setState(Object.assign(this.state.dualTaamPicker.pickerModel2, {values: [], renderValue: "", list: this.state.dualTaamPicker.masterList }));

      this.setState(Object.assign(this.state.searchParams.pasukSettings, new PasukSettings()));
  }

  resetBituiNikudTaamimState(index){
        var bituiSettings = this.state.bituiSettingsList[index]
        for (let j = 0; j < bituiSettings.letterNekudaPickers.length; j++){
            var lnPicker = bituiSettings.letterNekudaPickers[j];
            this.setState(Object.assign(lnPicker, {values: [], renderValue: ""}));
        }
        //reset General nikud
        for (let j = 0; j < bituiSettings.generalNekudaPickers.length; j++){
            var gnPicker = bituiSettings.generalNekudaPickers[j]
            this.setState(Object.assign(gnPicker, {values: [], renderValue: ""}));
        }
        //reset Taamim
        for (let j = 0; j < bituiSettings.generalTaamPickers.length; j++){
            var gntPicker = bituiSettings.generalTaamPickers[j]
            this.setState(Object.assign(gntPicker, {values: [], renderValue: ""}));
        }

        this.renderLetterNikkud(index)
        this.renderGeneralNikkud(index)
        this.renderGeneralHataama(index)
  }

  renderLetterNikkud(index) {
      if(this.state.bituiSettingsList[index]){ // --> prevents error when entering 2+ words in search bar, selecting a word other than the first in agGrid, then deleting from search bar
          if (this.state.bituiSettingsList[index].letterNekudaPickers.length == 0)
              return;

          var result = "";
          var bituiSettings = this.state.bituiSettingsList[index]
          var len = this.state.bituim[index].term.length;
          var i;
          for (i=len-1; i >=0; i--)
              result += bituiSettings.letterNekudaPickers[i].renderValue;

          this.setState(Object.assign(this.state.bituiSettingsList[index].letterNikkud, {value: result}));
      }

  }

  renderGeneralNikkud(index) {
        if(this.state.bituiSettingsList[index]){ // --> prevents error when entering 2+ words in search bar, selecting a word other than the first in agGrid, then deleting from search bar

            if (this.state.bituiSettingsList[index].generalNekudaPickers.length == 0)
                  return;

            var result = "";
            var bituiSettings = this.state.bituiSettingsList[index]
            var len = 13; //max length of 13 for now corresponding to longest stream of nikkud per word in Tanach
            var i;
            for (i=len-1; i >=0; i--)
                result += bituiSettings.generalNekudaPickers[i].renderValue;

            this.setState(Object.assign(this.state.bituiSettingsList[index].generalNikkud, {value: result}));
        }


  }

  renderGeneralHataama(index) {
      if(this.state.bituiSettingsList[index]){ // --> prevents error when entering 2+ words in search bar, selecting a word other than the first in agGrid, then deleting from search bar

            if (this.state.bituiSettingsList[index].generalTaamPickers.length == 0)
                return;

            var result = "";
            var bituiSettings = this.state.bituiSettingsList[index]
            var len = 8; //max length of 8 for now
            var i;
            for (i=len-1; i >=0; i--)
                result += bituiSettings.generalTaamPickers[i].renderValue;

            this.setState(Object.assign(this.state.bituiSettingsList[index].generalHataama, {value: result}));
      }


  }

  resetAllNikudTaamimState(){
      for(let i = 0; i < this.state.bituiSettingsList.length; i++){
            this.resetBituiNikudTaamimState(i)
      }
  }

  resetTermContainerDistances(index){
        var currBituiSettings = this.state.bituiSettingsList[index]
        for(let j = 0; j < 6; j++){
            this.setState(Object.assign(currBituiSettings, {
                containerDistDirections: ["ltr", "rtl", "ltr", "rtl", "ltr", "rtl"],
                containerDistDisplays:["0 עד ∞", "0 עד ∞", "0 עד ∞", "0 עד ∞", "0 עד ∞", "0 עד ∞"],
                containerDistances:[[0, 100], [0, 100], [0, 100], [0, 100], [0, 100], [0, 100]],
                madreigos: [2, 2, 2, 2, 2, 2]
            }));
        }
  }

  resetTermPairDistances(termPairKey){
          var currTermPairSettings = this.state.termDistances[termPairKey]
          this.setState(Object.assign(currTermPairSettings, {
              direction: 0,
              distDisplay: "0 עד ∞",
              distance: [0, 100],
              madreiga: 2
          }));
    }

  resetDistancesState(){
        //reset container distances
        for(let i = 0; i < this.state.bituiSettingsList.length; i++){
            this.resetTermContainerDistances(i)
        }

        this.setState({termDistances: new TermDistances(this.state.bituiSettingsList)})
  }

  resetPartOfSpeechState() {
      for(let i = 0; i < this.state.bituiSettingsList.length; i++){
            this.resetPartOfSpeech(i)
      }
  }

  resetPartOfSpeech(index){
      var currBituiSettings = this.state.bituiSettingsList[index]
      this.setState(Object.assign(currBituiSettings, {
          binyanim: [],
          zmanim: [],
          misparim: [],
          gufim: [],
          genders: [],
          misparimSof: [],
          gufimSof: [],
          gendersSof: [],
          prefixes: [],
          languages: [],
          wordTypes: [],
          objectCategories: [],
          numberCategories: [],
          hasInfix: null,
          hasSuffix: null,
          frequency: [1,1000],
          gematria: [3,1640],
          atbash: [2,1390],
          lettersQty: [1,11],
          vowelsQty: [0,9],
          tenuosQty: [0,6],
          frequencyDisplay: "1" + " עד " + "∞",
          gematriaDisplay: "3" + " עד " + "∞",
          atbashDisplay: "2" + " עד " + "∞",
          lettersQtyDisplay: "1" + " עד " + "∞",
          vowelsQtyDisplay: "0" + " עד " + "∞",
          tenuosQtyDisplay: "0" + " עד " + "∞",
          exactnessLevel: 0,
          exactnessLevelChecked: false
      }));
  }

  resetIsCaseSensitive(){
          for(let i = 0; i < this.state.bituiSettingsList.length; i++){
                var currBituiSettings = this.state.bituiSettingsList[i]
                this.setState(Object.assign(currBituiSettings, {
                    isCaseSensitive: null,
                }));
          }
    }

    resetIsNegative(){
          for(let i = 0; i < this.state.bituiSettingsList.length; i++){
                var currBituiSettings = this.state.bituiSettingsList[i]
                this.setState(Object.assign(currBituiSettings, {
                    isNegative: null,
                }));
          }
    }

    resetRepetitions(){
          for(let i = 0; i < this.state.bituiSettingsList.length; i++){
                var currBituiSettings = this.state.bituiSettingsList[i]
                this.setState(Object.assign(currBituiSettings, {
                    repetitions: 1,
                }));
          }
    }

    resetTransformations(){
          for(let i = 0; i < this.state.bituiSettingsList.length; i++){
                var currBituiSettings = this.state.bituiSettingsList[i]
                this.setState(Object.assign(currBituiSettings, {
                    transformation: 0,
                }));
          }
    }

      resetExactnessLevel(){
            for(let i = 0; i < this.state.bituiSettingsList.length; i++){
                  var currBituiSettings = this.state.bituiSettingsList[i];
                  this.setState(Object.assign(currBituiSettings, {exactnessLevel: 0}));
                  this.setState({});
            }
      }

  resetAllAdvancedSearchSettings(){
        this.resetPlacementState()
        this.resetIncludeExcludeState()
        this.resetAllNikudTaamimState()
        this.resetDistancesState()
        this.resetPartOfSpeechState();
        this.resetIsCaseSensitive();
        this.resetIsNegative();
        this.resetRepetitions();
        this.resetTransformations();
        this.resetExactnessLevel();
  }


    getCurrentPasukIds(){
        var pasukIds = [];
        if(this.state.openResults[this.state.searchResultsTabValue] && this.state.openResults[this.state.searchResultsTabValue].rowData){
            this.state.openResults[this.state.searchResultsTabValue].rowData.forEach(result => {
                pasukIds.push(result.pasukId);
            })
        }
        return pasukIds;
    }

    removeNikudAndTaamim(text){
        var cleanedText = text;
        cleanedText = this.removeNikud(this.removeTaamim(text));
     
        return cleanedText;
      }
     
      removeNikud(str){
       for (let i = str.length-1; i >= 0; i--){
           if('ְֱֲֳִֵֶַָֹֺֻּֽ־ׇֿׁׂׅׄ'.includes(str[i])){
               str = str.slice(0,i).concat(str.slice(i+1,str.length))
           }
       }
       return str;
     }
     
     removeTaamim(str){
       for (let i = str.length-1; i >= 0; i--){
           if('ֽ֑֖֛֢֣֤֥֦֧֪֚֭֮֒֓֔֕֗֘֙֜֝֞֟֠֡֨֩֫֬֯׀'.includes(str[i])){
               str = str.slice(0,i).concat(str.slice(i+1,str.length))
           }
       }
       return str;
     }

     getConvertedShemHashem(term){
         var shemHashem = this.state.nekudos === "N" ? "יְהֹוָה" : "יהוה"; // rtl is messing this up in VSCode. 
         while(-1 !== term.indexOf("ה'")){
             term = term.replace("ה'", shemHashem) // rtl is messing this up in VSCode. This replaces ה' with shem hashem 
         }
         return term;
     }

  initializeSearchState() {
    this.setSearchIsLoading(true)
    this.setState({ searchResultsHeight: '243px', showLastSearchResults: true })

    var searchResults = document.getElementById("searchResults");
    var paneContainerNodes = document.getElementById("paneContainer").childNodes[0];
    var searchResultsPane = paneContainerNodes.childNodes[4];
    searchResultsPane.style.height = "243px";
    searchResults.style.height = "243px";
    searchResults.style.display = "block";


//    if(this.state.keepResultsOpen){
//        this.keepResultsInTab()
//    }
    if(this.state.keepResultsOpen){
        this.setState({searchResultsTabValue: this.state.openResults.length-1})
    }

    this.setState({lastSearchTerms: this.state.terms})
  }

  async submitSearch(terms, scope, seq, filterIds, pasukIds,statisticsPickerValues,pasuk) {
  /*
      package what's relevant - remove what's not necessary

      public Integer getSearchLevel() {return getInt("searchLevel");} //eg. simple, advanced, etc.
      public Boolean isInSequence() {return getBoolean("isInSequence");} //do the terms need to be in sequence?
      public String getMorphInflections() {return getStr("morphInflections");} //LNTGS
      public ArrayList<IncludeExcludeLNT> getIncludeExcludeLNTs() {return (ArrayList<IncludeExcludeLNT>)getValue("includeExcludeLNTs");}
      public ArrayList<NamedLocation> getNamedLocations() {return (ArrayList<NamedLocation>)getValue("namedLocations");}
      public ArrayList<IdRange> getPasukRangeLocations() {return (ArrayList<IdRange>)getValue("pasukRangeLocations");}
      //per term settings
      public ArrayList<TermParameters> getBituiParameters() {return (ArrayList<TermParameters>)getValue("bituiParameters");}

IncludeExcludeLNT
      public Boolean isInclude() {return getBoolean("isInclude");} //include vs. exclude?
      public String getLNT() {return getStr("lnt");} //eg. L, N, T
      public ArrayList<Integer> getIds() {return (ArrayList<Integer>)getValue("ids");}
NamedLocation
      public Integer getKriaId() {return getInt("kriaId");}
      public Integer getAliyaId() {return getInt("aliyaId");}
IdRange
      public Integer getId1() {return getInt("id1");}
      public Integer getId2() {return getInt("id2");}
TermParameters
      public String getTerm() {return getStr("term");}
      public Boolean isNegative() {return getBoolean("isNegative");}
      public Boolean isCaseSensitive() {return getBoolean("isCaseSensitive");}
      public Integer getRepetitions() {return getInt("repetitions");}
      public Integer getTransformation() {return getInt("transformation");}
      public ArrayList<ArrayList<Integer>> getLetterNekudos() {return (ArrayList<ArrayList<Integer>>)getValue("letterNekudos");}
      public ArrayList<Integer> getNekudos() {return (ArrayList<Integer>)getValue("nekudos");}
      public ArrayList<Integer> getTaamim() {return (ArrayList<Integer>)getValue("taamim");}
      public ArrayList<DistanceRange> getDistances() {return (ArrayList<DistanceRange>)getValue("distances");}
DistanceRange
      public Integer getContainerLevelId() {return getInt("containerLevelId");} //eg. pasuk||parsha||sefer, etc.
      public Integer getUnitLevelId() {return getInt("unitLevelId");} //eg. letter||pasuk||parsha, etc.
      public Integer getDistance1() {return getInt("distance1");}
      public Integer getDistance2() {return getInt("distance2");}
    // pos for +start, neg for -end
   */

   this.initializeSearchState();
/*
    this.setSearchIsLoading(true)
    this.setState({ searchResultsHeight: '243px', showLastSearchResults: true })

    var searchResults = document.getElementById("searchResults");
    var paneContainerNodes = document.getElementById("paneContainer").childNodes[0];
    var searchResultsPane = paneContainerNodes.childNodes[4];
    searchResultsPane.style.height = "243px";
    searchResults.style.height = "243px";
    searchResults.style.display = "block";


//    if(this.state.keepResultsOpen){
//        this.keepResultsInTab()
//    }
    if(this.state.keepResultsOpen){
        this.setState({searchResultsTabValue: this.state.openResults.length-1})
    }

    this.setState({lastSearchTerms: this.state.terms})
*/
    var statisticsPickerArray = [{}]
    var include=[];var exclude=[];
    var includeOtherStats = this.state.includeOtherStats
    if(statisticsPickerValues && pasuk){
        for(var i=0;i<statisticsPickerValues.values.length;i++){
            if(statisticsPickerValues.values[i]==letterCoverageGridPos){//if it is letterCoverage
                var counts = util.charCounts(pasuk.text);
                for (var j=0; j < util.fullAlefBeis.length; j++) {
                    if(counts.hasOwnProperty(util.fullAlefBeis[j])) include.push(util.fullAlefBeis[j])
                    else exclude.push(util.fullAlefBeis[j])
                }
            }else{
                var val = pasuk[statisticsPickerValues.list[statisticsPickerValues.values[i]-1].colName]
                statisticsPickerArray.push({name:statisticsPickerValues.list[statisticsPickerValues.values[i]-1].colName,value:val})
            }
        }
    }else{
        includeOtherStats = true
    }
    var { dualLetterPicker, dualNekudaPicker, dualTaamPicker, searchParams } = this.state;
    if(!filterIds){
        var readFilterIds = encodeFilterIds(this.state.filterData);
        readFilterIds = parseInt(readFilterIds, 2).toString(16).toUpperCase();
        readFilterIds = util.right("00000000000"+readFilterIds,11);
    }
    var filterIds = filterIds?filterIds:readFilterIds;                    

    var searchParams = new SearchParameters();
    var bogusParams = new SearchParameters();
    searchParams.searchLevel = this.state.advancedSearchHeight == 0 ? 1 : 2; //1 = simple, 2 = advanced
    searchParams.isInSequence = (seq == "seq"); //alternate val is noseq
    searchParams.morphInflections = this.state.extSearchDefData; //str
    //alert(this.state.extSearchDefData + ":" + searchParams.morphInflections);
    searchParams.scope = scope;
    searchParams.sortDirection = this.state.sortDirection == 0 ? 0 : 1;
    searchParams.sortOrder = this.state.sortOrder;
    searchParams.sortedColumn = this.state.sortedColumn;
    searchParams.filterIds = filterIds;
    searchParams.includeExcludeLNTs = [new IncludeExcludeLNT(true, "L", include.length>0?include : includeOtherStats? dualLetterPicker.pickerModel1.values : []), new IncludeExcludeLNT(true, "N", includeOtherStats? dualNekudaPicker.pickerModel1.values : []), new IncludeExcludeLNT(true, "T", includeOtherStats? dualTaamPicker.pickerModel1.values : []),
                                       new IncludeExcludeLNT(false, "L", exclude.length>0?exclude : includeOtherStats? dualLetterPicker.pickerModel2.values : []), new IncludeExcludeLNT(false, "N", includeOtherStats? dualNekudaPicker.pickerModel2.values : []), new IncludeExcludeLNT(false, "T", includeOtherStats? dualTaamPicker.pickerModel2.values : [])];
    searchParams.tanachYear = statisticsPickerArray.findIndex(val=>val.name==="tanachYear")>-1? new Range(statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="tanachYear")].value,statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="tanachYear")].value): includeOtherStats? new Range(this.state.searchParams.pasukSettings.tanachYear[0], this.state.searchParams.pasukSettings.tanachYear[1]) : new Range(this.state.searchParams.pasukSettings.tanachYearRange[0], this.state.searchParams.pasukSettings.tanachYearRange[1]);
    searchParams.wordsPerPasuk = statisticsPickerArray.findIndex(val=>val.name==="teiva")>-1? new Range(statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="teiva")].value,statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="teiva")].value): includeOtherStats? new Range(this.state.searchParams.pasukSettings.wordsPerPasuk[0], this.state.searchParams.pasukSettings.wordsPerPasuk[1]) : new Range(this.state.searchParams.pasukSettings.wordsPerPasukRange[0], this.state.searchParams.pasukSettings.wordsPerPasukRange[1]);
    searchParams.lettersPerPasuk = statisticsPickerArray.findIndex(val=>val.name==="letter")>-1? new Range(statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="letter")].value,statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="letter")].value): includeOtherStats? new Range(this.state.searchParams.pasukSettings.lettersPerPasuk[0], this.state.searchParams.pasukSettings.lettersPerPasuk[1]) :new Range(this.state.searchParams.pasukSettings.lettersPerPasukRange[0], this.state.searchParams.pasukSettings.lettersPerPasukRange[1]);
    searchParams.lettersPerWord = statisticsPickerArray.findIndex(val=>val.name==="lettersPerWord")>-1? new Range(statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="lettersPerWord")].value,statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="lettersPerWord")].value): includeOtherStats? new Range(this.state.searchParams.pasukSettings.lettersPerWord[0], this.state.searchParams.pasukSettings.lettersPerWord[1]) : new Range(this.state.searchParams.pasukSettings.lettersPerWordRange[0], this.state.searchParams.pasukSettings.lettersPerWordRange[1]);
    searchParams.uniqueLetter = statisticsPickerArray.findIndex(val=>val.name==="uniqueLetter")>-1? new Range(statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="uniqueLetter")].value,statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="uniqueLetter")].value): includeOtherStats? new Range(this.state.searchParams.pasukSettings.uniqueLetter[0], this.state.searchParams.pasukSettings.uniqueLetter[1]) : new Range(this.state.searchParams.pasukSettings.uniqueLetterRange[0], this.state.searchParams.pasukSettings.uniqueLetterRange[1]);
    searchParams.uniqueLetterCS = statisticsPickerArray.findIndex(val=>val.name==="uniqueLetterCS")>-1? new Range(statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="uniqueLetterCS")].value,statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="uniqueLetterCS")].value): includeOtherStats? new Range(this.state.searchParams.pasukSettings.uniqueLetterCS[0], this.state.searchParams.pasukSettings.uniqueLetterCS[1]) : new Range(this.state.searchParams.pasukSettings.uniqueLetterCSRange[0], this.state.searchParams.pasukSettings.uniqueLetterCSRange[1]);
    searchParams.tenuosPerPasuk = statisticsPickerArray.findIndex(val=>val.name==="tenua")>-1? new Range(statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="tenua")].value,statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="tenua")].value): includeOtherStats? new Range(this.state.searchParams.pasukSettings.tenuosPerPasuk[0], this.state.searchParams.pasukSettings.tenuosPerPasuk[1]) : new Range(this.state.searchParams.pasukSettings.tenuosPerPasukRange[0], this.state.searchParams.pasukSettings.tenuosPerPasukRange[1]);
    searchParams.tenuosPerWord = statisticsPickerArray.findIndex(val=>val.name==="tenuosPerWord")>-1? new Range(statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="tenuosPerWord")].value,statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="tenuosPerWord")].value): includeOtherStats? new Range(this.state.searchParams.pasukSettings.tenuosPerWord[0], this.state.searchParams.pasukSettings.tenuosPerWord[1]) : new Range(this.state.searchParams.pasukSettings.tenuosPerWordRange[0], this.state.searchParams.pasukSettings.tenuosPerWordRange[1]);
    searchParams.tenuosPerLetter = statisticsPickerArray.findIndex(val=>val.name==="tenuosPerLetter")>-1? new Range(statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="tenuosPerLetter")].value,statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="tenuosPerLetter")].value): includeOtherStats? new Range(this.state.searchParams.pasukSettings.tenuosPerLetter[0], this.state.searchParams.pasukSettings.tenuosPerLetter[1]) : new Range(this.state.searchParams.pasukSettings.tenuosPerLetterRange[0], this.state.searchParams.pasukSettings.tenuosPerLetterRange[1]);
    searchParams.taamimPerPasuk = statisticsPickerArray.findIndex(val=>val.name==="taam")>-1? new Range(statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="taam")].value,statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="taam")].value): includeOtherStats? new Range(this.state.searchParams.pasukSettings.taamimPerPasuk[0], this.state.searchParams.pasukSettings.taamimPerPasuk[1]) : new Range(this.state.searchParams.pasukSettings.taamimPerPasukRange[0], this.state.searchParams.pasukSettings.taamimPerPasukRange[1]);
    searchParams.taamimPerWord = statisticsPickerArray.findIndex(val=>val.name==="taamimPerWord")>-1? new Range(statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="taamimPerWord")].value,statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="taamimPerWord")].value): includeOtherStats? new Range(this.state.searchParams.pasukSettings.taamimPerWord[0], this.state.searchParams.pasukSettings.taamimPerWord[1]) : new Range(this.state.searchParams.pasukSettings.taamimPerWordRange[0], this.state.searchParams.pasukSettings.taamimPerWordRange[1]);
    searchParams.taamimPerTenua = statisticsPickerArray.findIndex(val=>val.name==="taamimPerTenua")>-1? new Range(statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="taamimPerTenua")].value,statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="taamimPerTenua")].value): includeOtherStats? new Range(this.state.searchParams.pasukSettings.taamimPerTenua[0], this.state.searchParams.pasukSettings.taamimPerTenua[1]) : new Range(this.state.searchParams.pasukSettings.taamimPerTenuaRange[0], this.state.searchParams.pasukSettings.taamimPerTenuaRange[1]);
    searchParams.gematriaPerPasuk = statisticsPickerArray.findIndex(val=>val.name==="standard")>-1? new Range(statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="standard")].value,statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="standard")].value): includeOtherStats? new Range(this.state.searchParams.pasukSettings.gematriaPerPasuk[0], this.state.searchParams.pasukSettings.gematriaPerPasuk[1]) : new Range(this.state.searchParams.pasukSettings.gematriaPerPasukRange[0], this.state.searchParams.pasukSettings.gematriaPerPasukRange[1]);
    searchParams.gematriaPerWord = statisticsPickerArray.findIndex(val=>val.name==="gematriaPerWord")>-1? new Range(statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="gematriaPerWord")].value,statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="gematriaPerWord")].value): includeOtherStats? new Range(this.state.searchParams.pasukSettings.gematriaPerWord[0], this.state.searchParams.pasukSettings.gematriaPerWord[1]) : new Range(this.state.searchParams.pasukSettings.gematriaPerWordRange[0], this.state.searchParams.pasukSettings.gematriaPerWordRange[1]);
    searchParams.gematriaPerLetter = statisticsPickerArray.findIndex(val=>val.name==="gematriaPerLetter")>-1? new Range(statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="gematriaPerLetter")].value,statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="gematriaPerLetter")].value): includeOtherStats? new Range(this.state.searchParams.pasukSettings.gematriaPerLetter[0], this.state.searchParams.pasukSettings.gematriaPerLetter[1]) : new Range(this.state.searchParams.pasukSettings.gematriaPerLetterRange[0], this.state.searchParams.pasukSettings.gematriaPerLetterRange[1]);
    searchParams.atbashPerPasuk = statisticsPickerArray.findIndex(val=>val.name==="atbash")>-1? new Range(statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="atbash")].value,statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="atbash")].value): includeOtherStats? new Range(this.state.searchParams.pasukSettings.atbashPerPasuk[0], this.state.searchParams.pasukSettings.atbashPerPasuk[1]) : new Range(this.state.searchParams.pasukSettings.atbashPerPasukRange[0], this.state.searchParams.pasukSettings.atbashPerPasukRange[1]);
    searchParams.atbashPerWord = statisticsPickerArray.findIndex(val=>val.name==="atbashPerWord")>-1? new Range(statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="atbashPerWord")].value,statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="atbashPerWord")].value): includeOtherStats? new Range(this.state.searchParams.pasukSettings.atbashPerWord[0], this.state.searchParams.pasukSettings.atbashPerWord[1]) : new Range(this.state.searchParams.pasukSettings.atbashPerWordRange[0], this.state.searchParams.pasukSettings.atbashPerWordRange[1]);
    searchParams.atbashPerLetter = statisticsPickerArray.findIndex(val=>val.name==="atbashPerLetter")>-1? new Range(statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="atbashPerLetter")].value,statisticsPickerArray[statisticsPickerArray.findIndex(val=>val.name==="atbashPerLetter")].value): includeOtherStats? new Range(this.state.searchParams.pasukSettings.atbashPerLetter[0], this.state.searchParams.pasukSettings.atbashPerLetter[1]) : new Range(this.state.searchParams.pasukSettings.atbashPerLetterRange[0], this.state.searchParams.pasukSettings.atbashPerLetterRange[1]);
    searchParams.exactnessLevel = this.state.ssExactnessLevel;//add the exactnesslevel to searchParams

/*
        {id: 1, name: parshaName, colName: "parshaName", icon: ""},
        {id: 2, name: sidraName, colName: "sidraName", icon: ""},
        {id: 3, name: otherKriosName, colName: "otherKriosName", icon: ""},
        {id: 4, name: namedSections, colName: "namedSections", icon: ""},
        {id: 5, name: tanachYear, colName: "tanachYear", icon: ""},
        {id: 6, name: words, colName: "teiva", icon: ""},
        {id: 7, name: letters, colName: "letter", icon: ""},
        {id: 8, name: lettersPerWord, colName: "lettersPerWord", icon: ""},
        {id: 9, name: uniqueLetters, colName: "uniqueLetter", icon: ""},
        {id: 10, name: uniqueLettersCS, colName: "uniqueLetterCS", icon: ""},
        {id: 11, name: letterCoverage, colName: "letterCoverage", icon: ""},
        {id: 12, name: tenua, colName: "tenua", icon: ""},
        {id: 13, name: tenuosPerWord, colName: "tenuosPerWord", icon: ""},
        {id: 14, name: tenuosPerLetter, colName: "tenuosPerLetter", icon: ""},
        {id: 15, name: taam, colName: "taam", icon: ""},
        {id: 16, name: taamimPerWord, colName: "taamimPerWord", icon: ""},
        {id: 17, name: taamimPerTenua, colName: "taamimPerTenua", icon: ""},
        {id: 18, name: gematria, colName: "standard", icon: ""},
        {id: 19, name: gematriaPerWord, colName: "gematriaPerWord", icon: ""},
        {id: 20, name: gematriaPerLetter, colName: "gematriaPerLetter", icon: ""},
        {id: 21, name: atbash, colName: "atbash", icon: ""},
        {id: 22, name: atbashPerWord, colName: "atbashPerWord", icon: ""},
        {id: 23, name: atbashPerLetter, colName: "atbashPerLetter", icon: ""},
        {id: targumGridPos, name: targumText, colName: "targumText", icon: ""},
        {id: textGridPos, name: text, colName: "text", icon: ""}
*/

    var changedStatsArray = includeOtherStats ? statisticsPickerValues ? statisticsPickerValues.values : [] : statisticsPickerValues.values
    var firstStatIndex = 5; //year of occurrence
    if(includeOtherStats){
        if(!changedStatsArray.includes(firstStatIndex+0) && (this.state.searchParams.pasukSettings.tanachYear[0]!=this.state.searchParams.pasukSettings.tanachYearRange[0] || this.state.searchParams.pasukSettings.tanachYear[1]!=this.state.searchParams.pasukSettings.tanachYearRange[1]))
            changedStatsArray.push(firstStatIndex+0)
        if(!changedStatsArray.includes(firstStatIndex+1) && (this.state.searchParams.pasukSettings.wordsPerPasuk[0]!=this.state.searchParams.pasukSettings.wordsPerPasukRange[0] || this.state.searchParams.pasukSettings.wordsPerPasuk[1]!=this.state.searchParams.pasukSettings.wordsPerPasukRange[1]))
            changedStatsArray.push(firstStatIndex+1)
        if(!changedStatsArray.includes(firstStatIndex+2) && (this.state.searchParams.pasukSettings.lettersPerPasuk[0]!=this.state.searchParams.pasukSettings.lettersPerPasukRange[0] || this.state.searchParams.pasukSettings.lettersPerPasuk[1]!=this.state.searchParams.pasukSettings.lettersPerPasukRange[1]))
            changedStatsArray.push(firstStatIndex+2)
        if(!changedStatsArray.includes(firstStatIndex+3) && (this.state.searchParams.pasukSettings.lettersPerWord[0]!=this.state.searchParams.pasukSettings.lettersPerWordRange[0] || this.state.searchParams.pasukSettings.lettersPerWord[1]!=this.state.searchParams.pasukSettings.lettersPerWordRange[1]))
            changedStatsArray.push(firstStatIndex+3)
        if(!changedStatsArray.includes(firstStatIndex+4) && (this.state.searchParams.pasukSettings.uniqueLetter[0]!=this.state.searchParams.pasukSettings.uniqueLetterRange[0] || this.state.searchParams.pasukSettings.uniqueLetter[1]!=this.state.searchParams.pasukSettings.uniqueLetterRange[1]))
            changedStatsArray.push(firstStatIndex+4)
        if(!changedStatsArray.includes(firstStatIndex+5) && (this.state.searchParams.pasukSettings.uniqueLetterCS[0]!=this.state.searchParams.pasukSettings.uniqueLetterCSRange[0] || this.state.searchParams.pasukSettings.uniqueLetterCS[1]!=this.state.searchParams.pasukSettings.uniqueLetterCSRange[1]))
            changedStatsArray.push(firstStatIndex+5)
        if(!changedStatsArray.includes(firstStatIndex+7) && (this.state.searchParams.pasukSettings.tenuosPerPasuk[0]!=this.state.searchParams.pasukSettings.tenuosPerPasukRange[0] || this.state.searchParams.pasukSettings.tenuosPerPasuk[1]!=this.state.searchParams.pasukSettings.tenuosPerPasukRange[1]))
            changedStatsArray.push(firstStatIndex+7)
        if(!changedStatsArray.includes(firstStatIndex+8) && (this.state.searchParams.pasukSettings.tenuosPerWord[0]!=this.state.searchParams.pasukSettings.tenuosPerWordRange[0] || this.state.searchParams.pasukSettings.tenuosPerWord[1]!=this.state.searchParams.pasukSettings.tenuosPerWordRange[1]))
            changedStatsArray.push(firstStatIndex+8)
        if(!changedStatsArray.includes(firstStatIndex+9) && (this.state.searchParams.pasukSettings.tenuosPerLetter[0]!=this.state.searchParams.pasukSettings.tenuosPerLetterRange[0] || this.state.searchParams.pasukSettings.tenuosPerLetter[1]!=this.state.searchParams.pasukSettings.tenuosPerLetterRange[1]))
            changedStatsArray.push(firstStatIndex+9)
        if(!changedStatsArray.includes(firstStatIndex+10) && (this.state.searchParams.pasukSettings.taamimPerPasuk[0]!=this.state.searchParams.pasukSettings.taamimPerPasukRange[0] || this.state.searchParams.pasukSettings.taamimPerPasuk[1]!=this.state.searchParams.pasukSettings.taamimPerPasukRange[1]))
            changedStatsArray.push(firstStatIndex+10)
        if(!changedStatsArray.includes(firstStatIndex+11) && (this.state.searchParams.pasukSettings.taamimPerWord[0]!=this.state.searchParams.pasukSettings.taamimPerWordRange[0] || this.state.searchParams.pasukSettings.taamimPerWord[1]!=this.state.searchParams.pasukSettings.taamimPerWordRange[1]))
            changedStatsArray.push(firstStatIndex+11)
        if(!changedStatsArray.includes(firstStatIndex+12) && (this.state.searchParams.pasukSettings.taamimPerTenua[0]!=this.state.searchParams.pasukSettings.taamimPerTenuaRange[0] || this.state.searchParams.pasukSettings.taamimPerTenua[1]!=this.state.searchParams.pasukSettings.taamimPerTenuaRange[1]))
            changedStatsArray.push(firstStatIndex+12)
        if(!changedStatsArray.includes(firstStatIndex+13) && (this.state.searchParams.pasukSettings.gematriaPerPasuk[0]!=this.state.searchParams.pasukSettings.gematriaPerPasukRange[0] || this.state.searchParams.pasukSettings.gematriaPerPasuk[1]!=this.state.searchParams.pasukSettings.gematriaPerPasukRange[1]))
            changedStatsArray.push(firstStatIndex+13)
        if(!changedStatsArray.includes(firstStatIndex+14) && (this.state.searchParams.pasukSettings.gematriaPerWord[0]!=this.state.searchParams.pasukSettings.gematriaPerWordRange[0] || this.state.searchParams.pasukSettings.gematriaPerWord[1]!=this.state.searchParams.pasukSettings.gematriaPerWordRange[1]))
            changedStatsArray.push(firstStatIndex+14)
        if(!changedStatsArray.includes(firstStatIndex+15) && (this.state.searchParams.pasukSettings.gematriaPerLetter[0]!=this.state.searchParams.pasukSettings.gematriaPerLetterRange[0] || this.state.searchParams.pasukSettings.gematriaPerLetter[1]!=this.state.searchParams.pasukSettings.gematriaPerLetterRange[1]))
            changedStatsArray.push(firstStatIndex+15)
        if(!changedStatsArray.includes(firstStatIndex+16) && (this.state.searchParams.pasukSettings.atbashPerPasuk[0]!=this.state.searchParams.pasukSettings.atbashPerPasukRange[0] || this.state.searchParams.pasukSettings.atbashPerPasuk[1]!=this.state.searchParams.pasukSettings.atbashPerPasukRange[1]))
            changedStatsArray.push(firstStatIndex+16)
        if(!changedStatsArray.includes(firstStatIndex+17) && (this.state.searchParams.pasukSettings.atbashPerWord[0]!=this.state.searchParams.pasukSettings.atbashPerWordRange[0] || this.state.searchParams.pasukSettings.atbashPerWord[1]!=this.state.searchParams.pasukSettings.atbashPerWordRange[1]))
            changedStatsArray.push(firstStatIndex+17)
        if(!changedStatsArray.includes(firstStatIndex+18) && (this.state.searchParams.pasukSettings.atbashPerLetter[0]!=this.state.searchParams.pasukSettings.atbashPerLetterRange[0] || this.state.searchParams.pasukSettings.atbashPerLetter[1]!=this.state.searchParams.pasukSettings.atbashPerLetterRange[1]))
            changedStatsArray.push(firstStatIndex+18)
        if(!changedStatsArray.includes(firstStatIndex+6) && (include.length>0 || exclude.length>0 || dualLetterPicker.pickerModel1.values.length>0 || dualLetterPicker.pickerModel2.values.length>0))
            changedStatsArray.push(firstStatIndex+6)
        await this.setState({changedStatsArray})//set changedStatsArray here so that it will be updated by the time the boolean is changed
    }

    var termParameters, exactnessLevel;
    if(includeOtherStats){
        searchParams.hasParshios = this.state.searchParams.placementSettings.hasParshios;
        searchParams.hasKrios = this.state.searchParams.placementSettings.hasKrios;
        searchParams.hasSederTefila = this.state.searchParams.placementSettings.hasSederTefila;
        searchParams.hasShiros = this.state.searchParams.placementSettings.hasShiros;
        searchParams.hasBrachos = this.state.searchParams.placementSettings.hasBrachos;
        searchParams.hasTefilos = this.state.searchParams.placementSettings.hasTefilos;
        searchParams.hasMitzvos = this.state.searchParams.placementSettings.hasMitzvos;
        searchParams.hasShonos = this.state.searchParams.placementSettings.hasShonos;
        searchParams.locationIncludeFlag = this.state.searchParams.placementSettings.placementIncludeExclude == 100;

        var e, i, j, k;
        for (i = 0; i < this.state.placementRowData.length-1; i++) { //last position in placementRowData is never populated
            e = this.state.placementRowData[i];
            if (e.type)
                searchParams.namedLocations.push(new NamedLocation(e.val4, e.val5)); //kriaId, aliyaId
            else
                searchParams.pasukRangeLocations.push(new PerekPasukRange(e.val1, e.val2, e.val3, e.val4, e.val5, e.val6)); //int
        }

        for (i = 0; i < this.state.bituiSettingsList.length; i++) { //last position in placementRowData is never populated
            e = this.state.bituiSettingsList[i]; //BituiSettings()
            termParameters = new TermParameters();
            var term = this.state.bituim[i].term;
            if(-1 !== this.removeNikudAndTaamim(term).indexOf("ה'")){// writing this backwards to avoid rtl issues in VSCode
                term = this.getConvertedShemHashem(term);
            }
    //modify search terms based on "fuzziness" - changes var term (the term) and e (the settingslist for that term)
    //        switch(this.state.fuzzyState){        //changed fuzzyState to exactnessLevel
    //            case 0:
    //                //e.transformation = 0;
    //                break;
    //            case 1:
    //                //e.transformation = 0;
    //                term = "*" + term + "*";

    //                break;
    //            case 2:
    //                //e.transformation = 0;
    //                var termArr = term.split("");
    //                for(j=0; j<termArr.length; j++){
    //                   termArr[j] += "*";
    //                }
    //                termArr.unshift("*");
    //                term = termArr.join("");
    //                break;
    //            case 3:
    //                 e.transformation = 1;
    //                 var termArr = term.split("");
    //                 for(j=0; j<termArr.length; j++){
    //                      termArr[j] += "*";
    //                 }
    //                termArr.unshift("*");
    //                term = termArr.join("");
    //                break;
    //        }

            var adjustedTransformation = e.transformation;
    //If the Advanced Slider exactnessLevel slider is off, use this from the Simple Slider - this.state.ssExactnessLevel.
    //If it's on, use the per-term exactness level, found in this.state.bituiSettingsList.
            if(e.exactnessLevelChecked){
                exactnessLevel = this.state.bituiSettingsList[i].exactnessLevel;
            } else {
                exactnessLevel = this.state.ssExactnessLevel;
    //            exactnessLevel = null;
            }

    //        if (exactnessLevel == 1)
    //            term = "*" + term + "*";
    //        else if (exactnessLevel > 1 ) {
    //            var termArr = term.split("");
    //            for(j=0; j<termArr.length; j++){
    //               termArr[j] += "*";
    //            }
    //            termArr.unshift("*");
    //            term = termArr.join("");
    //            if (exactnessLevel > 2 && e.transformation == 0) //only adjust transformation to anagram due to fuzziness if transformation hasn't been set
    //                 adjustedTransformation = 1;
    //        }
            // searchParams.exactnessLevel = this.state.ssExactnessLevel;//add the exactnesslevel to searchParams
            termParameters.term = term;
            termParameters.exactnessLevel = exactnessLevel;  //<--
            termParameters.isNegative = e.isNegative;
            termParameters.isCaseSensitive = e.isCaseSensitive;
            termParameters.repetitions = e.repetitions; //int
            termParameters.transformation =  e.transformation; //int
            e.letterNekudaPickers.map(function(el){termParameters.letterNekudos.push(el.values);});
            termParameters.letterNekudos.reverse(); //for RTL issues
            //termParameters.nekudos = e.nekudaPicker.values; //ids
            e.generalNekudaPickers.map(function(el){termParameters.nekudos.push(el.values);});
            termParameters.nekudos.reverse(); //for RTL issues
            //termParameters.taamim = e.taamPicker.values; //ids
            e.generalTaamPickers.map(function(el){termParameters.taamim.push(el.values);});
            termParameters.taamim.reverse(); //for RTL issues
            termParameters.containerDistances.push(new ContainerDistanceRange(4, e.madreigos[0], e.containerDistances[0][0], e.containerDistances[0][1]));
            termParameters.containerDistances.push(new ContainerDistanceRange(4, e.madreigos[1], 100-e.containerDistances[1][1], 100-e.containerDistances[1][0])); //everything in right-hand column (rosh) needs to be reversed
            termParameters.containerDistances.push(new ContainerDistanceRange(3, e.madreigos[2], e.containerDistances[2][0], e.containerDistances[2][1]));
            termParameters.containerDistances.push(new ContainerDistanceRange(3, e.madreigos[3], 100-e.containerDistances[3][1], 100-e.containerDistances[3][0]));
            termParameters.containerDistances.push(new ContainerDistanceRange(2, e.madreigos[4], e.containerDistances[4][0], e.containerDistances[4][1]));
            termParameters.containerDistances.push(new ContainerDistanceRange(2, e.madreigos[5], 100-e.containerDistances[5][1], 100-e.containerDistances[5][0]));

            //the following are all from the POS tab
            termParameters.binyanim = e.binyanim;
            termParameters.zmanim = e.zmanim;
            termParameters.misparim = e.misparim;
            termParameters.gufim = e.gufim;
            termParameters.genders = e.genders;
            termParameters.misparimSof = e.misparimSof;
            termParameters.gufimSof = e.gufimSof;
            termParameters.gendersSof = e.gendersSof;
            termParameters.prefixes = e.prefixes;
            termParameters.languages = e.languages;
            termParameters.wordTypes = e.wordTypes;
            termParameters.objectCategories = e.objectCategories;
            termParameters.numberCategories = e.numberCategories;
            termParameters.hasInfix = e.hasInfix;
            termParameters.hasSuffix = e.hasSuffix;
            termParameters.frequency = new Range(e.frequency[0], e.frequency[1]);
            termParameters.gematria = new Range(e.gematria[0], e.gematria[1]);
            termParameters.atbash = new Range(e.atbash[0], e.atbash[1]);
            termParameters.lettersQty = new Range(e.lettersQty[0], e.lettersQty[1]);
            termParameters.vowelsQty = new Range(e.vowelsQty[0], e.vowelsQty[1]);
            termParameters.tenuosQty = new Range(e.tenuosQty[0], e.tenuosQty[1]);

            searchParams.bituiParameters.push(termParameters);
        }
        var termDistances = [];
        for (k = 0; k < Object.keys(this.state.termDistances).length; k++){
            var currDistancePair = this.state.termDistances[Object.keys(this.state.termDistances)[k]];
            var newDistanceObject = {}
            //add one to term indices for backend
            newDistanceObject.termPosition1 = currDistancePair.termPosition1+1;
            newDistanceObject.termPosition2 = currDistancePair.termPosition2+1;
            newDistanceObject.directionId = currDistancePair.direction;
            newDistanceObject.distance1 = 100-currDistancePair.distance[1];
            newDistanceObject.distance2 = 100-currDistancePair.distance[0];
            newDistanceObject.unitLevelId = currDistancePair.madreiga;
            termDistances.push(newDistanceObject)
        }
        searchParams.termDistances = termDistances;
    }
    searchParams.textSource = pasuk? 1 : this.state.textSource==='targum'?2:1;//only want to take targum source into account if not searching from a pasuk context menu

    //add current search results if searching within results
    searchParams.searchWithinResults = this.state.searchWithinResults;
    searchParams.pasukIds = pasukIds;

  //const msg = "word0"+this.state.bituim[0].term+"searchLevel:"+searchParams.searchLevel + ":isInSequence:" + searchParams.isInSequence + ":morphInflections:" + searchParams.morphInflections + ":scope:" + searchParams.scope + ":filterIds:" + searchParams.filterIds
//console.log(JSON.stringify(searchParams));
//alert(msg);
    var url = properties.env.webServiceURL + '/BaseHaSefer/getPesukimWithTerms';
    var willReturnAllResults = this.state.searchResultsPerPage === "showAll";
    url += "?willReturnAllResults=" + willReturnAllResults;
    const response = await fetch(url, {method: 'post', body: JSON.stringify(searchParams)});
    const body = await response.json();

    const searchData = {
        results: body,
        searchParams: searchParams
    }
    // properties.setStatisticsPickerValues(changedStatsArray)
    var textSourceSearched=this.state.textSourceSearched
    textSourceSearched[this.state.searchResultsTabValue]=this.state.textSource
    // this.setState({superTreeData:await util.setUpData(searchParams,this.state.placementRowData,this.state.filterData,this.props.translate,this.state.inLeftBar?this.state.inLeftBar:true)});
    this.setState({changedStatsArray,dataForTree:searchParams},()=>{this.setState({changedStatsArrayBool:!this.state.changedStatsArrayBool,textSourceSearched},()=>{this.setState({updateLeftBar:!this.state.updateLeftBar})})});
    return searchData;
  }
    
    toggleIncludeOtherStats(){
        this.setState({includeOtherStats:!this.state.includeOtherStats})
    }


//   resetSuperTreeData(updatedData){
//     this.setState({superTreeData:updatedData});
//   }

  getSearchSettings(){
        var searchParams = {}

        var salt = bcrypt.genSaltSync(10);
        var hash = bcrypt.hashSync("taryag_searchSettings", salt);

        searchParams.bituim = this.state.bituim;
        searchParams.bituiSettingsList = this.state.bituiSettingsList;
        searchParams.termDistances = this.state.termDistances;
        searchParams.dualLetterPicker = this.state.dualLetterPicker;
        searchParams.dualNekudaPicker = this.state.dualNekudaPicker;
        searchParams.dualTaamPicker = this.state.dualTaamPicker;
        searchParams.dualTaamPicker = this.state.dualTaamPicker;
        searchParams.placementRowData = this.state.placementRowData;
        searchParams.extSearchDefData = this.state.extSearchDefData;
        searchParams.extFilterData = this.state.extFilterData;
        searchParams.extBituimText = this.state.extBituimText;
        searchParams.terms = this.state.terms;
        searchParams.hash = hash;
        searchParams.seq = this.state.seq;
        searchParams.filterData = this.state.filterData;
        searchParams.sortDirection = this.state.sortDirection;
        searchParams.sortOrder = this.state.sortOrder;

//        searchParams.binyanim = this.state.binyanim;
//        searchParams.zmanim = this.state.zmanim;
//        searchParams.misparim = this.state.misparim;
//        searchParams.gufim = this.state.gufim;
//        searchParams.genders = this.state.genders;
//        searchParams.misparimSof = this.state.misparimSof;
//        searchParams.gufimSof = this.state.gufimSof;
//        searchParams.gendersSof = this.state.gendersSof;
//        searchParams.prefixes = this.state.prefixes;
//        searchParams.languages = this.state.languages;
//        searchParams.wordTypes = this.state.wordTypes;
//        searchParams.objectCategories = this.state.objectCategories;
//        searchParams.numberCategories = this.state.numberCategories;
//        searchParams.hasInfix = this.state.hasInfix;
//        searchParams.hasSuffix = this.state.hasSuffix;
//        searchParams.lettersQty = this.state.lettersQty;
//        searchParams.nekudos = this.state.nekudos;
//        searchParams.taamim = this.state.taamim;
//        searchParams.gematria = this.state.gematria;

        var blob = new Blob([JSON.stringify(searchParams)], {type: "text/plain;charset=utf-8"});

        return blob;
  }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    copySelectedToSearchbar(text){
        this.setState({terms: text}, () => {
                this.updateBituim(text);
        });
    }

    searchWithSelected(text){
        this.setState({terms: text}, () => {
                this.updateBituim(text, async () => {
                    var filterIds = encodeFilterIds(this.state.filterData);
                    filterIds = parseInt(filterIds, 2).toString(16).toUpperCase();
                    filterIds = util.right("00000000000"+filterIds,11);
                    var pasukIds = []
                    if(this.state.searchWithinResults){
                        pasukIds = this.getCurrentPasukIds()
                    }
                    this.extOpenSearchResults() // need this for results to load in new tab when option selected
                    const body = await this.submitSearch(this.state.terms, this.state.scope, this.state.seq, filterIds, pasukIds);
                    this.extOpenSearchResults(body);
                    this.setSearchIsLoading(false);
                });
        });
    }

   loadSearchParams(e){
        var files = document.getElementById('search-params-upload').files;
        var fr = new FileReader();
        const fakeThis = this; // persist this inside fr.onLoad
        fakeThis.setState = this.setState; // allow setState to be called inside fr.onLoad

        fr.onload = function(e) {
            if(fakeThis.isJson(e.target.result)){
                var result = JSON.parse(e.target.result);
                if(result.hash && bcrypt.compareSync("taryag_searchSettings", result.hash)){
                    fakeThis.setState({
                            bituim: result.bituim,
                            bituiSettingsList: result.bituiSettingsList,
                            termDistances: result.termDistances,
                            dualLetterPicker: result.dualLetterPicker,
                            dualNekudaPicker: result.dualNekudaPicker,
                            dualTaamPicker: result.dualTaamPicker,
                            dualTaamPicker: result.dualTaamPicker,
                            placementRowData: result.placementRowData,
                            extSearchDefData: result.extSearchDefData,
                            extFilterData: result.extFilterData,
                            extBituimText: result.extBituimText,
                            terms: result.terms,
                            seq: result.seq,
                            filterData: result.filterData,
                            sortDirection: result.sortDirection,
                            sortOrder: result.sortOrder,
                            sortedColumn: result.sortedColumn,

//                            binyanim: result.binyanim,
//                            zmanim: result.zmanim,
//                            misparim: result.misparim,
//                            gufim: result.gufim,
//                            genders: result.genders,
//                            misparimSof: result.misparimSof,
//                            gufimSof: result.gufimSof,
//                            gendersSof: result.gendersSof,
//                            prefixes: result.prefixes,
//                            languages: result.languages,
//                            wordTypes: result.wordTypes,
//                            objectCategories: result.objectCategories,
//                            numberCategories: result.numberCategories,
//                            hasInfix: result.hasInfix,
//                            hasSuffix: result.hasSuffix,
//                            lettersQty: result.lettersQty,
//                            nekudos: result.nekudos,
//                            taamim: result.taamim,
//                            gematria: result.gematria,
                    })
                } else {
                    fakeThis.handleOpenInvalidFileDialog()
                }
            } else {
                fakeThis.handleOpenInvalidFileDialog()
            }
        }
        fr.readAsText(files.item(0));

        //reset file input
        e.target.value = null;
   }

    loadSearchResults(e){

        this.setState({searchResultsHeight: '243px', searchResultsOpen: true}, () => {
            this.setSearchIsLoading(true)
        })

        var files = document.getElementById('search-results-upload').files;
        var fr = new FileReader();
        const fakeThis = this; // persist this inside fr.onLoad
        fakeThis.setState = this.setState; // allow setState to be called inside fr.onLoad

        fr.onload = function(e) {
            if(fakeThis.isJson(e.target.result)){
                var result = JSON.parse(e.target.result);
                if(result.hash && bcrypt.compareSync("taryag_searchResults", result.hash)){
//                    if(!fakeThis.state.resultsReceived){ // prevent lag on loading screen for first search
                    var resultsData = {};
                    resultsData.results = result.rowData;
                        fakeThis.extOpenSearchResults()
//                    }
                    fakeThis.extOpenSearchResults(resultsData, result.terms);
                    fakeThis.setState({
                        searchIsLoading: false,
                    })
                } else {
                    fakeThis.handleOpenInvalidFileDialog();
                    fakeThis.setSearchIsLoading(false);
                }
            } else {
                fakeThis.handleOpenInvalidFileDialog();
                fakeThis.setSearchIsLoading(false);
            }
        }
        fr.readAsText(files.item(0));

        //reset file input
        e.target.value = null;
    }

        async openTaamParser(pasukLocation,navigate,resultsApi){
            var openedBefore = this.state.taamParserOpen
            const body = await this.fetchTaamParseData(pasukLocation ? pasukLocation : this.state.taamParserPasukId)
            if(openedBefore){
                this.setState({taamParseData:body, taamParserPasukId: body.pasukId, taamParserLocation: body.pasukLocation})
            }else{
                // this.setState({taamParseData: body})
                this.setState({taamParseData: body, taamParserPasukId: body.pasukId, taamParserLocation: body.pasukLocation, taamParserOpen: false}, () => {
                    this.setState({taamParserOpen: true})
                })
                if(!navigate)this.toggleTaamParser(true,body.pasukId,resultsApi)


                if (this.state.isFirstTimeOpened && this.props.initialFullScreen && "TY".indexOf(this.props.initialFullScreen.toUpperCase()) >= 0) {
                    this.handleTaamParserFullscreen();
                    this.setState({isFirstTimeOpened:false});
                }
            }
        }

        closeTaamParser(){
            this.toggleTaamParser(false, null, null, null)
            // this.setState({taamParserOpen: false})
        }
        openLexiconViewer(srcType,text){
            var beta=false;
            if(srcType==="Targum") beta=true;
            this.setState({lexiconSrcType:srcType,lexiconSearchText:text,beta},
                ()=>{this.setState({beta:false},()=>{if(!this.state.lexiconOpen) this.toggleLexicon(true)})})
            
        }

        async fetchTaamParseData(pasukLocation){
            var response = null;
            if (typeof(pasukLocation) === 'number'){
                response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getParseTree?source=bhsWebApp&pasukId=' + pasukLocation);
            } else if (typeof(pasukLocation) === 'string'){
                response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getParseTree?source=bhsWebApp&location=' + pasukLocation);
            }
            const body = await response.json();
            for(let i = 0; i < body.nodes.length; i++){
                body.nodes[i].customIsExpanded = true;
                body.nodes[i].id = i;
            }
            return body;
        }

        updateTextComparatorWordCountData(newData){
            this.setState({
                textComparatorWordCountData: newData
            })
        }

        textComparatorText1GridReady(params){
            this.setState({
                textComparatorText1Api: params.api,
                textComparatorText1ColumnApi: params.columnApi
              }, () => {
                this.state.textComparatorText1Api.setRowData(this.state.text1Data);
    
                //following CSS classes seem to get overridden below in PlacementRenderer by placementGridLine and rangeGridLine
                      this.state.textComparatorText1Api.setColumnDefs(
                               [{headerName: this.props.translate("addLines"), headerClass: 'row-thin-center', field: "namedSection", width: 1168, cellClass: "row-thin-small", cellRendererFramework: PlacementRenderer, cellRendererParams:
                                   {
                                    placementRowData:this.state.text1Data,
                                    extModifyPlacementRow: this.modifyTextComparatorText1Row,
                                    extAddPlacementRow: this.addTextComparatorText1Row,
                                    extDeletePlacementRow: this.deleteTextComparatorText1Row,
                                    translate: this.props.translate, //do I need to pass this here?
                                },
                          //cellClass: function(params) {return params.data.type ? 'placementGridLine' : 'rangeGridLine';},
                                }]
                          );
                      if (this.state.text1Data.length > 0)
                          this.state.textComparatorText1Api.setFocusedCell(0, "namedSection", null);
                    //   var instances = this.refs.agGrid.gridOptions.api.getCellRendererInstances(params);
              })
        }

        textComparatorText2GridReady(params){
            this.setState({
                textComparatorText2Api: params.api,
                textComparatorText2ColumnApi: params.columnApi
              }, () => {
                this.state.textComparatorText2Api.setRowData(this.state.text2Data);
    
                //following CSS classes seem to get overridden below in PlacementRenderer by placementGridLine and rangeGridLine
                      this.state.textComparatorText2Api.setColumnDefs(
                               [{headerName: this.props.translate("addLines"), headerClass: 'row-thin-center', field: "namedSection", width: 1168, cellClass: "row-thin-small", cellRendererFramework: PlacementRenderer, cellRendererParams:
                                   {
                                    placementRowData:this.state.text2Data,
                                    extModifyPlacementRow: this.modifyTextComparatorText2Row,
                                    extAddPlacementRow: this.addTextComparatorText2Row,
                                    extDeletePlacementRow: this.deleteTextComparatorText2Row,
                                    translate: this.props.translate, //do I need to pass this here?
                                },
                          //cellClass: function(params) {return params.data.type ? 'placementGridLine' : 'rangeGridLine';},
                                }]
                          );
                      if (this.state.text2Data.length > 0)
                          this.state.textComparatorText2Api.setFocusedCell(0, "namedSection", null);
                    //   var instances = this.refs.agGrid.gridOptions.api.getCellRendererInstances(params);
              })
        }

        modifyTextComparatorText1Row(extState){
            var newWordCountData = this.state.textComparatorWordCountData;
            newWordCountData.termTotal1Accurate = false;
            this.updateTextComparatorWordCountData(newWordCountData);
            // this.setState({termTotal1Accurate: false, wordCountData: newWordCountData})
    
            var isAddRow = (extState.type && extState.val4 != this.state.text1Data.val4 && extState.val4 != 0) || (!extState.type && extState.val3 != this.state.text1Data.val3 && extState.val3 != 0 && extState.val6 != this.state.text1Data.val6 && extState.val6 != 0);
            var selected = this.state.textComparatorText1Api.getFocusedCell();
    
            var i = selected.rowIndex;
            var rowValues = {type: extState.type, val1: extState.val1, val2: extState.val2, val3: extState.val3, val4: extState.val4, val5: extState.val5, val6: extState.val6,
                                                  valList1: extState.valList1, valList2: extState.valList2, valList3: extState.valList3, valList4: extState.valList4, valList5: extState.valList5, valList6: extState.valList6 };
            var newData = this.state.text1Data;
            newData[i] = rowValues;
    
            this.setState( {text1Data: newData}, () => {
                if (i == this.state.textComparatorText1Api.getLastDisplayedRow() && isAddRow) {
                    this.addTextComparatorText1Row();
                }else{
                    this.setState({text1Data:[]},()=>{
                      this.state.textComparatorText1Api.updateRowData({ update: [rowValues] });
                      this.setState({ text1Data: newData })
                    })
                  }
            });
        }

        modifyTextComparatorText2Row(extState){
            var newWordCountData = this.state.textComparatorWordCountData;
            newWordCountData.termTotal2Accurate = false;
            this.updateTextComparatorWordCountData(newWordCountData);
            // this.setState({termTotal2Accurate: false, wordCountData: newWordCountData})
    
            var isAddRow = (extState.type && extState.val4 != this.state.text2Data.val4 && extState.val4 != 0) || (!extState.type && extState.val3 != this.state.text2Data.val3 && extState.val3 != 0 && extState.val6 != this.state.text2Data.val6 && extState.val6 != 0);
            var selected = this.state.textComparatorText2Api.getFocusedCell();
    
            var i = selected.rowIndex;
            var rowValues = {type: extState.type, val1: extState.val1, val2: extState.val2, val3: extState.val3, val4: extState.val4, val5: extState.val5, val6: extState.val6,
                                                  valList1: extState.valList1, valList2: extState.valList2, valList3: extState.valList3, valList4: extState.valList4, valList5: extState.valList5, valList6: extState.valList6 };
            var newData = this.state.text2Data;
            newData[i] = rowValues;
    
            this.setState( {text2Data: newData}, () => {
                if (i == this.state.textComparatorText2Api.getLastDisplayedRow() && isAddRow) {
                    this.addTextComparatorText2Row();
                }else{
                    this.setState({text2Data:[]},()=>{
                      this.state.textComparatorText2Api.updateRowData({ update: [rowValues] });
                      this.setState({ text2Data: newData })
                    })
                  }
            });
        }
        
        addTextComparatorText1Row(){
            var newRow = {type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []};
            var newData = this.state.text1Data;
            newData.push(newRow);
            this.setState({text1Data:[]},()=>{
                this.state.textComparatorText1Api.updateRowData({ add: [newRow] });
                this.setState({ text1Data: newData, rowData1Length:newData.length-1 })
              })
            // this.setState( {text1Data: newData}, () => {this.state.textComparatorText1Api.updateRowData({ add: [newRow] })});
        }

        addTextComparatorText2Row(){
            var newRow = {type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []};
            var newData = this.state.text2Data;
            newData.push(newRow);
            this.setState({text2Data:[]},()=>{
                this.state.textComparatorText2Api.updateRowData({ add: [newRow] });
                this.setState({ text2Data: newData, rowData2Length:newData.length-1 })
              })          
            // this.setState( {text2Data: newData}, () => {this.state.textComparatorText2Api.updateRowData({ add: [newRow] })});
        }

        deleteTextComparatorText1Row(extState){
            var newWordCountData = this.state.textComparatorWordCountData;
            newWordCountData.termTotal1Accurate = false;
            this.updateTextComparatorWordCountData(newWordCountData)

            var selected = this.state.textComparatorText1Api.getFocusedCell();
            var i = selected.rowIndex;
            if (i == this.state.textComparatorText1Api.getLastDisplayedRow()) { //then just clear
                this.modifyTextComparatorText1Row(extState);
                //selected.setSelected(false);
            }
            else {
                var selectedData = this.state.textComparatorText1Api.getSelectedRows();
                var newData = this.state.text1Data;
                newData.splice(i,1);
                this.setState( {text1Data: newData, rowData1Length:i}, () => {this.state.textComparatorText1Api.updateRowData({ remove: selectedData }) });
            }
        } 

        deleteTextComparatorText2Row(extState){
            var newWordCountData = this.state.textComparatorWordCountData;
            newWordCountData.termTotal2Accurate = false;
            this.updateTextComparatorWordCountData(newWordCountData)

            var selected = this.state.textComparatorText2Api.getFocusedCell();
            var i = selected.rowIndex;
            if (i == this.state.textComparatorText2Api.getLastDisplayedRow()) { //then just clear
                this.modifyTextComparatorText2Row(extState);
                //selected.setSelected(false);
            }
            else {
                var selectedData = this.state.textComparatorText2Api.getSelectedRows();
                var newData = this.state.text2Data;
                newData.splice(i,1);
                this.setState( {text2Data: newData, rowData2Length:i}, () => {this.state.textComparatorText2Api.updateRowData({ remove: selectedData }) });
            }
        } 

        async fillTextComparator(contextType, pasuk1Data, pasuk2Data){
            this.setState({isFillingComparatorSelector: true}, async () => {
                window.location.href = this.baseURL+"/#/textComparator";

                var textData = {};
                var bounds1 = [];
                var bounds2 = [];
                if(contextType === "20 pesukim"){
                    var bounds1 = await this.getContextBounds(pasuk1Data.data.location)
                    var bounds2 = await this.getContextBounds(pasuk2Data.data.location)

                } else if (contextType === "parsha"){
                    var bounds1 = await this.getParshaBounds(pasuk1Data.data.parshaId);
                    var bounds2 = await this.getParshaBounds(pasuk2Data.data.parshaId);
                }

                textData = {
                    text1: {
                        start: bounds1[0],
                        end: bounds1[1],
                    },
                    text2: {
                        start: bounds2[0],
                        end: bounds2[1],
                    }
                }

                var text1Data = [await this.getRangeSelectorData(textData.text1)]
                var text2Data = [await this.getRangeSelectorData(textData.text2)]

                var emptyRow = { // last line of placement selector is always empty. mimicking that here to avoid bugs
                    type: true,
                    val1: 0,
                    val2: 0,
                    val3: 0,
                    val4: 0,
                    val5: 0,
                    val6: 0,
                    valList1: [],
                    valList2: [],
                    valList3: [],
                    valList4: [],
                    valList5: [],
                    valList6: []
                }

                text1Data.push(emptyRow)
                text2Data.push(emptyRow)
    
                this.setState({
                    text1Data: text1Data,
                    text2Data: text2Data,
                }, () => {
                    this.updateTextComparatorWordCountData({
                        totalTerms1: 0,
                        totalTerms2: 0,
                        termTotal1Accurate: false,
                        termTotal2Accurate: false,
                    })

                    this.setState({
                        isFillingComparatorSelector: false
                    })
                })
            });
        }

        async getContextBounds(location){
            var url = properties.env.webServiceURL + '/BaseHaSefer/getPasukContext?source=bhsWebApp'
            url += '&location=' + location;
            url += '&seferId=0';
            url += '&totalPesukim=21';
            var response = await fetch(url);
            const body = await response.json();
            const bounds = [body[0], body[body.length-1]]
            return bounds;
        }

        async getParshaBounds(parshaId){
            const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getParsha?id=' + parshaId);
            var body = await response.json();
            const bounds = [body[0], body[body.length-1]]
            return bounds;
        }

        async getRangeSelectorData(textBounds){
            var startLocation = textBounds.start.location;
            var endLocation = textBounds.end.location;

            var startLocationArr = startLocation.split(":")
            var endLocationArr = endLocation.split(":")
  
            const seferPerekInfo = await this.getSeferPerekSelectorInfo();
            const startSeferIndex = seferPerekInfo.findIndex((sefer) => {
                return sefer.seferName === startLocationArr[0];
            })
            const endSeferIndex = seferPerekInfo.findIndex((sefer) => {
                return sefer.seferName === endLocationArr[0];
            })

            const startSeferVal = seferPerekInfo[startSeferIndex].seferId;
            const endSeferVal = seferPerekInfo[endSeferIndex].seferId;

            const startPerekInfo = await this.getPerekInfo(startSeferVal);
            const endPerekInfo = await this.getPerekInfo(endSeferVal);

            const startPerekIndex = startPerekInfo.findIndex((perek) => {
                return perek.perekValue === startLocationArr[1];
            });

            const endPerekIndex = endPerekInfo.findIndex((perek) => {
                return perek.perekValue === endLocationArr[1];
            });

            const startPerekVal = startPerekInfo[startPerekIndex].perekNum;
            const endPerekVal = endPerekInfo[endPerekIndex].perekNum;

            const startPasukInfo = await this.getPasukInfo(startPerekVal, startPerekInfo);
            const endPasukInfo = await this.getPasukInfo(endPerekVal, endPerekInfo);

            const startPasukIndex = startPasukInfo.findIndex((pasuk) => {
                return pasuk.pasukValue === startLocationArr[2];
            });
            const endPasukIndex = endPasukInfo.findIndex((pasuk) => {
                return pasuk.pasukValue === endLocationArr[2];
            });

            const startPasukVal = startPasukInfo[startPasukIndex].pasukNum;
            const endPasukVal = endPasukInfo[endPasukIndex].pasukNum;

            const selectorData = {
                type: false,
                val1: startSeferVal,
                val2: startPerekVal,
                val3: startPasukVal,
                val4: endSeferVal,
                val5: endPerekVal,
                val6: endPasukVal,
                valList1: seferPerekInfo,
                valList2: startPerekInfo,
                valList3: startPasukInfo,
                valList4: seferPerekInfo,
                valList5: endPerekInfo,
                valList6: endPasukInfo
            }
            return selectorData
        }

        async getSeferPerekSelectorInfo(){
            const url = properties.env.webServiceURL + "/BaseHaSefer/getSeferPerekInfo?source=bhsWebApp";
            const response = await fetch(url);
            const seferPerekInfo = await response.json();
            return seferPerekInfo
        }

        async getPerekInfo(seferId){
            const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPerekInfo?source=bhsWebApp&seferId='+seferId);
            const body = await response.json();
            const perakim = body.map(function(e) {return {perekNum: e.perek, perekValue: util.gematriya(e.perek, {punctuate: false}), pesukim: e.pesukim }});
            return perakim
        }

        getPasukInfo(perekNum, perekList){
            const perek = perekList.find(function(e){return e.perekNum == perekNum;});
            const body = Array(perek.pesukim).fill().map((x,i)=>({pasukNum: i+1, pasukValue: util.gematriya(i+1, {punctuate: false}) }));
            return body;
        }

        setExactnessLevel(e){
            this.setState({ssExactnessLevel: e});
            }

        setAboutTabIndex(i) {
            this.setState({aboutTabIndex: i, forceOpenAboutDialog: this.state.forceOpenAboutDialog == 0 ? 1 : 0}); //force change in state here so AppNavBar's componentDidUpdate() will fire even if aboutTabIndex didn't change
        }

        colorWord(word,letterWordTypes,color){
            letterWordTypes = letterWordTypes.split(",")
            var j = letterWordTypes.length-1;
            var coloredWord = "";
            var splitWord = word.split("");//array of each character including nikud and taam
            var cleanedText = this.removeNikudAndTaamim(word).split("");//splits the word into array of just letters
            for(let i=splitWord.length-1; i>=0; i--){
                if(letterWordTypes[j]!=4){//if the letter is not part of the shoresh
                    coloredWord = "<span class='red'>"+splitWord[i]+"</span>"+coloredWord;
                }else{
                    coloredWord = "<span class='"+color+"'>"+splitWord[i]+"</span>"+coloredWord;
                }
                if( splitWord[i]==cleanedText[j] ){
                    j--;
                }
            }
            coloredWord = "<span>"+coloredWord+"</span>"
            return coloredWord
        }
        setUpShoresh(wordData, tooltip){
            var shoresh;
            if(tooltip){
                shoresh = wordData.shoresh?"<b>"+wordData.shoresh+"</b>":this.props.translate("notAvailable")
            }else{
                shoresh = wordData.shoresh?wordData.shoresh:""
            }
            return shoresh;
        }
        setUpAppearancesShoresh(wordData,tooltip){
            if(tooltip){
                var byAppearance = this.props.translate("appearances");
                var appearancesShoresh = byAppearance+": <b>"+wordData.appearancesShoresh+"</b>"
                if(wordData.appearancesShoresh) return appearancesShoresh;
                else return "";
            } else{
                const appearancesShoresh = wordData.appearancesShoresh?wordData.appearancesShoresh:""
                return appearancesShoresh;
            }
        }
        setUpTaam(word){
            const mukafPhrase = word.mukafPhrase?"&nbsp;<b>"+word.mukafPhrase+"</b>":""
            const taamType = word.taamType?"&nbsp;<span class='smallerFont'>("+word.taamType+")</span>":""
            const taam = word.taam?"<b>"+word.taam+"</b>":this.props.translate("notAvailable")
            var string = taam+taamType+mukafPhrase
            return string;
        }
        setUpTargum(word){
            const targumPhrase = word.targumPhrase === word.word?"":word.targumPhrase?"&emsp;<b>("+word.targumPhrase+")</b>":"";
            var targum = word.targum?"<b>"+word.targum+"</b>":this.props.translate("notAvailable")
            var string = targum+targumPhrase
            return string;
        }
        setUpWordType(wordData){
            var verbArray = []
            var gender = ""
            var genderSuffix = ""
            var suffixArray = [];
            var suffix = this.props.translate("suffix")
            if(wordData.wordType_EN=='Verb'){
                if(wordData.gender=="M")gender = "זכר"
                else gender = "נקבה"
                if(wordData.binyan) verbArray.push(wordData.binyan)
                if(wordData.zman) verbArray.push(wordData.zman)
                if(wordData.mispar) verbArray.push(wordData.mispar)
                if(wordData.guf) verbArray.push(wordData.guf)
                if(wordData.gender) verbArray.push(gender)

                if(wordData.hasSuffix=='T'){
                    if(wordData.suffixMispar) suffixArray.push(wordData.suffixMispar);
                    if(wordData.suffixGuf) suffixArray.push(wordData.suffixGuf);

                    if(wordData.gender=="M")genderSuffix = "זכר"
                    else genderSuffix = "נקבה"
                    if(wordData.suffixGender) suffixArray.push(genderSuffix);
                }
            }
            const verb = verbArray.length>0?suffixArray.length>0?"&nbsp;("+verbArray.join("\\")+"&nbsp;"+suffix+":"+suffixArray.join("\\")+")":"&nbsp;("+verbArray.join("\\")+")":"";
            const wordType = wordData.wordType?"<b>"+wordData.wordType+"</b>":this.props.translate("notAvailable")
            var string=wordType+"<span class='littleSmaller'>"+verb+"</span>"
            return string;
        }
        setUpMisc(wordData,tooltip){
            var changeArray = []
            if(wordData.vavHaHipuchFlag) changeArray.push("ו ההיפוך")
            if(wordData.hehHaSheelaFlag) changeArray.push("ה השאלה")

            var changes = ""
            if(tooltip){
                changes = changeArray.length>0?"<td width='51%'>"+this.props.translate("misc")+": "+changeArray.join(', ')+"</td>":""
            }else{
                changes = changeArray.length>0?changeArray.join(', '):""
            }
            return changes;
        }
        setCoordinatesWordTooltip(e){
            const gematria = this.props.translate("gematria");
            const letters = this.props.translate("letters");
            const nekudos = this.props.translate("nekudos");
            const unique = this.props.translate("unique");
            const tenua = this.props.translate("tenuos");
            const taam = this.props.translate("taam");
            const atbash = this.props.translate("atbash");
            const letterAppearance = this.props.translate("letterAppearance");
            const wordTypes = this.props.translate("wordTypes");
            const shoresh = this.props.translate("root");
            const byAppearance = this.props.translate("appearances");
            const katan = this.props.translate("katan");
            const ketana = this.props.translate("ketana");
            const targum = this.props.translate("targum");
            const pasukPosition = this.props.translate("pasukPosition");
            const stam = this.props.translate("stam");
            const ivri = this.props.translate("ivri");
            const inflection = this.props.translate("inflection");
        
            var elements = document.getElementsByClassName('wordHover');//this part is to make sure that if mouse is not over a word, the tooltip should not show at all
            var overWord=false;                                         //even if the mouseout didn't fire properly.
            if (elements.length>0){
                for(let i=0;i<elements.length;i++){
                    if(window.getComputedStyle(elements[i]).getPropertyValue('border-top-style')==='hidden'){
                        overWord = true
                        break;
                    }
                }
            }
            if(overWord){       
                var tooltip = document.getElementById("wordTooltip")
                var wordData = null;
                if(properties.hoverWords == "perWordStat" ){
                    wordData = properties.wordStats
                }

                if(wordData){
                    tooltip.className="wordStatTooltip";
                    const topRow = "<table class='ltrWordStat'><tr><td width='23%'>"+ivri+": <b><span class='hebrewPaleoGlezer'>"+this.removeNikudAndTaamim(wordData.ln)+"</span></b></td><td width='23%'>"+stam+": <span class='stamAshkenazCLM'>"+this.removeNikudAndTaamim(wordData.ln)+"</span></td><td width='17%'><b class='theWord'>"+this.colorWord(wordData.word,wordData.letterWordTypes,"white")+"</b></td><td width='17%'>"+pasukPosition+": <b>"+wordData.wordNumber+"</b></td><td width='19%'><span class='wordStatsTooltipLocation'>"+wordData.location+"</span></td><td width='1%'></td></tr></table>"
                    const secondRow = "<table class='ltrWordStat'><tr><td width='48%'>"+letterAppearance+": <b>"+wordData.sortedLetters+"</b>&emsp;<span class='smallerFont'>("+unique+": <b>"+ String.prototype.concat(...new Set(wordData.sortedLetters))+"</b>)</span></td><td width='29%'>"+byAppearance+": <b>"+wordData.appearancesLN+"</b></td><td width='22%'>"+inflection+": <b>"+wordData.ln+"</b></td><td width='1%'></td></tr>"
                    const thirdRow = "<tr><td width='48%'>"+letters+": <b>"+wordData.letters+"</b></td><td width='29%'>"+this.setUpAppearancesShoresh(wordData,true)+"</td><td width='22%'>"+shoresh+": "+this.setUpShoresh(wordData, true)+"</td><td width='1%'></td></tr></table>"
                    const fourthRow = "<tr><td width='48%'>"+nekudos+": <b>"+wordData.nekudos+"</b></td><td></td><td width='51%'>"+targum+": "+this.setUpTargum(wordData)/*<b>"+wordData.targum+"</b>"+targumPhrase*/+"</td><td width='1%'></td></tr>"
                    const fifthRow = "<tr><td width='48%'>"+tenua+": <b>"+wordData.tenuos+"</b></td><td></td><td width='51%'>"+taam+": "+this.setUpTaam(wordData)+/*<b>"+wordData.taam+"</b>"+taamType+mukafPhrase+*/"</td><td width='1%'></td></tr>"
                    const sixthRow = "<tr><td width='48%'>"+gematria+": <b>"+wordData.gematria+"</b>&nbsp;<span class='smallerFont'>("+ketana+": <b>"+wordData.katan+"</b>)</span></td><td></td><td width='51%'>"+wordTypes+": "+this.setUpWordType(wordData)+/*<b>"+wordData.wordType+"</b>"+verb+*/"</td><td width='1%'></td></tr>"
                    const seventhRow = "<tr><td width='48%'>"+atbash+": <b>"+wordData.atbash+"</b>&nbsp;<span class='smallerFont'>("+katan+": <b>"+wordData.atbashKatan+"</b>)</span></td><td></td>"+this.setUpMisc(wordData,true)+"<td width='1%'></td></tr>"
                    
                    tooltip.innerHTML=topRow+secondRow+thirdRow+"<table class='ltrWordStat'>"+fourthRow+fifthRow+sixthRow+seventhRow+"</table>"
                }
                else{
                    tooltip.className="pasukStatsTooltipBlank"
                    tooltip.innerHTML=""
                }
                var x = e.clientX;
                var y = e.clientY;
                document.getElementById("wordTooltip").style.left = x-350+"px"
                document.getElementById('wordTooltip').style.top  = y+10+"px";
                document.getElementById('wordTooltip').style.zIndex = 100000090;
            }
        }
    
        setWordTooltipOff(){
            properties.setWordStat(null);
            document.getElementById("wordTooltip").className="pasukStatsTooltipBlank"
            document.getElementById("wordTooltip").innerHTML=""
        }
        openSearchResultsHeight(){
            if(parseInt(this.state.searchResultsHeight.substring(0,this.state.searchResultsHeight.length -2))<430){
                this.setState({searchResultsHeight:'430px'})
            }
        }

        // openContextMenu(event){
        //     var x = event.clientX+10;
        //     var y = event.clientY+10;
        //     this.setState({xPos:x,yPos:y},()=>{
        //       this.setState({showContextMenu:true})
        //     })
        // }
      
      
        // setContextMenuOff(){
        //   this.setState({showContextMenu:false})
        // }
        setTextSource(val){
            var textSourceSearched = this.state.textSourceSearched
            textSourceSearched[this.state.searchResultsTabValue] = val
            this.setState({textSource:val,textSourceSearched})
        } 
      

        
  render() {
    const isHebrew = this.props.activeLanguage && this.props.activeLanguage.code === 'he';
    const {item} = this.state;
    const {rowData, isLoading} = this.state;
    const word = this.props.translate("word");
//    var searchResultsHeightNum = parseInt(this.state.searchResultsHeight.substring(0, this.state.searchResultsHeight.length - 2))

    var minResultsPaneHeight = this.state.searchResultsOpen ? '243px' : '0px';
    var viewerPaneInitialSize = this.state.viewerHeight;

    var resultsDragIconDisplay = this.state.openResults.length > 0 ? "block" : "none"

    var directionStyling = {}
    if(this.props.activeLanguage && this.props.activeLanguage.code === "he"){
        directionStyling = {direction: 'rtl', textAlign: 'right'}
    }
    var viewerHelp= "";

    var splitPaneContainerHeight = 130 + //simple search height
        this.state.advancedSearchHeight +
        parseInt(this.state.searchResultsHeight.substring(0, this.state.searchResultsHeight.length - 2)) +
        parseInt(this.state.viewerHeight.substring(0, this.state.viewerHeight.length - 2)) +
        100 // buffer to allow dragging on last pane



    //let language = sessionStorage.getItem("language"); //can also use localStorage for this
    //this.state.defaultLanguage = language;
    //this.setLanguage(language);

    let viewer = "";

    if (this.state.viewer == 'ParshaViewer') {
      viewer = <ParshaViewer align="right" extCloseDockedViewer={this.closeDockedViewer} extFilterData={this.state.extFilterData} viewerHeight={this.state.viewerHeight}
                        extCopySelectedToSearchbar={this.copySelectedToSearchbar} extSearchWithSelected={this.searchWithSelected}
                        extFetchTaamParseData={this.fetchTaamParseData} openResults={this.state.openResults} extOpenTaamParser={this.openTaamParser} 
                        extCloseTaamParser={this.closeTaamParser} extSetTextSource={(val)=>this.setState({textSource:val})} 
                        extOpenLexiconViewer={this.openLexiconViewer} extSearchDefData={this.state.extSearchDefData}
                        toggleObjectCloudViewer={((boolean,parshaId) => {this.toggleObjectCloudViewer(boolean,parshaId)})}
                        isObjectCloudViewerOpen={this.state.objectCloudViewerOpen} handleCopyLink={this.handleCopyLink} setHelpTopic={this.setHelpTopic}/>;
      viewerHelp = this.props.translate("parshaViewerHelp");
    } else if (this.state.viewer == 'FrequencyViewer') {
      viewer = <FrequencyViewer align="right" extCloseDockedViewer={this.closeDockedViewer} extSearchDefData={this.state.extSearchDefData} extFilterData={this.state.extFilterData}
                        extOpenTaamParser={this.openTaamParser} extCloseTaamParser={this.closeTaamParser}
                        extCopySelectedToSearchbar={this.copySelectedToSearchbar} extSearchWithSelected={this.searchWithSelected}
                        extFetchTaamParseData={this.fetchTaamParseData} viewerHeight={this.state.viewerHeight} openResults={this.state.openResults}
                        toggleTanachViewer={((boolean, pasukId, resultsApi) => {this.toggleTanachViewer(boolean, pasukId, resultsApi)})} 
                        extSetTextSource={(val)=>this.setState({textSource:val})} extOpenLexiconViewer={this.openLexiconViewer}
                        extSetBeta={this.setBeta} textSource={this.state.textSource} handleCopyLink={this.handleCopyLink} setHelpTopic={this.setHelpTopic}/>;
      viewerHelp = this.props.translate("frequencyViewerHelp");
    } else if (this.state.viewer == 'TextComparatorViewer') {
      viewer = <TextComparatorViewer align="right" extCloseDockedViewer={this.closeDockedViewer} extFilterData={this.state.extFilterData}
                    text1Data={this.state.text1Data} text2Data={this.state.text2Data} extSetTextSource={(val)=>this.setState({textSource:val})}
                    extOpenTaamParser={this.openTaamParser}  extCloseTaamParser={this.closeTaamParser}
                    extSearchDefData={this.state.extSearchDefData} extCopySelectedToSearchbar={this.copySelectedToSearchbar}
                    extSearchWithSelected={this.searchWithSelected} viewerHeight={this.state.viewerHeight} openResults={this.state.openResults} 
                    extFetchTaamParseData={this.fetchTaamParseData} isFillingSelector={this.state.isFillingComparatorSelector}
                    toggleTanachViewer={((boolean, pasukId, resultsApi) => {this.toggleTanachViewer(boolean, pasukId, resultsApi)})}
                    wordCountData={this.state.textComparatorWordCountData} updateWordCountData={this.updateTextComparatorWordCountData}
                    text1GridReady={this.textComparatorText1GridReady} text1Api={this.state.textComparatorText1Api} modifyText1Row={this.modifyTextComparatorText1Row}
                    addText1Row={this.addTextComparatorText1Row} deleteText1Row={this.deleteTextComparatorText1Row} textSource={this.state.textSource}
                    text2GridReady={this.textComparatorText2GridReady} text2Api={this.state.textComparatorText2Api} modifyText2Row={this.modifyTextComparatorText2Row}
                    addText2Row={this.addTextComparatorText2Row} deleteText2Row={this.deleteTextComparatorText2Row} extOpenLexiconViewer={this.openLexiconViewer}
                    handleCopyLink={this.handleCopyLink} setHelpTopic={this.setHelpTopic} extSetBeta={this.setBeta}
                    />;
      viewerHelp = this.props.translate("textComparatorViewerHelp");
    } else {//if the page is landingPage
        viewer = <StartMenu extOpenDockedViewer={this.openDockedViewer} viewerHeight={this.state.viewerHeight}
                        premiumServicesDialogOpen={this.state.premiumServicesDialogOpen}
                        toggleTanachViewer={((boolean, pasukId) => {this.toggleTanachViewer(boolean, pasukId)})}
                        toggleYuchsinTreeViewer={((boolean) => {this.toggleYuchsinTreeViewer(boolean)})}
                        toggleObjectCloudViewer={((boolean) => {this.toggleObjectCloudViewer(boolean,0)})}
                        togglePremiumServices={this.togglePremiumServices}
                        extOpenTaamParser={this.openTaamParser} activeLanguage={this.props.activeLanguage}
                        toggleLexicon={((boolean)=>{this.toggleLexicon(boolean)})}
                        handleCopyLink={this.handleCopyLink}
                        ></StartMenu>
        
    }
    //const noRows = '<span style="font-size:28px; color: dodgerblue;"><i>' + this.props.translate("asdf") + '</i></span>';
    //const loading = this.props.translate("loading");

//orig side widths = 9.2%

//todo - Something deeper OR something missing OR different framework
    var ReactGridLayout = require('react-grid-layout'); //static: true
    var layout = [
      {i: 'a', x: 0, y: 0, w: 1, h: 1, rowHeight: 400},
      {i: 'b', x: 0, y: 1, w: 1, h: 1, rowHeight: 400},
      {i: 'c', x: 0, y: 2, w: 1, h: 1, rowHeight: 400}
    ];

/*
search line - static

adv search window - close button - fixed size - when opens, moves other windows dowm

results window(s) - close button - adjust size -  - when opens, moves other windows dowm - potential to add multi windows and compact size as % of width

page content window - can be slidden downward by sliding adv search or results
*/

    return (
    <div id='home-scroll-container' style={{height: '100vh', width: '100%', direction: 'rtl', overflowX: 'auto'}}><div id='home-component' style={{direction: 'ltr'}}
        onClick={(e) => {this.setHelpStartupTopic(e)}}
    >

        <AppNavBar checkAuthentication={this.props.checkAuthentication} login={this.props.login} authenticated={this.props.authenticated} userinfo={this.props.userinfo}
            logout={this.props.logout} extOpenTaamParser={this.openTaamParser} profile={this.props.profile} userRole={this.props.userRole}
            toggleTanachViewer={((boolean, pasukId) => {this.toggleTanachViewer(boolean, pasukId)})} serverMessage={this.state.serverMessage}
            toggleObjectCloudViewer={((boolean) => {this.toggleObjectCloudViewer(boolean,0)})}
            helpDialogStartupTopic={this.state.helpStartupTopic} toggleYuchsinTreeViewer={((boolean) => {this.toggleYuchsinTreeViewer(boolean)})}
            aboutTabIndex={this.state.aboutTabIndex} extSetAboutTabIndex={this.setAboutTabIndex} isForceOpenAboutDialog={this.state.isForceOpenAboutDialog}
            toggleLexicon={((boolean) => {this.toggleLexicon(boolean)})} beta={this.state.beta}
            aboutDialogOpen={this.state.aboutDialogOpen} toggleAboutDialog={this.toggleAboutDialog} aboutOpen={this.props.aboutOpen}
            helpDialogOpen={this.state.helpDialogOpen} toggleHelpDialog={this.toggleHelpDialog} handleCopyLink={this.handleCopyLink}
            isFirstTimeOpened={this.state.isFirstTimeOpened} extSetFirstTimeOpened={()=>this.setState({isFirstTimeOpened:false})}
            initialFullScreen={this.props.initialFullScreen} isFullScreen={this.isFullScreen}
            extOpenDockedViewer={this.openDockedViewer}
        />
        <table width="100%">
        <tbody>
        <tr>
        <td width="9.2%" className = "leftbar-header">
            <LeftBar updateLeftBar={this.state.updateLeftBar} leftBarText={viewerHelp} superTreeData={this.state.superTreeData} inLeftBar={(passed)=>{this.setState({inLeftBar:passed})}} 
            resetSuperTreeData={this.resetSuperTreeData} searchCriteria={this.state.dataForTree} placementRowData={this.state.placementRowData} filterData={this.state.filterData}/>
        </td>
        <td width="81.6%" className="main-body">

        <Container fluid id="paneContainer" style={{ padding: '5px', margin: '5px', maxHeight: `${splitPaneContainerHeight}px`, overflowY: 'hidden' }}>
      <SplitPane id="panes" split="horizontal" allowResize={true} className="main-body" primary="second" onChange={(e) => {this.onPaneChange(e)}} >
          <div allowResize={false} initialSize="130px" minSize="130px" maxSize="130px" className="searchLine"  align="center" helpTopic="simpleSearch">
              <SimpleSearch extSearchDefData={this.state.extSearchDefData} setSearchIsLoading={this.setSearchIsLoading}
                             extOpenAdvancedSearch={this.extOpenAdvancedSearch} extCloseAdvancedSearch={this.extCloseAdvancedSearch}
                             extOpenSearchResults={this.extOpenSearchResults} extFilterData={this.state.extFilterData}
                             extUpdateBituim={this.extUpdateBituim} extDelayUpdateBituim={this.extDelayUpdateBituim}
                             extSubmitSearch={this.submitSearch} resultsReceived={this.state.resultsReceived} extSetBeta={this.setBeta}
                             extSetTerms={this.setTerms} terms={this.state.terms} textSource={this.state.textSource}
                             extSetScope={this.setScope} scope={this.state.scope} extSetTextSource={(val)=>this.setState({textSource:val})}
                             extSetSeq={this.setSeq} seq={this.state.seq} extLoadSearchResults={this.loadSearchResults}
                             extGetCurrentPasukIds={this.getCurrentPasukIds} searchWithinResults={this.state.searchWithinResults} hasInvalidQuote={this.state.hasInvalidQuote}
                             closeLexiconKb={()=>this.setState({lexiconKeyboard:!this.state.lexiconKeyboard})} simpleSearchKb={this.state.simpleSearchKb}/>
          </div>
          <div id="advancedSearch" allowResize={false} visible={false} initialSize={`${this.state.advancedSearchHeight}px`} minSize={`${this.state.advancedSearchHeight}px`}
                maxSize={`${this.state.advancedSearchHeight}px`} align="center" className="invisible" >
              <AdvancedSearch extBituimText={this.state.extBituimText} rowData={this.state.bituim} searchParams={this.state.searchParams} bituiSettingsList={this.state.bituiSettingsList}
                              dualLetterPicker={this.state.dualLetterPicker} dualNekudaPicker={this.state.dualNekudaPicker} dualTaamPicker={this.state.dualTaamPicker}
                              placementRowData={this.state.placementRowData} termDistances={this.state.termDistances} defaultBituiSettings={new BituiSettings()}
                              extResetPlacementState={this.resetPlacementState} extResetIncludeExcludeState={this.resetIncludeExcludeState}
                              extResetBituiNikudTaamimState={this.resetBituiNikudTaamimState} extResetTermContainerDistances={this.resetTermContainerDistances}
                              extResetTermPairDistances={this.resetTermPairDistances} extSetAdvancedSearchTabValue={this.setAdvancedSearchTabValue}
                              extResetPartOfSpeechState={this.resetPartOfSpeechState}
                              tabValue={this.state.advancedSearchTabValue} extRenderLetterNikkud={this.renderLetterNikkud} extRenderGeneralNikkud={this.renderGeneralNikkud}
                              extRenderGeneralHataama={this.renderGeneralHataama} renderAdvancedSearch={this.state.renderAdvancedSearch}
                              extResetAllAdvancedSearchSettings={this.resetAllAdvancedSearchSettings} extPassGridApiToHome={this.passGridApiToHome}
                              extGetSearchSettings={this.getSearchSettings} extLoadSearchParams={this.loadSearchParams}
                              extHandleCloseInvalidFileDialog={this.handleCloseInvalidFileDialog}
                              invalidFileDialogOpen={this.state.invalidFileDialogOpen} extSetContainerDistances={this.setContainerDistances}
                              extSetContainerMadreigos={this.setContainerMadreigos} extSetTermDistances={this.setTermDistances} extSetPartOfSpeechSliders={this.setPartOfSpeechSliders} />
          </div>
          <div onMouseEnter={this.setWordTooltipOff} onMouseMove={this.setCoordinatesWordTooltip}onMouseOut={this.setWordTooltipOff} id="searchResults" allowResize={false} visible={false} initialSize={this.state.searchResultsHeight} height={minResultsPaneHeight}
                minSize={minResultsPaneHeight} align="center" className="invisible" >
              <span >
                  <SearchResults setCoordinatesWordTooltip={this.setCoordinatesWordTooltip} rowData={this.state.rowData} searchIsLoading={this.state.searchIsLoading} posTop={this.state.advancedSearchHeight} align="right"
                                    searchResultsHeight={this.state.searchResultsHeight} openSearchResultsHeight={this.openSearchResultsHeight} lastSearchTerms={this.state.lastSearchTerms}
                                    seq={this.state.seq} ssExactnessLevel={this.state.ssExactnessLevel} extSearchDefData={this.state.extSearchDefData}
                                    openResults={this.state.openResults} extUpdateOpenResults={this.extUpdateOpenResults} rowDataForTotalTooltip={this.state.rowDataForTotalTooltip}
                                    extOpenTaamParser={this.openTaamParser} extCloseTaamParser={this.closeTaamParser} sortedColumn={this.state.sortedColumn}
                                    extUpdateSearchResultsPerPage={this.extUpdateSearchResultsPerPage} extLoadSearchResults={this.loadSearchResults}
                                    extCloseResultsTab={this.closeResultsTab} extHandleSearchResultsTabChange={this.handleSearchResultsTabChange}
                                    tabValue={this.state.searchResultsTabValue} extDeleteSelectedResults={this.deleteSelectedResults}
                                    extToggleKeepResultsOpen={this.toggleKeepResultsOpen} keepResultsOpen={this.state.keepResultsOpen}
                                    extToggleSearchWithinResults={this.toggleSearchWithinResults} searchWithinResults={this.state.searchWithinResults}
                                    extCopySelectedToSearchbar={this.copySelectedToSearchbar} extSearchWithSelected={this.searchWithSelected}
                                   extFetchTaamParseData={this.fetchTaamParseData} fillTextComparator={this.fillTextComparator}
                                   toggleTanachViewer={((boolean, pasukId, resultsApi,columnClicked) => {this.toggleTanachViewer(boolean, pasukId, resultsApi,columnClicked)})}
                                   colorWord={this.colorWord} setUpTaam={this.setUpTaam} setUpTargum={this.setUpTargum} setUpWordType={this.setUpWordType}
                                   setUpMisc={this.setUpMisc} setUpShoresh={this.setUpShoresh} setUpAppearancesShoresh={this.setUpAppearancesShoresh}
                                   newResults={this.state.updateLeftBar} deletedResults={this.state.deletedResults} textSource={this.state.textSource}
                                   extSetTextSource={(val)=>this.setState({textSource:val})} textSourceSearched={this.state.textSourceSearched}
                                   extOpenLexiconViewer={this.openLexiconViewer} searchResultsOpen={this.state.searchResultsOpen}
                                   extSetBeta={this.setBeta} extSubmitSearch={this.submitSearch} extOpenSearchResults={this.extOpenSearchResults}
                                   setSearchIsLoading={this.setSearchIsLoading} includeOtherStats={this.state.includeOtherStats} toggleIncludeOtherStats={this.toggleIncludeOtherStats}
                                   resetContentDisplayed={()=>this.setState({sentContentDisplayed:null})} sentContentDisplayed={this.state.sentContentDisplayed}
                                   isInitializedPasukFetched={this.state.isInitializedPasukFetched} extSetInitializedPasukFetched={()=>this.setState({isInitializedPasukFetched:false})}
                                   changedStatsArray={this.state.changedStatsArray}changedStatsArrayBool={this.state.changedStatsArrayBool} searchParams={this.state.dataForTree}
                                   placementSettings={this.state.searchParams.placementSettings}
                                   handleCloseSearchResultsDialogOpen={this.handleCloseSearchResultsDialogOpen} handleCopyLink={this.handleCopyLink}
                                   /*added this here so can get new results so can add correct columns*//>
              </span>
              <div id="wordTooltip" className="pasukStatsTooltipBlank"style={{ position:"absolute"}}></div>

          </div>
          <div id="viewer" allowResize={false} initialSize={this.state.viewerHeight} className="searchLine" align="center">
              <UnfoldMoreIcon style={{marginTop: "-12px", display: resultsDragIconDisplay, color: "grey"}} />
              {viewer}
          </div>
          <div id="empty-div-for-dragging" allowResize={true} initialSize="1000px" className="searchLine" align="center">
            <UnfoldMoreIcon style={{marginTop: "-12px", color: "grey"}} />

          </div>
      </SplitPane>

        </Container>
        </td>
        <td width="9.2%" className = "rightbar-header" helpTopic="rightBar">
          <RightBar searchDefCallback={this.extSearchDefCallback} filterCallback={this.extFilterCallback}
                        setHomeState={this.setState} extSetBeta={this.setBeta}
                        exactnessLevel={this.setExactnessLevel}
                        ssExactnessLevel={this.state.ssExactnessLevel}
                        sliderValue={this.state.ssSliderValue} sliderValueSet={this.sliderValueSet}
                        extSetFilterData={this.setFilterData} filterData={this.state.filterData}
                        extSetLetters={this.setLetters} letters={this.state.letters}
                        extSetNekudos={this.setNekudos} nekudos={this.state.nekudos}
                        extSetTaamim={this.setTaamim} taamim={this.state.taamim}
                        extSetGematria={this.setGematria} gematria={this.state.gematria}
                        extSetSortDirection={this.setSortDirection} sortDirection={this.state.sortDirection}
                        extSetSortOrder={this.setSortOrder} sortOrder={this.state.sortOrder} />
        </td>
        </tr>
        </tbody>
        </table>

        <Dialog
          open={this.state.clearRBChangesFromLexicon}
          onClose={()=>this.setState({clearRBChangesFromLexicon:false})}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={directionStyling}
        >
          <DialogTitle id="alert-dialog-title">{<Translate id="clearRBChangesFromLexiconPopupTitle" />}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Translate id="clearRBChangesFromLexiconContent" />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>this.setState({clearRBChangesFromLexicon:false})} color="primary">
              <Translate id="keepChanges" />
            </Button>
            <Button onClick={this.handleClearRBChangesFromLexicon} color="primary">
              <Translate id="dropChanges" />
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.clearSourceChangesFromLexicon}
          onClose={()=>this.setState({clearSourceChangesFromLexicon:false})}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={directionStyling}
        >
          <DialogTitle id="alert-dialog-title">{<Translate id="clearSourceChangesFromLexiconPopupTitle" />}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Translate id="clearSourceChangesFromLexiconContent" />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>this.setState({clearSourceChangesFromLexicon:false})} color="primary">
              <Translate id="keepChanges" />
            </Button>
            <Button onClick={this.handleClearSourceChangesFromLexicon} color="primary">
              <Translate id="dropChanges" />
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.closeSearchResultsDialogOpen}
          onClose={this.handleCloseSearchResultsDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={directionStyling}
        >
          <DialogTitle id="alert-dialog-title">{<Translate id="clearSearchResultsPopupTitle" />}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Translate id="clearSearchResultsPopupContent" />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseSearchResultsDialogClose} color="primary">
              <Translate id="cancel.caption" />
            </Button>
            <Button onClick={this.closeSearchResults} color="primary">
              <Translate id="continue.caption" />
            </Button>
          </DialogActions>
        </Dialog>
        {/* {this.state.showContextMenu ? <div style={{top:this.state.yPos,left:this.state.xPos,position:'absolute'}}><TaamParserContextMenu closeContextMenu={this.setContextMenuOff} id="TaamParserContextMenu" menuId={'TaamParserContextMenu'} xPos={this.state.xPos} yPos={this.state.yPos}  /></div>:<div id="TaamParserContextMenu"></div>} */}

          {/* <Dialog
            open={this.state.taamParserOpen}
            onClose={this.closeTaamParser}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
          > */}
            {this.state.taamParserOpen&&<TaamParser data={this.state.taamParseData} open={this.state.taamParserOpen} closeTaamParser={this.closeTaamParser}
                focusedCellRowLocation={null} extOpenTaamParser={this.openTaamParser}pasukId={this.state.taamParserPasukId} 
                pasukLocation={this.state.taamParserLocation} resultsApi={this.state.parshaViewerResultsApi} resultsNavDisabled={this.state.parshaViewerResultsApi===null}
                xPos={this.state.xPos} yPos={this.state.yPos} //setContextMenuOff={this.setContextMenuOff}
                toggleTaamParser={(boolean, pasukId, resultsApi) => {this.toggleTaamParser(boolean, pasukId, resultsApi)}}
                //openContextMenu={this.openContextMenu} showContextMenu={this.state.showContextMenu}
                x={this.state.taamParserPosition.x} y={this.state.taamParserPosition.y}
                widthNum={this.state.taamParserPosition.widthNum} heightNum={this.state.taamParserPosition.heightNum}
                setSize={(widthNum, heightNum, x, y) => {this.setTaamParserSize(widthNum, heightNum, x, y)}}
                setPosition={(x, y) => {this.setTaamParserPosition(x, y)}} handleClose={()=>this.setState({taamParserOpen:false})}
                handleFullscreen={() => {this.handleTaamParserFullscreen()}}
                handleSplitScreen={() => {this.handleTaamParserSplitScreen()}} search={obj=>{console.log(obj)}}
                isFirstTimeOpened={this.state.isFirstTimeOpened} extSetFirstTimeOpened={()=>this.setState({isFirstTimeOpened:false})}
                initialFullScreen={this.props.initialFullScreen} isFullScreen={this.isFullScreen} handleCopyLink={this.handleCopyLink}
    />}
          {/* </Dialog> */}
          <NotificationSystem ref={this.notificationSystem} style={{Title:{info:{margin:'0 126px',width:'max-content'}}, MessageWrapper:{info:{margin:'0 5px 0 15px'}}, NotificationItem:{DefaultStyle: {marginTop:'120%',padding:'10px 10px 10px 0'},}}}/>

        <TanachViewer extSearchDefData={this.state.extSearchDefData} isOpen={this.state.tanachViewerOpen} resultsApi={this.state.parshaViewerResultsApi}
            pasukId={this.state.contextualizedPasukId} extCopySelectedToSearchbar={this.copySelectedToSearchbar} 
            extSearchWithSelected={this.searchWithSelected} extFetchTaamParseData={this.fetchTaamParseData}
            toggleTanachViewer={(boolean, pasukId, resultsApi) => {this.toggleTanachViewer(boolean, pasukId, resultsApi)}}
            setSize={(widthNum, heightNum, x, y) => {this.setTanachViewerSize(widthNum, heightNum, x, y)}}
            setPosition={(x, y) => {this.setTanachViewerPosition(x, y)}} columnClicked={this.state.columnClicked}
            x={this.state.tanachViewerPosition.x} y={this.state.tanachViewerPosition.y} extSetTextSource={(val)=>this.setState({textSource:val})}
            widthNum={this.state.tanachViewerPosition.widthNum} heightNum={this.state.tanachViewerPosition.heightNum}
            handleFullscreen={() => {this.handleTanachViewerFullscreen()}} extOpenTaamParser={this.openTaamParser} 
            handleSplitScreen={() => {this.handleTanachViewerSplitScreen()}} extCloseTaamParser={this.closeTaamParser}
            isFirstTimeOpened={this.state.isFirstTimeOpened} extSetFirstTimeOpened={()=>this.setState({isFirstTimeOpened:false})}
            isJustOpened={this.state.isJustOpened} extSetJustOpened={()=>this.setState({isJustOpened:false})}
            initialFullScreen={this.props.initialFullScreen} isFullScreen={this.isFullScreen} handleCopyLink={this.handleCopyLink}
            extOpenLexiconViewer={this.openLexiconViewer} extSetBeta={this.setBeta}
        />

        <ObjectCloudViewer extFilterData={this.state.extFilterData} extSearchDefData={this.state.extSearchDefData} isOpen={this.state.objectCloudViewerOpen}
            extCopySelectedToSearchbar={this.copySelectedToSearchbar} objectCloudParshaId={this.state.objectCloudParshaId}
            extSearchWithSelected={this.searchWithSelected} extFetchTaamParseData={this.fetchTaamParseData}
            setSize={(widthNum, heightNum, x, y) => {this.setObjectCloudViewerSize(widthNum, heightNum, x, y)}}
            setPosition={(x, y) => {this.setObjectCloudViewerPosition(x, y)}}
            x={this.state.objectCloudViewerPosition.x} y={this.state.objectCloudViewerPosition.y}
            widthNum={this.state.objectCloudViewerPosition.widthNum} heightNum={this.state.objectCloudViewerPosition.heightNum}
            toggleOpen={(boolean) => {this.toggleObjectCloudViewer(boolean)}}
            handleFullscreen={() => {this.handleObjectCloudViewerFullscreen()}} placementRowData={this.state.placementRowData}
            handleSplitScreen={() => {this.handleObjectCloudViewerSplitScreen()}} textSource={this.state.textSource}
            isFirstTimeOpened={this.state.isFirstTimeOpened} extSetFirstTimeOpened={()=>this.setState({isFirstTimeOpened:false})}
            initialFullScreen={this.props.initialFullScreen} isFullScreen={this.isFullScreen} handleCopyLink={this.handleCopyLink}
        />

        <YuchsinTreeViewer  extFilterData={this.state.extFilterData} extSearchDefData={this.state.extSearchDefData} isOpen={this.state.yuchsinTreeViewerOpen}
            initializedToFullScreen={this.props.yuchsinTreeViewerOpen} isFullScreen={this.isFullScreen}
            //extCopySelectedToSearchbar={this.copySelectedToSearchbar}
            //extSearchWithSelected={this.searchWithSelected} extFetchTaamParseData={this.fetchTaamParseData}
            personFullName={this.props.personFullName} personFullName2={this.props.personFullName2}
            zoomDistance={this.props.zoomDistance} animate={this.props.animate} initialDarkMode={this.props.initialDarkMode}
            setSize={(widthNum, heightNum, x, y) => {this.setYuchsinTreeViewerSize(widthNum, heightNum, x, y)}}
            setPosition={(x, y) => {this.setYuchsinTreeViewerPosition(x, y)}}
            x={this.state.yuchsinTreeViewerPosition.x} y={this.state.yuchsinTreeViewerPosition.y}
            widthNum={this.state.yuchsinTreeViewerPosition.widthNum} heightNum={this.state.yuchsinTreeViewerPosition.heightNum}
            toggleOpen={(boolean) => {this.toggleYuchsinTreeViewer(boolean)}}
            handleFullscreen={() => {this.handleYuchsinTreeViewerFullscreen()}}
            handleSplitScreen={() => {this.handleYuchsinTreeViewerSplitScreen()}}
            setSearchIsLoading={this.setSearchIsLoading}sortOrder={this.state.sortOrder}sortDirection={this.state.sortDirection}
            extOpenSearchResults={this.extOpenSearchResults}sortedColumn={this.state.sortedColumn}
            extInitializeSearchState={this.initializeSearchState} handleCopyLink={this.handleCopyLink}
            extSetAboutTabIndex={this.setAboutTabIndex} placementRowData={this.state.placementRowData}
        />
        <Lexicon isOpen={this.state.lexiconOpen} isLexiconOpenedFromURL={this.state.isLexiconOpenedFromURL} word={this.props.word}
                    terms={this.state.terms} scope={this.state.scope} seq={this.state.seq}
                    setSize={(widthNum, heightNum, x, y) => {this.setLexiconSize(widthNum, heightNum, x, y)}}
                    setPosition={(x, y) => {this.setLexiconPosition(x, y)}}
                    x={this.state.lexiconPosition.x} y={this.state.lexiconPosition.y}
                    widthNum={this.state.lexiconPosition.widthNum} heightNum={this.state.lexiconPosition.heightNum}
                    toggleLexicon={(boolean) => {this.toggleLexicon(boolean)}}
                    handleFullscreen={() => {this.handleLexiconFullscreen()}} extSetBeta={this.setBeta}
                    handleSplitScreen={() => {this.handleLexiconSplitScreen()}} extDelayUpdateBituim={this.extDelayUpdateBituim}
                    extOpenSearchResults={this.extOpenSearchResults} setSearchIsLoading={this.setSearchIsLoading}
                    extFilterData={this.state.extFilterData} sortOrder={this.state.sortOrder} exactnessLevel={this.state.ssExactnessLevel}
                    extInitializeSearchState={this.initializeSearchState} sortDirection={this.state.sortDirection} sortedColumn={this.state.sortedColumn}
                    extSetTextSource={this.setTextSource} sourceType={this.state.lexiconSrcType} lexiconSearchText={this.state.lexiconSearchText} ssExactnessLevel={this.state.ssExactnessLevel}
                    sendNotification={this.addNotification } extSetContentDisplayed={(content)=>this.setState({sentContentDisplayed:content})}
                    closeSimpleSearchKb={()=>this.setState({simpleSearchKb:!this.state.simpleSearchKb})} lexiconKeyboard={this.state.lexiconKeyboard}
                    placementRowData={this.state.placementRowData} toggleIsLexiconOpenedFromURL={this.toggleIsLexiconOpenedFromURL}
                    isFirstTimeOpened={this.state.isFirstTimeOpened} extSetFirstTimeOpened={()=>this.setState({isFirstTimeOpened:false})}
                    initialFullScreen={this.props.initialFullScreen} isFullScreen={this.isFullScreen} handleCopyLink={this.handleCopyLink}
        />
        {this.props.isKodeshMode&&<KodeshMode isKodeshMode={this.props.isKodeshMode}
                                              isOpen={this.props.isKodeshMode || this.props.kodeshModeType != "CHOL"}
                                              kodeshModeType={this.props.kodeshModeType}
                                              kodeshModeEndType={this.props.kodeshModeEndType}
                                              kodeshModeStart={this.props.kodeshModeStart}
                                              kodeshModeEnd={this.props.kodeshModeEnd}
                                              kodeshModeSecondsRemaining={this.props.kodeshModeSecondsRemaining}
                                              hebRows={this.props.hebRows} engRows={this.props.engRows} toggleKodeshMode={this.props.toggleKodeshMode} />}

      </div></div>
    );
  }
}

export default withLocalize(Home);

/*
                                              //kodeshModeInfo={this.props.kodeshModeInfo}

              <span><Button close title={this.props.translate("close")} onClick={this.closeAdvancedSearch} />
              <AdvancedSearch />
              </span>
      <ReactGridLayout className="layout" layout={layout} cols={12} rowHeight={700} width={1200} >
        <div key="a" className="rg" h="30">jamli-kel-benn-pedassvedanya<br />Ely Roz-Lynne</div>
        <div key="b" className="rg" h="30">pedatzur<br />Shuzzbut</div>
        <div key="c" className="rg" h="30">nimrod ben ken iption<br />Dath-ka-too</div>
      </ReactGridLayout>
              <SimpleSearch callbackFromHome={this.extSearchCallback} extFilterData={this.state.extFilterData} openKeyboardCallback={this.extOpenKeyboardCallback} extKeyboardData={this.state.extKeyboardData} />
          <div id="keyboard" allowResize={true} initialSize="0px" minSize="0px" align="center">
              <HebrewKeyboard keyboardCallback={this.extKeyboardCallback} />
          </div>
*/