import React, { Component, useState } from 'react';
import './css/App.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import { Button, Paper, InputBase, IconButton, Fade, Typography, Popper, AppBar, Tabs, Tab, InputLabel, MenuItem, FormControl, Select, Switch, Popover } from '@material-ui/core';
import translations from "./json/translations.json";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ClearAll from '@material-ui/icons/ClearAll';
import SelectAll from '@material-ui/icons/SelectAll';
import SearchIcon from '@material-ui/icons/Search';
import {Checkbox} from 'primereact/checkbox';

const textGridPos = 25, targumGridPos = 24;

class StatPicker2D extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            open: false
        }

        this.handlePopoverClick = this.handlePopoverClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleButtonClicked = this.handleButtonClicked.bind(this);

    }

     handleButtonClicked(buttonId){
        const oldValues = this.props.pickerModel.values;
        var newValues = oldValues;

        if(buttonId === 'selectAll'){
            newValues = [];
            for(let i = 1; i <= this.props.pickerModel.list.length; i++){
                if(i!==targumGridPos && i!==textGridPos){
                    newValues.push(i)
                }
            }    
            if(oldValues.includes(targumGridPos))
                newValues.push(targumGridPos);
            if(oldValues.includes(textGridPos))
                newValues.push(textGridPos);
                
            
        }
        else if (buttonId === 'clearAll'){
            newValues = [];
            if(oldValues.includes(targumGridPos)){
                newValues.push(targumGridPos)
            }
            if(oldValues.includes(textGridPos)){
                newValues.push(textGridPos)
            }
        }else if(buttonId === 'search'){
            this.props.extApplyValues();
        }else {
            //remove selected value from newValues
            if (oldValues.includes(buttonId)){
                var buttonIndex = oldValues.indexOf(buttonId);
                newValues = oldValues.slice(0,buttonIndex).concat(oldValues.slice(buttonIndex+1, oldValues.length));
            //add selected value to newValues
            }
            else {
                newValues.push(buttonId);
            }
        }
        this.props.extSetValues(newValues);
     }



//     const classes = useStyles();

       handlePopoverClick(event) {
         this.setState({anchorEl: event.currentTarget, open: true});
       }

       handleClose() {
         this.setState({anchorEl: null, open: false});
         this.props.extApplyValues();
       }

     render() {
        if (this.props.pickerModel == null || this.props.pickerModel.list == null || this.props.pickerModel.list.length == 0)
             return null;

        var list = this.props.pickerModel.list;

        //var len = list.length; //GET tHIS TO DIFFERENT OBJECT SO DOESNT CHANGE STASTE!!!!!
        //var el = {id: len+1, name: 'ללא נקודה', icon: 'X'};
        //list.push(el);
        const selectStyle = this.props.selectStyle != null ? this.props.selectStyle : "";
        const icon = this.props.icon != null ? this.props.icon : null;
        const displayMultiple = this.props.displayMultiple;
        const rowHeight = {height: '40px'}; //style Wingdings 2 : tuvwxyz{|~ to rep 0 - 10
        const footerStyle = {height: '0px', fontWeight: 'bold', align: 'center', textAlign: 'center', padding: '0px', backgroundColor: '#F8F5DE'};
        var i = 0;

        const anchorEl = this.state.anchorEl;
        const id = this.state.open ? 'simple-popover' : undefined;

        const sel = this.props.translate("select");
        const selectPad = this.props.translate("selectPad.IncludeExclude");
        const selectPad2 = (selectStyle == "General" && !displayMultiple) ? selectPad : ""; //if can assume we're on niku/hataama tab and in the general nekuda picker, then need to add some extra space

        const pickerModelValues = this.props.pickerModel.values;
        const renderValue = this.props.title;
        const fakeThis = this //fixed problem with 'this' not getting defined

        const buttonStyle = {height: '40px', width: '95px'};

        const isHebrew = localStorage.getItem("language") === 'he';
        const menuItemStyle = {direction: isHebrew ? "rtl" : "ltr", backgroundColor: '#ffffff', fontWeight: 'normal', fontSize: '12px', height: '16px', padding: '3px'};
        const generalAlign = isHebrew ? "right" : "left";
        const miClass = isHebrew ? "menuItem" : "ltrMenuItem";

        const positionNearText = this.props.translate("positionNearText");
        const hoverInfo = this.props.translate("hoverInfo");
        const all = this.props.translate("all");
        const selectedAbove = this.props.translate("selectedAbove");
        const perWordStat = this.props.translate("perWordStat");
        const none = this.props.translate("none");
        const toRight = this.props.translate("toRight");
        const toLeft = this.props.translate("toLeft");
        const selectAll = this.props.translate("selectAll");
        const removeAll = this.props.translate("removeAll");
        const search = this.props.translate("searchWithOutExclamation");
        const copyPasteScope =  this.props.translate("Copy/pasteScope");
        const copyPasteCaptions = this.props.translate("Copy/pasteCaptions");
        const headerRow = this.props.translate("headerRow");
        const inline = this.props.translate("inline");
        const includeWordStats = this.props.translate("includeWordStats");

        
        if (!this.props.keepOpen){
            return <span className="placementGridLine" style={rowHeight} style={{backgroundColor: '#3f51b5'}}>

                <Button aria-describedby={id} variant="contained" onClick={this.handlePopoverClick} width='44px' className={fakeThis.props.extClassName} elevation={1} style={{outlineWidth: '0px'}}>
                            <ArrowDropDown style={{color: 'white', paddingBottom: '1px'}}/> <Typography style={{fontSize: '14px', color: 'white', fontWeight: 'bold', lineHeight: 0, paddingBottom: '2px'}}>{renderValue}</Typography >
                </Button>
                <Popover
                    id={id}
                    open={this.state.open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    elevation={1}
                >
                    <Typography style={{direction: isHebrew ? "rtl" : "ltr", padding: '10px'}}>
                        <Table size="small" >
                        <TableHead>
                            <TableRow style={{height: '30px'}}>
                            <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                                    <MenuItem className="menuItem menuItemSpecial" style={{height: '30px'}} value={'selectAll'} selected={false} onClick={() => fakeThis.handleButtonClicked('selectAll')}>
                                    <span className="lntPickerSelectIcon"><SelectAll style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName" style={{textTransform: 'capitalize'}}>{selectAll}</span>
                                    </MenuItem>
                                </TableCell>
                            <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                                <MenuItem className="menuItem menuItemSpecial" style={{height: '30px'}} value={'clearAll'} selected={false} onClick={() => fakeThis.handleButtonClicked('clearAll')}>
                                <span className="lntPickerSelectIcon"><ClearAll style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName" style={{textTransform: 'capitalize'}}>{removeAll}</span>
                                </MenuItem>
                            </TableCell>
                            <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                                    <Typography> </Typography>
                            </TableCell>
                            <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                                    <Typography> </Typography>
                            </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{padding: '0px'}}>
                            <TableRow style={rowHeight}>
                                {list.slice(0,4).map(function(e){
                                    return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', align: {generalAlign}, textAlign: {generalAlign}}}>
                                    <MenuItem className={miClass} style={buttonStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleButtonClicked(e.id)}>
                                    <span className="lntPickerSelectName">{e.name}</span>
                                    </MenuItem>
                                    </TableCell>
                                    })}
                            </TableRow>
                            <TableRow style={rowHeight}>
                                {list.slice(4,8).map(function(e){
                                    return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}}>
                                    <MenuItem className={miClass} style={buttonStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleButtonClicked(e.id)}>
                                    <span className="lntPickerSelectName">{e.name}</span>
                                    </MenuItem>
                                    </TableCell>
                                    })}
                            </TableRow>
                            <TableRow style={rowHeight}>
                                {list.slice(8,12).map(function(e){
                                    return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}}>
                                    <MenuItem className={miClass} style={buttonStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleButtonClicked(e.id)}>
                                    <span className="lntPickerSelectName">{e.name}</span>
                                    </MenuItem>
                                    </TableCell>
                                    })}
                            </TableRow>
                            <TableRow style={rowHeight}>
                                {list.slice(12,16).map(function(e){
                                    return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}}>
                                    <MenuItem className={miClass} style={buttonStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleButtonClicked(e.id)}>
                                    <span className="lntPickerSelectName">{e.name}</span>
                                    </MenuItem>
                                    </TableCell>
                                    })}
                            </TableRow>
                            <TableRow style={rowHeight}>
                                {list.slice(16,20).map(function(e){
                                    return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}}>
                                    <MenuItem className={miClass} style={buttonStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleButtonClicked(e.id)}>
                                    <span className="lntPickerSelectName">{e.name}</span>
                                    </MenuItem>
                                    </TableCell>
                                    })}
                            </TableRow>
                            <TableRow style={rowHeight}>
                                {list.slice(20,22).map(function(e){
                                    return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}}>
                                    <MenuItem className={miClass} style={buttonStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleButtonClicked(e.id)}>
                                    <span className="lntPickerSelectName">{e.name}</span>
                                    </MenuItem>
                                    </TableCell>
                                    })}
                            </TableRow>
{/* whenClicked is a property not an event, per se.
//                            <TableRow style={rowHeight}>
//                                {list.slice(18,21).map(function(e){
//                                    return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}}>
//                                    <MenuItem className={miClass} style={buttonStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleButtonClicked(e.id)}>
//                                    <span className="lntPickerSelectName">{e.name}</span>
//                                    </MenuItem>
//                                    </TableCell>
//                                    })}
//                            </TableRow>
*/}
                            <TableRow>
                                <TableCell colspan={4} style={footerStyle}>
                                <Table>
                                    <TableBody>
                                        <TableRow style={footerStyle}>
                                            <TableCell component="tr" scope="row" style={footerStyle}>
                                                <div>
                                                    {positionNearText}
                                                </div>
                                            </TableCell>
                                            <TableCell component="tr" scope="row" style={footerStyle}>
                                                <div>
                                                    {hoverInfo}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow style={footerStyle}>
                                            <TableCell component="tr" scope="row" style={footerStyle}>
                                                <div style={{align: 'center'}}>
                                                    <Select
                                                        value={this.props.pasukTextPosition}
                                                        onChange={(e) => {this.props.extHandleStatsPosition(e.target.value)}}
                                                        name="statsPositionSelect"
                                                        displayEmpty
                                                        style={{
                                                            width: '112px',
                                                            textAlign: {generalAlign},
                                                            backgroundColor: '#ffffff',
                                                            fontWeight: 'normal',
                                                            fontSize: '12px',
                                                            paddingTop: '0px',
                                                            paddingBottom: '0px'
                                                        }}
                                                    >
                                                        <MenuItem style={menuItemStyle} value={"left"}>{toRight}</MenuItem>
                                                        <MenuItem style={menuItemStyle} value={"right"}>{toLeft}</MenuItem>
                                                    </Select>
                                                </div>
                                            </TableCell>
                                            <TableCell component="tr" scope="row" style={footerStyle}>
                                                <div style={{align: 'center'}}>
                                                    <Select
                                                        value={this.props.hoverSelect}
                                                        onChange={(e) => {this.props.extHandleHoverSelect(e.target.value)}}
                                                        name="hoverSelect"
                                                        displayEmpty
                                                        style={{
                                                            width: '112px',
                                                            align: 'center',
                                                            textAlign: {generalAlign},
                                                            backgroundColor: '#ffffff',
                                                            fontWeight: 'normal',
                                                            fontSize: '12px',
                                                            paddingTop: '0px',
                                                            paddingBottom: '0px'
                                                        }}
                                                    >
                                                        <MenuItem style={menuItemStyle} value={"all"}>{all}</MenuItem>
                                                        <MenuItem style={menuItemStyle} value={"selected"}>{selectedAbove}</MenuItem>
                                                        <MenuItem style={menuItemStyle} value={"none"}>{none}</MenuItem>
                                                    </Select>
                                                </div>
                                            </TableCell>

                                        </TableRow>

                                        <TableRow style={footerStyle}>
                                            <TableCell component="tr" scope="row" style={footerStyle}>
                                                <div>
                                                    {copyPasteScope}
                                                </div>
                                            </TableCell>
                                            <TableCell component="tr" scope="row" style={footerStyle}>
                                                <div>
                                                    {copyPasteCaptions}
                                                </div>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow style={footerStyle}>
                                            <TableCell component="tr" scope="row" style={footerStyle}>
                                                <div style={{align: 'center'}}>
                                                    <Select
                                                        value={this.props.statsToCopy}
                                                        onChange={(e) => {this.props.setStatsToCopy(e.target.value)}}
                                                        name="statsPositionSelect"
                                                        displayEmpty
                                                        style={{
                                                            width: '112px',
                                                            textAlign: {generalAlign},
                                                            backgroundColor: '#ffffff',
                                                            fontWeight: 'normal',
                                                            fontSize: '12px',
                                                            paddingTop: '0px',
                                                            paddingBottom: '0px'
                                                        }}
                                                    >
                                                        <MenuItem style={menuItemStyle} value={"all"}>{all}</MenuItem>
                                                        <MenuItem style={menuItemStyle} value={"selected above"}>{selectedAbove}</MenuItem>
                                                    </Select>
                                                </div>
                                            </TableCell>
                                            <TableCell component="tr" scope="row" style={footerStyle}>
                                                <div style={{align: 'center'}}>
                                                    <Select
                                                        value={this.props.howToPaste}
                                                        onChange={(e) => {this.props.setHowToPaste(e.target.value)}}
                                                        name="hoverSelect"
                                                        displayEmpty
                                                        style={{
                                                            width: '112px',
                                                            align: 'center',
                                                            textAlign: {generalAlign},
                                                            backgroundColor: '#ffffff',
                                                            fontWeight: 'normal',
                                                            fontSize: '12px',
                                                            paddingTop: '0px',
                                                            paddingBottom: '0px'
                                                        }}
                                                    >
                                                        <MenuItem style={menuItemStyle} value={"header row"}>{headerRow}</MenuItem>
                                                        <MenuItem style={menuItemStyle} value={"inline"}>{inline}</MenuItem>
                                                    </Select>
                                                </div>
                                            </TableCell>

                                        </TableRow>

                                    </TableBody>
                                    </Table>
                                </TableCell>

                            </TableRow>

                        </TableBody>
                        </Table>

                    </Typography>
                </Popover>
            </span>;
        }else{
            return <Typography style={{direction: isHebrew ? "rtl" : "ltr"}}>
                <Table size="small" >
                <TableHead>
                    <TableRow style={{height: '30px'}}>
                    <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                            <MenuItem className="menuItem menuItemSpecial" style={{height: '30px'}} value={'selectAll'} selected={false} onClick={() => fakeThis.handleButtonClicked('selectAll')}>
                            <span className="lntPickerSelectIcon"><SelectAll style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName" style={{textTransform: 'capitalize'}}>{selectAll}</span>
                            </MenuItem>
                        </TableCell>
                    <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                        <MenuItem className="menuItem menuItemSpecial" style={{height: '30px'}} value={'clearAll'} selected={false} onClick={() => fakeThis.handleButtonClicked('clearAll')}>
                        <span className="lntPickerSelectIcon"><ClearAll style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName" style={{textTransform: 'capitalize'}}>{removeAll}</span>
                        </MenuItem>
                    </TableCell>
                    <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                        <MenuItem disabled={this.props.pickerModel.values.length===0} className="menuItem menuItemSpecial" style={{height: '30px'}} value={'clearAll'} onClick={() => fakeThis.handleButtonClicked('search')}>
                            <span className="lntPickerSelectIcon"><SearchIcon style={{color: '#757575'}}/></span>&nbsp;<span className="lntPickerSelectName" style={{textTransform: 'capitalize',direction:isHebrew?'rtl':'ltr'}}>{search}</span>
                        </MenuItem>
                    </TableCell>
                    <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', backgroundColor: '#e0e0e0'}}>
                            <Typography> </Typography>
                    </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody style={{padding: '0px'}}>
                    <TableRow style={rowHeight}>
                        {list.slice(0,4).map(function(e){
                            return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0', align: {generalAlign}, textAlign: {generalAlign}}}>
                            <MenuItem className={miClass} style={buttonStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleButtonClicked(e.id)}>
                            <span className="lntPickerSelectName">{e.name}</span>
                            </MenuItem>
                            </TableCell>
                            })}
                    </TableRow>
                    <TableRow style={rowHeight}>
                        {list.slice(4,8).map(function(e){
                            return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}}>
                            <MenuItem className={miClass} style={buttonStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleButtonClicked(e.id)}>
                            <span className="lntPickerSelectName">{e.name}</span>
                            </MenuItem>
                            </TableCell>
                            })}
                    </TableRow>
                    <TableRow style={rowHeight}>
                        {list.slice(8,12).map(function(e){
                            return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}}>
                            <MenuItem className={miClass} style={buttonStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleButtonClicked(e.id)}>
                            <span className="lntPickerSelectName">{e.name}</span>
                            </MenuItem>
                            </TableCell>
                            })}
                    </TableRow>
                    <TableRow style={rowHeight}>
                        {list.slice(12,16).map(function(e){
                            return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}}>
                            <MenuItem className={miClass} style={buttonStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleButtonClicked(e.id)}>
                            <span className="lntPickerSelectName">{e.name}</span>
                            </MenuItem>
                            </TableCell>
                            })}
                    </TableRow>
                    <TableRow style={rowHeight}>
                        {list.slice(16,20).map(function(e){
                            return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}}>
                            <MenuItem className={miClass} style={buttonStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleButtonClicked(e.id)}>
                            <span className="lntPickerSelectName">{e.name}</span>
                            </MenuItem>
                            </TableCell>
                            })}
                    </TableRow>
                    <TableRow style={rowHeight}>
                        {list.slice(20,22).map(function(e){
                            return <TableCell component="tr" scope="row" style={{padding: '0px', borderBottom: '0'}}>
                            <MenuItem className={miClass} style={buttonStyle} value={e.id} selected={pickerModelValues.includes(e.id)} onClick={() => fakeThis.handleButtonClicked(e.id)}>
                            <span className="lntPickerSelectName">{e.name}</span>
                            </MenuItem>
                            </TableCell>
                            })}
                    </TableRow>
                    <TableRow style={{height:'30px'}}>
                        <TableCell colspan={4} style={{backgroundColor:'#e0e0e0',padding: '0px', borderBottom: '0', textAlign: isHebrew?'right':'left'}}>
                        <div onClick={() => {fakeThis.props.toggleIncludeOtherStats()}}>
                                   <Checkbox checked={fakeThis.props.includeOtherStats} id='otherStats-checkbox' style={{display: 'inline-block',margin:'0 6px', zIndex: '2', position: 'relative'}} />
                                   <label htmlFor="otherStats-checkbox" style={{ whiteSpace: 'normal', lineHeight: '1', display: 'inline',fontSize:'14px' }} >
                                       {includeWordStats}
                                   </label>
                               </div>
                        </TableCell>
                    </TableRow>
                </TableBody>
                </Table>

                </Typography>
            }
        }
}

export default withLocalize(StatPicker2D);


