import React, { Component } from 'react';
import * as properties from './config/properties.js';
import { withLocalize, Translate ,getTranslate} from "react-localize-redux";

//section for global variables
export var isForceOpenAboutDialog = false;
export function setForceOpenAboutDialog(x) {isForceOpenAboutDialog = x;}
export var totalAboutTabs = 11;

export function empty(cVal) {
   return (cVal == null || (typeof cVal == "string" && cVal.trim() == ""));
}

export function clipL(c, nChars) {
   if (c == null)  return null;
   return right(c, c.length - nChars);
}

export function clipR(c, nChars) {
   if (c == null)  return null;
   return left(c, c.length - nChars);
}

export function left(c, nLen) {
   if (c == null)  return null;
   if (nLen <= 0)  return "";
   if (c.length < nLen)  return c;
   return c.substr(0,nLen);
}

export function right(c, nLen) {
   if (c == null)  return null;
   if (nLen <= 0)  return "";
   if (c.length < nLen)  return c;
   return c.substr(c.length-nLen,nLen);
}

//insert string cStuff into string c at position nStart, optionally overwriting nChars # of characters
export function stuff(c, cStuff, nStart, nChars) {
   var c1 = c.substring(0,nStart);
   var c2 = c.substring(nStart+nChars,c.length);
   return c1 + cStuff + c2;
}

export function replicate(c, nLen) {
   var i;
   if (c == null)  return null;
   var cRepl = "";
   for (i=1; i<=nLen; i++)
      cRepl += c;
   return cRepl;
}

export function setValue(cFld, cVal, oFrame, cForm) {
   var oFld = document_all(cFld, oFrame, cForm);
//   if (left(oFld.type, 6) == "select")
//      setSelValue(cFld, cVal, oFrame, cForm);
//   else if (left(oFld.type, 6) == "radio")
//      setSelChecked(cFld, cVal, oFrame, cForm);
//   else if (left(oFld.type, 8) == "checkbox") {
//      if (typeof cVal == "boolean")
//         setChecked(cFld, cVal, oFrame, cForm);
//      else
//         setCheckedText(cFld, cVal, oFrame, cForm);
//   }
//   else
      oFld.value = cVal;
}

function document_all(cFld, oFrame, cForm) {
  var i, aElements, aFlds, nFlds;
  if (document_all.arguments.length <= 1 || (oFrame == null && cForm == null))
//     aElements = document.forms[0].elements;
      aElements = document.getElementsByName(cFld);     //this works much faster for name/id lookups
  else if (document_all.arguments.length <= 2 || cForm == null)
     aElements = oFrame.document.forms[0].elements;
  else if (oFrame == null)
     aElements = document.forms[cForm].elements;
  else
     aElements = oFrame.document.forms[cForm].elements;
  aFlds = new Array();
  nFlds = 0;
  for (i=0; i < aElements.length; i++) {
//    if (aElements[i].name == cFld) {
     if (aElements[i].id == cFld || aElements[i].name == cFld) {
        aFlds[nFlds++] = aElements[i];
     }
  }
  if (nFlds == 0)
     return aElements;  //eg. when "" is passed
  else if (nFlds == 1)
     return aFlds[0];
  else
     return aFlds;
}

//todo - can peformance improve here?
//todo - check whether this generates extra html where search term is repeated in text
export function decorateText(text,textField, matchIndex,targumMapObj,torahMapObj,frequencyViewer,tanachSearch,tanachText,targumSearch,targumText,spanWords) {
  if (text == null || text == "" || textField.length!==text.split(" ").length) return "";
  var decoratedText = textField; //= "";
  var words = text.split(" ");
  var matches = matchIndex.split(";");
  var matchProperties, pasukSequenceNum, paramMatchNum;
  var style = "font-weight: bold; color: ";
  var colors = ["red", "blue", "green", "brown", "darkorange", "blueviolet"];

  if(targumMapObj && torahMapObj && tanachSearch && targumText){//"10,1;16,2" numberInPasuk,termNumber; 
    var matchesIds
    for(var i=0; i < matches.length; i++){
      matchProperties = matches[i].split(",");
      paramMatchNum = matchProperties[1];
      var foundMatch=false;
      matchesIds = torahMapObj[matchProperties[0]]
      if(!matchesIds) matchesIds=[]
      for(let j=1;j<=Object.keys(targumMapObj).length; j++){
        if(targumMapObj[j]){
          for(let k=0; k < targumMapObj[j].length; k++){
            if(matchesIds.includes(targumMapObj[j][k]))
              foundMatch = j
            else foundMatch=false
          }
          if(foundMatch){
              pasukSequenceNum = foundMatch//(targumMapObj[foundMatch])
              // var word = '>'+words[pasukSequenceNum-1]+' <'
              decoratedText[pasukSequenceNum-1] = decoratedText[pasukSequenceNum-1].replace(words[pasukSequenceNum-1], "<span style='" + style + colors[paramMatchNum-1] + ";'>"+words[pasukSequenceNum-1]+"</span>");
          }
        }
      }
    }

  } else if(targumMapObj && torahMapObj && targumSearch && tanachText){
    var matchesIds
    for(var i=0; i < matches.length; i++){
      matchProperties = matches[i].split(",");
      paramMatchNum = matchProperties[1];
      var foundMatch=false;
      matchesIds = targumMapObj[matchProperties[0]]
      if(!matchesIds) matchesIds=[]
      for(let j=1;j<=Object.keys(torahMapObj).length; j++){
        if(torahMapObj[j]){
          for(let k=0; k < torahMapObj[j].length; k++){
            if(matchesIds.includes(torahMapObj[j][k]))
              foundMatch = j
            else foundMatch=false
          }
          if(foundMatch){
              pasukSequenceNum = foundMatch
              decoratedText[pasukSequenceNum-1] = decoratedText[pasukSequenceNum-1].replace(words[pasukSequenceNum-1], "<span style='" + style + colors[paramMatchNum-1] + ";'>"+words[pasukSequenceNum-1]+"</span>");
          }
        }
      }
    }
  }
  else{//if it is the frequencyViewer
    for (var i=0; i < matches.length; i++) {
        matchProperties = matches[i].split(",");
        pasukSequenceNum = matchProperties[0];
        if(pasukSequenceNum && decoratedText[pasukSequenceNum-1]){
          paramMatchNum = matchProperties[1];
          //words[pasukSequenceNum-1] = "<span style='" + style + colors[paramMatchNum-1] + ";'>"+words[pasukSequenceNum-1]+"</span>";
          decoratedText[pasukSequenceNum-1] = decoratedText[pasukSequenceNum-1].replace(words[pasukSequenceNum-1], "<span style='" + style + colors[paramMatchNum-1] + ";'>"+words[pasukSequenceNum-1]+"</span>");
        }
    }

  }
    //for (var i=0; i < words.length; i++)
    //    decoratedText += (words[i] + ' ');
//alert(decoratedText);
  if(frequencyViewer){
    if(spanWords)
      for (let i=0;i<decoratedText.length;i++){
        decoratedText[i]=decoratedText[i].replace(words[i],`<span>${decoratedText[i]}</span>`)
      }
    return decoratedText.join(" ");
  }
    return decoratedText.join(""); //util.clipR(decoratedText, 1);
}

// function showOnHover(index){
//   console.log(index);
// }

//seen at https://www.w3resource.com/javascript-exercises/javascript-function-exercise-17.php
export function charCounts(text) {
    var uchars = {};
    text.replace(/\S/g, function(l){uchars[l] = (isNaN(uchars[l]) ? 1 : uchars[l] + 1);});
    return uchars;
}

      //alert(this.Char_Counts("Hi fiddle dee dee")["d"]);
export var fullAlefBeis = ["א", "ב", "ג", "ד", "ה", "ו", "ז", "ח", "ט", "י", "כ", "ל", "מ", "נ", "ס", "ע", "פ", "צ", "ק", "ר", "ש", "ת", "ם", "ן", "ץ", "ף", "ך"];

export function showLetterCoverage(text, isFormatted) {
    if (text == null || text == "") return "";
    isFormatted = arguments.length >= 2 ? isFormatted : true;
    var counts = charCounts(text);
    var resultText = "";
    var fontSize = 14;
    var stylePresent = "font-weight: bold; font-size: " + fontSize + "px;";
    var styleAbsent = "font-weight: normal; font-size: " + (fontSize-3) + "px; color: gray;";
    var style;
    for (var i=0; i < fullAlefBeis.length; i++) {
        if (isFormatted) {
            style = counts.hasOwnProperty(fullAlefBeis[i]) ? stylePresent : styleAbsent;
            resultText += "<span style='" + style + "'>"+fullAlefBeis[i]+"</span>";
        }
        else
            resultText += counts.hasOwnProperty(fullAlefBeis[i]) ? fullAlefBeis[i] : "";
    }

    return resultText; //util.clipR(resultText, 1);
}

export function removeNikud(str){
   for (let i = str.length-1; i >= 0; i--){
       if('ְֱֲֳִֵֶַָֹֺֻּֽ־ׇֿׁׂׅׄ'.includes(str[i])){
           str = str.slice(0,i).concat(str.slice(i+1,str.length))
       }
   }
   return str;
}

export function toRishonLetters(str){
   return str.replace('ך', 'כ').replace('ם', 'מ').replace('ן', 'נ').replace('ף', 'פ').replace('ץ','צ');
}

export function isHebrew(text) {
    if (text == null || typeof text == "number") return false;
    var patt1 = /[א-ת]/g;
    var result = text.match(patt1);
    return result != null && result != "";
}

export function isNumeric(text) {
    if (text == null) return false;
    var patt1 = /[A-Z]/g;
    var result = text.match(patt1);
    return result != null && result != "";
}

export function isAlefNumeric(text) {
    if (text == null) return false;
    var patt1 = /[א-ת,0-9]/g;
    var result = text.match(patt1);
    return result != null && result != "";
}

//from https://www.30secondsofcode.org/js/s/levenshtein-distance
export function levenshteinDistance(s, t) {
  if (!s.length) return t.length;
  if (!t.length) return s.length;
  const arr = [];
  for (let i = 0; i <= t.length; i++) {
    arr[i] = [i];
    for (let j = 1; j <= s.length; j++) {
      arr[i][j] =
        i === 0
          ? j
          : Math.min(
              arr[i - 1][j] + 1,
              arr[i][j - 1] + 1,
              arr[i - 1][j - 1] + (s[j - 1] === t[i - 1] ? 0 : 1)
            );
    }
  }
  return arr[t.length][s.length];
};

export function decorateNekudosTaamim(text) {
    if (text == null || typeof text == "number" || isAlefNumeric(text)){
      if(isHebrew(text)){
        text = text.split(" ")
        for(let i=0;i<text.length;i++){
          text[i] = `<span>${text[i]}</span>`
        }
        return text.join(" ");
      }
      return text;
    }

    var result = "";
    for (var i=0; i < text.length; i++) {
        result += ("ת" + text.slice(i,i+1));
    }
    return result;
}

	var letters = {}, numbers = {
		'': 0,
		א: 1,
		ב: 2,
		ג: 3,
		ד: 4,
		ה: 5,
		ו: 6,
		ז: 7,
		ח: 8,
		ט: 9,
		י: 10,
		כ: 20,
		ל: 30,
		מ: 40,
		נ: 50,
		ס: 60,
		ע: 70,
		פ: 80,
		צ: 90,
		ק: 100,
		ר: 200,
		ש: 300,
		ת: 400,
		תק: 500,
		תר: 600,
		תש: 700,
		תת: 800,
		תתק: 900,
		תתר: 1000
	}, i;
	for (i in numbers) {
		letters[numbers[i]] = i;
	}

	export function gematriya(num, options) {
		if (options === undefined) {
			var options = {limit: false, punctuate: true, order: false, geresh: true};
		}

		if (typeof num !== 'number' && typeof num !== 'string') {
			throw new TypeError('non-number or string given to gematriya()');
		}

		if (typeof options !== 'object' || options === null){
			throw new TypeError('An object was not given as second argument')
		}

		var limit = options.limit;
		var order = options.order;
		var punctuate = typeof options.punctuate === 'undefined' ? true : options.punctuate;
		var geresh = typeof options.geresh === 'undefined' && punctuate ? true : options.geresh;

		var str = typeof num === 'string';

		if (str) {
			num = num.replace(/('|")/g,'');
		}
		num = num.toString().split('').reverse();
		if (!str && limit) {
			num = num.slice(0, limit);
		}

		num = num.map(function g(n,i){
			if (str) {
				return order && numbers[n] < numbers[num[i - 1]] && numbers[n] < 100 ? numbers[n] * 1000 : numbers[n];
			} else {
				if (parseInt(n, 10) * Math.pow(10, i) > 1000) {
					return g(n, i-3);
				}
				return letters[parseInt(n, 10) * Math.pow(10, i)];
			}
		});

		if (str) {
			return num.reduce(function(o,t){
				return o + t;
			}, 0);
		} else {
			num = num.reverse().join('').replace(/יה/g,'טו').replace(/יו/g,'טז').split('');

			if (punctuate || geresh)	{
				if (num.length === 1) {
					num.push(geresh ? '׳' : "'");
				} else if (num.length > 1) {
					num.splice(-1, 0, geresh ? '״' : '"');
				}
			}

			return num.join('');
		}
	}

    export function objectPropertyList(obj) {
        var objects = "";
        Object.keys(obj).map(function(e){objects += e + ", ";})
        return clipR(objects, 2);
    }

    export function getTimeStamp() {
       var oDate = new Date();
       return oDate.getTime();
    }

    export function getMillisFromDate(dt) {
        return dt.getHours()*3600000 + dt.getMinutes()*60000 + dt.getSeconds()*1000 + dt.getMilliseconds();
    }

    export function getCurrentMillis() {return getMillisFromDate(new Date());}

    export function sleep(millis) {
        var startTime = getCurrentMillis();
        while (getCurrentMillis() - startTime < millis) {doNothing();}
    }

    export function doNothing() {var xyz = 100;}


    export async function  loadPerakim(val) {
          const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPerekInfo?source=bhsWebApp&seferId='+val);
          const body = await response.json();
          const perakim = body.map(function(e) {return {perekNum: e.perek, perekValue: gematriya(e.perek, {punctuate: false}), pesukim: e.pesukim }});
          return perakim;
    }
    export async function loadPesukim(val,valList2) {
        const perek = valList2.find(function(e){return e.perekNum == val;});
        const body = Array(perek.pesukim).fill().map((x,i)=>({pasukNum: i+1, pasukValue: gematriya(i+1, {punctuate: false}) }));
        return body;
    }
    export async function loadSeforim() {
      const url = properties.env.webServiceURL + "/BaseHaSefer/getSeferPerekInfo?source=bhsWebApp";
      const response = await fetch(url);
      const body = await response.json();
      return body;
    }
    export async function getParshaIdFromSeferPerekPasuk(seferId, perekId, pasukId){
      const url = properties.env.webServiceURL + '/BaseHaSefer/getParshaIdFromSeferPerekPasuk?source=bhsWebApp&seferId=' + seferId + '&perekNum=' + perekId + '&pasukNum=' + pasukId;
      const response = await fetch(url);
      const body = await response.json();
      return body;
    }
    export async function getParshaPasukLocationList(){
      const url = properties.env.webServiceURL + '/BaseHaSefer/getParshaPasukLocationList?source=bhsWebApp';
      const response = await fetch(url);
      const body = await response.json();
      return body;
    }
    export var isPlaying = false;
    export function setIsPlaying(val){
      isPlaying = val
    }
    var timer 
    export function timerSetInterval(sec,callback){
      timer = setInterval(callback,sec)
    }
    export function timerClearInterval(){
      clearTimeout(timer)
    }


    async function setUpPerWord(bitui,mainExactnessLevel,translate,inLeftBar){
        var dataArray=[];
        var leftBar=inLeftBar;
        for(let i=0; i<bitui.length; i++){

              var data={
                id:dataArray.length+1,
                name:<div title={leftBar?bitui[i].term:""}><b><Translate id="searchTerm"/>{bitui[i].term}</b></div>,
                isExpandable:true,
                isExpanded:true,
                children:[],
              };
              if(bitui[i].isNegative){
                data.children.push({
                  id:data.children.length+1,
                  name:<div title={true?translate("negativeWord"):""} ><Translate id="negativeWord"/></div>
                })
              }
              if(bitui[i].isCaseSensitive){
                data.children.push({
                  id:data.children.length+1,
                  name:<div title={true?translate("caseSensitive"):""}><Translate id="caseSensitive"/></div>
                })
              }
              if(bitui[i].repetitions>1){
                data.children.push({
                  id:data.children.length+1,
                  name:<div title={true?bitui[i].repetitions:""}><Translate  id="occurrences"/>: {bitui[i].repetitions}</div>
                })
              }
              if(bitui[i].transformation){
                data.children.push({
                  id:data.children.length+1,
                  name:<div title={true?bitui[i].transformation===1?translate("anagram"):bitui[i].transformation===2?translate("reverse"):bitui[i].transformation===3?translate("root"):"Regex":""}>
                    <Translate id="transformationSearchCriteria"/>&nbsp;{bitui[i].transformation===1?<Translate id="anagram" title={translate("anagram")}/>:
                  bitui[i].transformation===2?<span><Translate id="reverse"/></span>:
                  bitui[i].transformation===3?<span><Translate id="root" /></span>:
                  bitui[i].transformation===4?<span>Regex</span>: ""}</div>
                })
              }
              if(bitui[i].exactnessLevel!==mainExactnessLevel){
                data.children.push({
                  id: data.children.length+1,
                  name: <div title={true?bitui[i].exactnessLevel===0?translate("preciseSearch.caption"):bitui[i].exactnessLevel===1?translate("somewhatExact"):bitui[i].exactnessLevel===2?translate("somewhatFuzzy"):translate("fuzzySearch.caption"):""}>
                  <Translate id="exactness"/>&nbsp;{bitui[i].exactnessLevel===0?<span><Translate id="preciseSearch.caption" /></span>:
                                      bitui[i].exactnessLevel===1?<span ><Translate id="somewhatExact" /></span>:
                                      bitui[i].exactnessLevel===2?<span ><Translate id="somewhatFuzzy"/></span>:
                                      bitui[i].exactnessLevel===3?<span ><Translate id="fuzzySearch.caption"/></span>:""}</div>
                })
                
              }
              var NikudAndTaamim={
                id:data.children.length+1,
                name:<div title={true?translate("advTabVowelizeCantillate"):""}><b><Translate id="advTabVowelizeCantillate"/></b></div>,
                isExpanded:true,
                isExpandable:true,
                children:[],
              }
              var NikudPerLetter={
                id:NikudAndTaamim.children.length+1,
                name:<div title={true?translate("nikudPerLetter"):""}><b><Translate id="nikudPerLetter"/></b></div>,
                isExpanded:true,
                isExpandable:true,
                children:[],
              }
              bitui[i].letterNekudos.map((letterNikud,i)=>{//todo: fix the id of special characters-it is not always 18, 19, 20
                        /*
                        BUG: THE NUMBER THAT IS PASSED IN FOR THE X:NO NEKUDA, 
                        *:ALL NEKUDOS, AND ?:ALL NEKUDA IS NOT CONSISTANT. 
                        IN NEKUDA BY LETTER, THE SEARCH CRITERIA MAY NOT BE CORRECT 
                        BECAUSE IT WORKS BY SPECIFIC ID. WHEN THERE IS NO SHIN OR SIN 
                        OR MAPIK HEY, THOSE SPECIAL CHARACTERS HAVE A LOWER ID 
                        AND THE CRITERIA READS IT AS A SHIN OR SIN...*/
                var array=[];
                if(letterNikud.includes(1))array.push(" שוא")
                if(letterNikud.includes(2))array.push(" חטף סגול")
                if(letterNikud.includes(3))array.push(" חטף פתח")
                if(letterNikud.includes(4))array.push(" חטף קמץ")
                if(letterNikud.includes(5))array.push(" חיריק")
                if(letterNikud.includes(6))array.push(" צירי")
                if(letterNikud.includes(7))array.push(" סגול")
                if(letterNikud.includes(8))array.push(" פתח")
                if(letterNikud.includes(9))array.push(" קמץ")
                if(letterNikud.includes(10))array.push(" חולם")
                if(letterNikud.includes(11))array.push(" חולם חסר")
                if(letterNikud.includes(12))array.push(" קבוץ")
                if(letterNikud.includes(13))array.push(" שורוק")
                if(letterNikud.includes(14))array.push(" דגש")
                if(letterNikud.includes(15))array.push(" מפיק")
                if(letterNikud.includes(16))array.push(" שין ימיני")
                if(letterNikud.includes(17))array.push(" שין שמאלי")
                if(letterNikud.includes(18))array.push(translate("noNekuda"))
                if(letterNikud.includes(19))array.push(translate("allNekudos?"))
                if(letterNikud.includes(20))array.push(translate("allNekudos*"))

                if(letterNikud.length>0){
                  NikudPerLetter.children.push({
                    id:NikudPerLetter.children.length+1,
                    name:<div title={array.toString()}><span><Translate id="nikudPerLetterName"/>&nbsp;{i+1}: {array.toString()}</span></div>
                  })
                }
              })
              if(NikudPerLetter.children.length>0){
                NikudAndTaamim.children.push(NikudPerLetter);
              }

              var GeneralNekudos={
                id:NikudAndTaamim.children.length+1,
                name:<div title={translate("nikudGeneral")}><b><Translate id="nikudGeneral"/></b></div>,
                isExpanded:true,
                isExpandable:true,
                children:[],
              }
              bitui[i].nekudos.map((nikud)=>{ 
                var array=[];
                if(nikud.includes(1))array.push(" שוא")
                if(nikud.includes(2))array.push(" חטף סגול")
                if(nikud.includes(3))array.push(" חטף פתח")
                if(nikud.includes(4))array.push(" חטף קמץ")
                if(nikud.includes(5))array.push(" חיריק")
                if(nikud.includes(6))array.push(" צירי")
                if(nikud.includes(7))array.push(" סגול")
                if(nikud.includes(8))array.push(" פתח")
                if(nikud.includes(9))array.push(" קמץ")
                if(nikud.includes(10))array.push(" חולם")
                if(nikud.includes(11))array.push(" חולם חסר")
                if(nikud.includes(12))array.push(" קבוץ")
                if(nikud.includes(13))array.push(" שורוק")
                if(nikud.includes(14))array.push(" דגש")
                if(nikud.includes(15))array.push(" מפיק")
                if(nikud.includes(16))array.push(" שין ימיני")
                if(nikud.includes(17))array.push(" שין שמאלי")
                if(nikud.includes(18))array.push(translate("noNekuda"))//the id is fine here because all of the other nekudos are allowed.
                if(nikud.includes(19))array.push(translate("allNekudos?"))
                if(nikud.includes(20))array.push(translate("allNekudos*"))

                if(nikud.length>0){
                  GeneralNekudos.children.push({
                    id:GeneralNekudos.children.length+1,
                    name:<div title={array.toString()}><Translate id="position"/>&nbsp;{GeneralNekudos.children.length+1} <Translate id="nekudos"/>:&nbsp;{array.toString()}</div>
                  })
                }
              })
              if(GeneralNekudos.children.length>0){
                NikudAndTaamim.children.push(GeneralNekudos);
              }

              var GeneralTaamim={
                id:NikudAndTaamim.children.length+1,
                name:<div title={translate("tataama")}><b><Translate id="hataama"/></b></div>,
                isExpanded:true,
                isExpandable:true,
                children:[],
              }

              var taamResponse = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getTaamim?source=bhsWebApp');
              var taamBody = await taamResponse.json()
              bitui[i].taamim.map((taam)=>{ 
              var taamArray=[];
                if(taam.length>0){
                  for(let j=0; j<taam.length;j++){
                    if(taam[j]===60){
                      taamArray.push(" כל טעמים *")
                    }else if(taam[j]===59){
                      taamArray.push(" כל טעם ?")
                    }else if(taam[j]===58){
                      taamArray.push(" ללא טעם X")
                    }
                    else{
                      for(let i=0; i<taamBody.length; i++){
                        if(taamBody[i].id===taam[j]){
                          taamArray.push(" "+taamBody[i].name)
                        }
                      }
                    }
                  }
                  GeneralTaamim.children.push({
                    id:GeneralTaamim.children.length+1,
                    name:<div title={taamArray.toString()}><Translate id="position"/> {GeneralTaamim.children.length+1} <Translate id="hataama"/>: {taamArray.toString()}</div>
                  })
                }
              })
              if(GeneralTaamim.children.length>0){
                NikudAndTaamim.children.push(GeneralTaamim);
              }
              if(NikudAndTaamim.children.length>0){
                data.children.push(NikudAndTaamim);
              }
               
              var PartsOfSpeech=
                {
                  id:data.children.length+1,
                  name:<div title={translate("advTabPOS")}><b><Translate id="advTabPOS"/></b></div>,
                  isExpandable:true,
                  isExpanded:true,
                  children:[],  
                }
              
              if(bitui[i].atbash && (bitui[i].atbash.min>2 || bitui[i].atbash.max<1390)){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={bitui[i].atbash.min+"-"+bitui[i].atbash.max}><Translate id="atbash"/>: {bitui[i].atbash.min}-{bitui[i].atbash.max}</div>
                })
              }
              if(bitui[i].gematria && (bitui[i].gematria.min>3 || bitui[i].gematria.max<1640)){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={bitui[i].gematria.min+"-"+bitui[i].gematria.max}><Translate id="gematria"/>: {bitui[i].gematria.min}-{bitui[i].gematria.max}</div>
                })
              }
              if(bitui[i].frequency && (bitui[i].frequency.min>1 || bitui[i].frequency.max<1000)){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={bitui[i].frequency.min+"-"+bitui[i].frequency.max}><Translate id="frequency.caption"/>: {bitui[i].frequency.min}-{bitui[i].frequency.max}</div>
                })
              }
              if(bitui[i].lettersQty && (bitui[i].lettersQty.min>1 || bitui[i].lettersQty.max<11)){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={bitui[i].lettersQty.min+"-"+bitui[i].lettersQty.max}><Translate id="lettersPerWord"/>: {bitui[i].lettersQty.min}-{bitui[i].lettersQty.max}</div>
                })
              }
              if(bitui[i].tenuosQty && (bitui[i].tenuosQty.min>0 || bitui[i].tenuosQty.max<6)){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={bitui[i].tenuosQty.min+"-"+bitui[i].tenuosQty.max}><Translate id="tenuosPerWord"/>: {bitui[i].tenuosQty.min}-{bitui[i].tenuosQty.max}</div>
                })
              }
              if(bitui[i].vowelsQty && (bitui[i].vowelsQty.min>0 || bitui[i].vowelsQty.max<9)){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={bitui[i].vowelsQty.min+"-"+bitui[i].vowelsQty.max}><Translate id="vowelsPerWord"/>: {bitui[i].vowelsQty.min}-{bitui[i].vowelsQty.max}</div>
                })
              }
              if(bitui[i].languages && bitui[i].languages.includes(1)&& bitui[i].languages.includes(3)){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={translate("hebrew")+", "+translate("aramaic")}><Translate id="language"/>: <Translate id="hebrew"/>, <Translate id="aramaic"/></div>
                })
              }else if(bitui[i].languages && bitui[i].languages.includes(i)){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={translate("hebrew")}><Translate id="language"/>: <Translate id="hebrew"/></div>
                })
              }else if(bitui[i].languages && bitui[i].languages.includes(3)){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={translate("aramaic")}><Translate id="language"/>: <Translate id="aramaic"/></div>
                })
              }
              if(bitui[i].hasInfix ){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={translate("yes")}><Translate id="infix"/>: <Translate id="yes"/></div>
                })
              }else if(bitui[i].hasInfix===false){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={translate("no")}><Translate id="infix"/>: <Translate id="no"/></div>
                })
              }
              if(bitui[i].hasSuffix ){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={translate("yes")}><Translate id="suffix"/>: <Translate id="yes"/></div>
                })
              }else if(bitui[i].hasSuffix===false){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={translate("no")}><Translate id="suffix"/>: <Translate id="no"/></div>
                })
              }
              if(bitui[i].prefixes.length>0){
                var array=[];
                if(bitui[i].prefixes.includes(-2))array.push(translate("noPrefix"))
                if(bitui[i].prefixes.includes(-1))array.push(translate("allPrefix"))
                if(bitui[i].prefixes.includes(1))array.push(translate("article"))
                if(bitui[i].prefixes.includes(2))array.push(translate("question"))
                if(bitui[i].prefixes.includes(3))array.push(translate("conjunctionAffix"))
                if(bitui[i].prefixes.includes(4))array.push(translate("inversion"))
                if(bitui[i].prefixes.includes(5))array.push(translate("2Preposition"))
                if(bitui[i].prefixes.includes(6))array.push(translate("20Preposition"))
                if(bitui[i].prefixes.includes(7))array.push(translate("30Preposition"))
                if(bitui[i].prefixes.includes(8))array.push(translate("40Preposition"))
                if(bitui[i].prefixes.includes(9))array.push(translate("1Future"))
                if(bitui[i].prefixes.includes(10))array.push(translate("10Future"))
                if(bitui[i].prefixes.includes(11))array.push(translate("50Future"))
                if(bitui[i].prefixes.includes(12))array.push(translate("400Future"))
                if(bitui[i].prefixes.includes(13))array.push(translate("50Past"))
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={array.toString()}><Translate id="prefix"/>:&nbsp;{array.toString()}</div>
                })
              }
              if(bitui[i].numberCategories.length>0){
                var array=[];
                  if(bitui[i].numberCategories.includes(1))array.push(translate("mispar"))
                  if(bitui[i].numberCategories.includes(2))array.push(translate("cardinal"))
                  if(bitui[i].numberCategories.includes(3))array.push(translate("ordinal"))
                  if(bitui[i].numberCategories.includes(4))array.push(translate("fractional"))
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={array.toString()}><Translate id="numberCategories"/>:&nbsp;{array.toString()}</div>
                })
              }
              if(bitui[i].objectCategories.length>0){
                var array=[];
                  if(bitui[i].objectCategories.includes(10)) array.push(translate("famFam"))
                  if(bitui[i].objectCategories.includes(1))array.push(translate("personMan"))
                  if(bitui[i].objectCategories.includes(9))array.push(translate("GdGd"))
                  if(bitui[i].objectCategories.includes(2))array.push(translate("personWoman"))
                  if(bitui[i].objectCategories.includes(4))array.push(translate("placeLand"))
                  if(bitui[i].objectCategories.includes(5))array.push(translate("placeMtn"))
                  if(bitui[i].objectCategories.includes(6))array.push(translate("placeRiver"))
                  if(bitui[i].objectCategories.includes(3))array.push(translate("placePlace"))
                  if(bitui[i].objectCategories.includes(7))array.push(translate("placeCity"))
                  if(bitui[i].objectCategories.includes(8))array.push(translate("nameName"))
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={array.toString()}><Translate id="objectCategory"/>:&nbsp;{array.toString()}</div>
                })
              }
              if(bitui[i].wordTypes.length>0){
                  var array=[];
                  if(bitui[i].wordTypes.includes(-1)) array.push(translate("uncategorized"))
                  if(bitui[i].wordTypes.includes(1)) array.push(translate("noun"))
                  if(bitui[i].wordTypes.includes(9))array.push(translate("pronoun"))
                  if(bitui[i].wordTypes.includes(2))array.push(translate("verb"))
                  if(bitui[i].wordTypes.includes(3))array.push(translate("adj"))
                  if(bitui[i].wordTypes.includes(4))array.push(translate("adverb"))
                  if(bitui[i].wordTypes.includes(5))array.push(translate("preposition"))
                  if(bitui[i].wordTypes.includes(6))array.push(translate("conjunction"))
                  if(bitui[i].wordTypes.includes(7))array.push(translate("interjection"))
                  if(bitui[i].wordTypes.includes(8))array.push(translate("determiner"))

                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={array.toString()}><Translate id="wordTypes"/>:&nbsp;{array.toString()} </div>
                })
              }
              if(bitui[i].binyanim.length>0){
                var array=[];
                  if(bitui[i].binyanim.includes(1))array.push(translate("simpleActive"))
                  if(bitui[i].binyanim.includes(2))array.push(translate("simplePassive"))
                  if(bitui[i].binyanim.includes(3))array.push(translate("causativeActive"))
                  if(bitui[i].binyanim.includes(4))array.push(translate("causativePassive"))
                  if(bitui[i].binyanim.includes(5))array.push(translate("intensiveActive"))
                  if(bitui[i].binyanim.includes(6))array.push(translate("intensivePassive"))
                  if(bitui[i].binyanim.includes(7))array.push(translate("reflexive"))
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={array.toString()}><Translate id="binyan"/>:&nbsp;{array.toString()}</div>
                  
                })
              }
              if(bitui[i].genders.length>0){
                var array=[];
                  if(bitui[i].genders.includes(1))array.push(translate("male"))
                  if(bitui[i].genders.includes(2))array.push(translate("female"))
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={array.toString()}><Translate id="gender"/>:&nbsp;{array.toString()}</div>
                })
              }
              if(bitui[i].gendersSof.length>0){
                var array=[];
                  if(bitui[i].gendersSof.includes(1))array.push(translate("male"))
                  if(bitui[i].gendersSof.includes(2))array.push(translate("female"))
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={array.toString()}><Translate id="gender"/>&nbsp;<Translate id="suffixPOS"/>:&nbsp;{array.toString()}</div>
                })
              }
              if(bitui[i].gufim.length>0){
                var array=[];
                  if(bitui[i].gufim.includes(1))array.push(translate("firstPerson"))
                  if(bitui[i].gufim.includes(2))array.push(translate("secondPerson"))
                  if(bitui[i].gufim.includes(3))array.push(translate("thirdPerson"))
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={array.toString()}><Translate id="guf"/>:&nbsp;{array.toString()}</div>
                })
              }
              if(bitui[i].gufimSof.length>0){
                var array=[];
                  if(bitui[i].gufimSof.includes(1))array.push(translate("firstPerson"))
                  if(bitui[i].gufimSof.includes(2))array.push(translate("secondPerson"))
                  if(bitui[i].gufimSof.includes(3))array.push(translate("thirdPerson"))
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={array.toString()}><Translate id="guf"/>&nbsp;<Translate id="suffixPOS"/>:&nbsp;{array.toString()}</div>
                })
              }
              if(bitui[i].misparim.length>0){
                var array=[];
                  if(bitui[i].misparim.includes(1))array.push(translate("singular"))
                  if(bitui[i].misparim.includes(2))array.push(translate("plural"))
                  if(bitui[i].misparim.includes(3))array.push(translate("dual"))
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={array.toString()}><Translate id="mispar"/>:&nbsp;{array.toString()}</div>
                })
              }
              if(bitui[i].misparimSof.length>0){
                var array=[];
                  if(bitui[i].misparimSof.includes(1))array.push(translate("singular"))
                  if(bitui[i].misparimSof.includes(2))array.push(translate("plural"))
                  if(bitui[i].misparimSof.includes(3))array.push(translate("dual"))
                  let mispar=<Translate id="mispar"/>
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={array.toString()}>{mispar}&nbsp;<Translate id="suffixPOS"/>:&nbsp;{array.toString()}</div>
                })
              }
              if(bitui[i].zmanim.length>0){
                var array=[];
                  if(bitui[i].zmanim.includes(1))array.push(translate("past"))
                  if(bitui[i].zmanim.includes(2))array.push(translate("present"))
                  if(bitui[i].zmanim.includes(3))array.push(translate("future"))
                  if(bitui[i].zmanim.includes(4))array.push(translate("imperitive"))
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1,
                  name:<div title={array.toString()}><Translate id="zman"/>:&nbsp;{array.toString()}</div>
                })
              }

              if(PartsOfSpeech.children.length>0){
                data.children.push(PartsOfSpeech);
              }
              var Distances={
                id:data.children.length+1,
                name:<div title={translate("advTabDistances")}><b><Translate id="advTabDistances"/></b></div>,
                isExpanded:true,
                isExpandable:true,
                children:setUpDistancesPerWord(bitui[i],inLeftBar)
              }

              if(Distances.children.length>0){
                data.children.push(Distances);
              }

              if(!data.children.length>0){
                data={
                  id:dataArray.length+1,
                  name:<div title={bitui[i].term}><Translate id="searchTerm"/>{bitui[i].term}</div>,
                }
              }
              dataArray.push(data)
        }
        return dataArray;

        
      }
    function setUpDistancesPerWord(termParameters,inLeftBar){
        var words=<Translate id="wordUnit"></Translate>
        var letters=<Translate id="letterUnit"></Translate>
        var pesukim=<Translate id="pasukUnit"></Translate>
        var parshios=<Translate id="parshaUnit"></Translate>
        var data=[];
        if(termParameters.containerDistances && (termParameters.containerDistances[0].distance1 > 0 || termParameters.containerDistances[0].distance2 < 100)){
          data.push({
            id:data.length+1,
            name:<div title={termParameters.containerDistances[0].distance1+"-"+termParameters.containerDistances[0].distance2}><Translate id="distanceToPasukEnd"/>: {termParameters.containerDistances[0].distance1}-{termParameters.containerDistances[0].distance2} {termParameters.containerDistances[0].unitLevelId===1?letters:words}</div>
          })
        }
        if(termParameters.containerDistances && (termParameters.containerDistances[1].distance1 > 0 || termParameters.containerDistances[1].distance2 < 100)){
          data.push({
            id:data.length+1,
            name:<div title={termParameters.containerDistances[1].distance1+"-"+termParameters.containerDistances[1].distance2}><Translate id="distanceToPasukStart"/>: {termParameters.containerDistances[1].distance1}-{termParameters.containerDistances[1].distance2} {termParameters.containerDistances[1].unitLevelId===1?letters:words}</div>
          })
        }
        if(termParameters.containerDistances && (termParameters.containerDistances[2].distance1 > 0 || termParameters.containerDistances[2].distance2 < 100)){
          data.push({
            id:data.length+1,
            name:<div title={termParameters.containerDistances[2].distance1+"-"+termParameters.containerDistances[2].distance2}><Translate id="distanceToParshaEnd"/>: {termParameters.containerDistances[2].distance1}-{termParameters.containerDistances[2].distance2} {termParameters.containerDistances[2].unitLevelId===1?letters:termParameters.containerDistances[2].unitLevelId===2?words:pesukim}</div>
          })
        }
        if(termParameters.containerDistances && (termParameters.containerDistances[3].distance1 > 0 || termParameters.containerDistances[3].distance2 < 100)){
          data.push({
            id:data.length+1,
            name:<div title={termParameters.containerDistances[3].distance1+"-"+termParameters.containerDistances[3].distance2}><Translate id="distanceToParshaStart"/>: {termParameters.containerDistances[3].distance1}-{termParameters.containerDistances[3].distance2} {termParameters.containerDistances[3].unitLevelId===1?letters:termParameters.containerDistances[3].unitLevelId===2?words:pesukim}</div>
          })
        }
        if(termParameters.containerDistances && (termParameters.containerDistances[4].distance1 > 0 || termParameters.containerDistances[4].distance2 < 100)){
          data.push({
            id:data.length+1,
            name:<div title={termParameters.containerDistances[4].distance1+"-"+termParameters.containerDistances[4].distance2}><Translate id="distanceToSeferEnd"/>: {termParameters.containerDistances[4].distance1}-{termParameters.containerDistances[4].distance2} {termParameters.containerDistances[4].unitLevelId===1?letters:termParameters.containerDistances[4].unitLevelId===2?words:termParameters.containerDistances[4].unitLevelId===3?pesukim:parshios}</div>
          })
        }
        if(termParameters.containerDistances && (termParameters.containerDistances[5].distance1 > 0 || termParameters.containerDistances[5].distance2 < 100)){
          data.push({
            id:data.length+1,
            name:<div title={termParameters.containerDistances[5].distance1+"-"+termParameters.containerDistances[5].distance2}><Translate id="distanceToSeferStart"/>: {termParameters.containerDistances[5].distance1}-{termParameters.containerDistances[5].distance2} {termParameters.containerDistances[5].unitLevelId===1?letters:termParameters.containerDistances[5].unitLevelId===2?words:termParameters.containerDistances[5].unitLevelId===3?pesukim:parshios}</div>
          })
        }
        return data;
      }

    function setUpPlacement(params,placementRowData,translate,inLeftBar){
      var data=[];

      for(let i=0; i<placementRowData.length-1; i++){
        if(placementRowData[i].type===true){//if it is a namedLocation

          var selectedVal1 = placementRowData[i].valList1.filter(obj => {return obj.id === placementRowData[i].val1});
          var selectedVal2 = placementRowData[i].valList2.filter(obj => {return obj.id === placementRowData[i].val2});
          var selectedVal3 = placementRowData[i].valList3.filter(obj => {return obj.id === placementRowData[i].val3});
          var selectedVal4 = placementRowData[i].valList4.filter(obj => {return obj.id === placementRowData[i].val4});
          var selectedVal5 = placementRowData[i].valList5.filter(obj => {return obj.id === placementRowData[i].val5});
  
          // var kria = placementRowData[i].valList4.filter(obj => {return obj.id === placementRowData[i].val4});
          // var aliya = placementRowData[i].valList5.filter(obj => {return obj.id === placementRowData[i].val5});
          var name=`${selectedVal1[0]?selectedVal1[0].value+" > ":''}${selectedVal2[0]?selectedVal2[0].value+" > ":''}${selectedVal3[0]?selectedVal3[0].value+" > ":''}${selectedVal4[0]? selectedVal5[0]? selectedVal4[0].value+" > ":selectedVal4[0].value:''}${selectedVal5[0]?selectedVal5[0].value:''}`
          data.push({
              id:data.length+1,
              name:<div title={name}>{name}</div>,
            })
        }else{//if it is a range
          var selectedVal1 = placementRowData[i].valList1.filter(obj => {return obj.seferId === placementRowData[i].val1});
          var selectedVal2 = placementRowData[i].valList2.filter(obj => {return obj.perekNum === placementRowData[i].val2});
          var selectedVal3 = placementRowData[i].valList3.filter(obj => {return obj.pasukNum === placementRowData[i].val3});
          var selectedVal4 = placementRowData[i].valList4.filter(obj => {return obj.seferId === placementRowData[i].val4});
          var selectedVal5 = placementRowData[i].valList5.filter(obj => {return obj.perekNum === placementRowData[i].val5});
          var selectedVal6 = placementRowData[i].valList6.filter(obj => {return obj.pasukNum === placementRowData[i].val6});
          var name=(selectedVal1[0].value +" "+selectedVal2[0].value+" : "+selectedVal3[0].value+" "+translate("to")+" "+selectedVal4[0].value+" "+selectedVal5[0].value+" : "+selectedVal6[0].value)
          data.push({
            id:data.length+1,
            name: <div title={name}>{name}</div>,
          })
        }
      }

      for(let i=0;i<params.termDistances.length; i++){
        if(params.termDistances[i].distance1!==0 || params.termDistances[i].distance2 !== 100){
          var term = params.termDistances[i].unitLevelId===1?translate("letterUnit"):
                      params.termDistances[i].unitLevelId===2?translate("wordUnit"):
                      params.termDistances[i].unitLevelId===3?translate("pasukUnit"):translate("parshaUnit");
            var name= `${params.termDistances[i].directionId===0?`The distance in ${term} between word ${params.termDistances[i].termPosition1} and word ${params.termDistances[i].termPosition2} is between ${params.termDistances[i].distance1} and ${params.termDistances[i].distance2}.`:
                  `${params.termDistances[i].directionId===-1?`Word ${params.termDistances[i].termPosition1} is at least ${params.termDistances[i].distance1} ${term} and at most ${params.termDistances[i].distance2} ${term} after ${params.termDistances[i].termPosition2}.`:
                                                              `Word ${params.termDistances[i].termPosition1} is at least ${params.termDistances[i].distance1} ${term} and at most ${params.termDistances[i].distance2} ${term} before ${params.termDistances[i].termPosition2}.`}`}`//todo: I still have to translate this.
          data.push({
            id: data.length+1,
            name:<div title={name}>{name}</div>
          })
        }
      }

      if(data.length>0){
        return data;
      }
      return null;
    }

    async function setUpPasukSettings(params,inLeftBar){
        var data=[];
        if(params.includeExcludeLNTs[0].ids.length>0){
          data.push({
            id:data.length+1,
            name:<div title={params.includeExcludeLNTs[0].ids.toString()}><Translate id="lettersToInclude"/>: {params.includeExcludeLNTs[0].ids.toString()}</div>
          })
        }
        if(params.includeExcludeLNTs[1].ids.length>0){
          var array=[];
          if(params.includeExcludeLNTs[1].ids.includes(1))array.push(" שוא")
          if(params.includeExcludeLNTs[1].ids.includes(2))array.push(" חטף סגול")
          if(params.includeExcludeLNTs[1].ids.includes(3))array.push(" חטף פתח")
          if(params.includeExcludeLNTs[1].ids.includes(4))array.push(" חטף קמץ")
          if(params.includeExcludeLNTs[1].ids.includes(5))array.push(" חיריק")
          if(params.includeExcludeLNTs[1].ids.includes(6))array.push(" צירי")
          if(params.includeExcludeLNTs[1].ids.includes(7))array.push(" סגול")
          if(params.includeExcludeLNTs[1].ids.includes(8))array.push(" פתח")
          if(params.includeExcludeLNTs[1].ids.includes(9))array.push(" קמץ")
          if(params.includeExcludeLNTs[1].ids.includes(10))array.push(" חולם")
          if(params.includeExcludeLNTs[1].ids.includes(11))array.push(" חולם חסר")
          if(params.includeExcludeLNTs[1].ids.includes(12))array.push(" קבוץ")
          if(params.includeExcludeLNTs[1].ids.includes(13))array.push(" שורוק")
          if(params.includeExcludeLNTs[1].ids.includes(14))array.push(" דגש")
          if(params.includeExcludeLNTs[1].ids.includes(15))array.push(" מפיק")
          if(params.includeExcludeLNTs[1].ids.includes(16))array.push(" שין ימיני")
          if(params.includeExcludeLNTs[1].ids.includes(17))array.push(" שין שמאלי")
          data.push({
            id:data.length+1,
            name:<div title={array.toString()}><Translate id="nekudosToInclude"/>: {array.toString()}</div>
          })
        }

        var taamResponse = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getTaamim?source=bhsWebApp');
        var taamBody = await taamResponse.json()

        if(params.includeExcludeLNTs[2].ids.length>0){
          var taamArray=[];
          for(let j=0; j<params.includeExcludeLNTs[2].ids.length; j++){
            for(let i=0; i<taamBody.length; i++){
              if(taamBody[i].id===params.includeExcludeLNTs[2].ids[j]){
                taamArray.push(" "+taamBody[i].name)
              }
            }
          }
          data.push({
            id:data.length+1,
            name:<div title={taamArray.toString()}><Translate id="taamimToInclude"/>: {taamArray.toString()}</div>
          })
        }
        if(params.includeExcludeLNTs[3].ids.length>0){
          data.push({
            id:data.length+1,
            name:<div title={params.includeExcludeLNTs[3].ids.toString()}><Translate id="lettersToExclude"/>: {params.includeExcludeLNTs[3].ids.toString()}</div>
          })
        }
        if(params.includeExcludeLNTs[4].ids.length>0){
          var array=[];
            if(params.includeExcludeLNTs[4].ids.includes(1))array.push(" שוא")
            if(params.includeExcludeLNTs[4].ids.includes(2))array.push(" חטף סגול")
            if(params.includeExcludeLNTs[4].ids.includes(3))array.push(" חטף פתח")
            if(params.includeExcludeLNTs[4].ids.includes(4))array.push(" חטף קמץ")
            if(params.includeExcludeLNTs[4].ids.includes(5))array.push(" חיריק")
            if(params.includeExcludeLNTs[4].ids.includes(6))array.push(" צירי")
            if(params.includeExcludeLNTs[4].ids.includes(7))array.push(" סגול")
            if(params.includeExcludeLNTs[4].ids.includes(8))array.push(" פתח")
            if(params.includeExcludeLNTs[4].ids.includes(9))array.push(" קמץ")
            if(params.includeExcludeLNTs[4].ids.includes(10))array.push(" חולם")
            if(params.includeExcludeLNTs[4].ids.includes(11))array.push(" חולם חסר")
            if(params.includeExcludeLNTs[4].ids.includes(12))array.push(" קבוץ")
            if(params.includeExcludeLNTs[4].ids.includes(13))array.push(" שורוק")
            if(params.includeExcludeLNTs[4].ids.includes(14))array.push(" דגש")
            if(params.includeExcludeLNTs[4].ids.includes(15))array.push(" מפיק")
            if(params.includeExcludeLNTs[4].ids.includes(16))array.push(" שין ימיני")
            if(params.includeExcludeLNTs[4].ids.includes(17))array.push(" שין שמאלי")
          data.push({
            id:data.length+1,
            name:<div title={array.toString()}><Translate id="nekudosToExclude"/>: {array.toString()}</div>
          })
        }
        if(params.includeExcludeLNTs[5].ids.length>0){
          var taamArray2=[];
          for(let j=0; j<params.includeExcludeLNTs[5].ids.length; j++){
            for(let i=0; i<taamBody.length; i++){
              if(taamBody[i].id===params.includeExcludeLNTs[5].ids[j]){
                taamArray2.push(" "+taamBody[i].name)
              }
            }
          }
          data.push({
            id:data.length+1,
            name:<div title={taamArray2.toString()}><Translate id="taamimToExclude"/>: {taamArray2.toString()}</div>
          })
        }
        if(params.wordsPerPasuk.min!==2 || params.wordsPerPasuk.max!==64){
          data.push({
            id:data.length+1,
            name:<div title={params.wordsPerPasuk.min+"-"+params.wordsPerPasuk.max}><Translate id="wordsPerPasuk"/>: {params.wordsPerPasuk.min}-{params.wordsPerPasuk.max}</div>
          })
        }
        if(params.lettersPerPasuk.min!==6 || params.lettersPerPasuk.max!==254){
          data.push({
            id:data.length+1,
            name:<div title={params.lettersPerPasuk.min+"-"+params.lettersPerPasuk.max}><Translate id="lettersPerPasuk"/>: {params.lettersPerPasuk.min}-{params.lettersPerPasuk.max}</div>
          })
        }
        if(params.lettersPerWord.min!==2.5 || params.lettersPerWord.max!==8){
          data.push({
            id:data.length+1,
            name:<div title={params.lettersPerWord.min+"-"+params.lettersPerWord.max}><Translate id="letters"/> <Translate id="perWord"/>: {params.lettersPerWord.min}-{params.lettersPerWord.max}</div>
          })
        }
        if(params.uniqueLetter.min!==5 || params.uniqueLetter.max!==22){
          data.push({
            id:data.length+1,
            name:<div title={params.uniqueLetter.min+"-"+params.uniqueLetter.max}><Translate id="byUniqueLetters"/>: {params.uniqueLetter.min}-{params.uniqueLetter.max}</div>
          })
        }
        if(params.uniqueLetterCS.min!==5 || params.uniqueLetterCS.max!==27){
          data.push({
            id:data.length+1,
            name:<div title={params.uniqueLetterCS.min+"-"+params.uniqueLetterCS.max}><Translate id="byUniqueLettersCS"/>: {params.uniqueLetterCS.min}-{params.uniqueLetterCS.max}</div>
          })
        }
        if(params.tenuosPerPasuk.min!==4 || params.tenuosPerPasuk.max!==96){
          data.push({
            id:data.length+1,
            name:<div title={params.tenuosPerPasuk.min+"-"+params.tenuosPerPasuk.max}><Translate id="tenuosPerPasuk"/>: {params.tenuosPerPasuk.min}-{params.tenuosPerPasuk.max}</div>
          })
        }
        if(params.tenuosPerWord.min!==1.25 || params.tenuosPerWord.max!==4.5){
          data.push({
            id:data.length+1,
            name:<div title={params.tenuosPerWord.min+"-"+params.tenuosPerWord.max}><Translate id="tenuos"/> <Translate id="perWord"/>: {params.tenuosPerWord.min}-{params.tenuosPerWord.max}</div>
          })
        }
        if(params.tenuosPerLetter.min!==.31 || params.tenuosPerLetter.max!==.75){
          data.push({
            id:data.length+1,
            name:<div title={params.tenuosPerLetter.min+"-"+params.tenuosPerLetter.max}><Translate id="tenuos"/> <Translate id="perLetter"/>: {params.tenuosPerLetter.min}-{params.tenuosPerLetter.max}</div>
          })
        }
        if(params.taamimPerPasuk.min!==2 || params.taamimPerPasuk.max!==35){
          data.push({
            id:data.length+1,
            name:<div title={params.taamimPerPasuk.min+"-"+params.taamimPerPasuk.max}><Translate id="taamimPerPasuk"/>: {params.taamimPerPasuk.min}-{params.taamimPerPasuk.max}</div>
          })
        }
        if(params.taamimPerWord.min!==.43 || params.taamimPerWord.max!==1.5){
          data.push({
            id:data.length+1,
            name:<div title={params.taamimPerWord.min+"-"+params.taamimPerWord.max}><Translate id="taamim"/> <Translate id="perWord"/>: {params.taamimPerWord.min}-{params.taamimPerWord.max}</div>
          })
        }
        if(params.taamimPerTenua.min!==.22 || params.taamimPerTenua.max!==.82){
          data.push({
            id:data.length+1,
            name:<div title={params.taamimPerTenua.min+"-"+params.taamimPerTenua.max}><Translate id="taamim"/> <Translate id="perTenua"/>: {params.taamimPerTenua.min}-{params.taamimPerTenua.max}</div>
          })
        }
        if(params.gematriaPerPasuk.min!==160 || params.gematriaPerPasuk.max!==18377){
          data.push({
            id:data.length+1,
            name:<div title={params.gematriaPerPasuk.min+"-"+params.gematriaPerPasuk.max}><Translate id="gematria"/>: {params.gematriaPerPasuk.min}-{params.gematriaPerPasuk.max}</div>
          })
        }
        if(params.gematriaPerWord.min!==48.8 || params.gematriaPerWord.max!==731.3){
          data.push({
            id:data.length+1,
            name:<div title={params.gematriaPerWord.min+"-"+params.gematriaPerWord.max}><Translate id="gematria"/> <Translate id="perWord"/>: {params.gematriaPerWord.min}-{params.gematriaPerWord.max}</div>
          })
        }
        if(params.gematriaPerLetter.min!==13.8 || params.gematriaPerLetter.max!==164.8){
          data.push({
            id:data.length+1,
            name:<div title={params.gematriaPerLetter.min+"-"+params.gematriaPerLetter.max}><Translate id="gematria"/> <Translate id="perLetter"/>: {params.gematriaPerLetter.min}-{params.gematriaPerLetter.max}</div>
          })
        }
        if(params.atbashPerPasuk.min!==268 || params.atbashPerPasuk.max!==19145){
          data.push({
            id:data.length+1,
            name:<div title={params.atbashPerPasuk.min+"-"+params.atbashPerPasuk.max}><Translate id="atbash"/>: {params.atbashPerPasuk.min}-{params.atbashPerPasuk.max}</div>
          })
        }
        if(params.atbashPerWord.min!==69.5 || params.atbashPerWord.max!==791){
          data.push({
            id:data.length+1,
            name:<div title={params.atbashPerWord.min+"-"+params.atbashPerWord.max}><Translate id="atbash"/> <Translate id="perWord"/>: {params.atbashPerWord.min}-{params.atbashPerWord.max}</div>
          })
        }
        if(params.atbashPerLetter.min!==20.2 || params.atbashPerLetter.max!==173.8){
          data.push({
            id:data.length+1,
            name:<div title={params.atbashPerLetter.min+"-"+params.atbashPerLetter.max}><Translate id="atbash"/> <Translate id="perLetter"/>: {params.atbashPerLetter.min}-{params.atbashPerLetter.max}</div>
          })
        }
        if(data.length>0){
          return data;
        }

        return null;
        
      }
      function seforimToIncludeExclude(filterData, filterDataArrayInc, filterDataArrayExc,inLeftBar){
        var seforimInclude=(filterDataArrayInc != undefined)?filterDataArrayInc:[];//array of seforim to include
        var seforimExclude=(filterDataArrayExc != undefined)?filterDataArrayExc:[];
        filterData.forEach((data)=>{
          if(data.children){
            seforimToIncludeExclude(data.children, seforimInclude, seforimExclude,inLeftBar);
          }else{
            if(data.isChecked){
              seforimInclude.push(" "+data.name);
            } else{
              seforimExclude.push(" "+data.name);
            }
          } 
        })
        if(seforimExclude.length!==0) {
          if(seforimInclude.length>seforimExclude.length){
            return {
              id:6,
              name:<div title={seforimExclude.toString()}><Translate id="seforimToExclude"/>: {seforimExclude.toString()}</div>
            }
          }
          return {
            id:6,
            name:<div title={seforimInclude.toString()}><Translate id="seforimToInclude"/>: {seforimInclude.toString()}</div>
          }
        }
      }

    export async function setUpData(searchParameters,placementRowData,filterData, translate, inLeftBar){
        var sortOrder = (searchParameters.sortOrder===0?translate("byAppearance"):
        searchParameters.sortOrder===1?translate("yearOccurred"): //tanachYear
        searchParameters.sortOrder===2?translate("words"):
        searchParameters.sortOrder===3?translate("letters"):
        searchParameters.sortOrder===4?translate("lettersPerWord"):
        searchParameters.sortOrder===5?translate("byUniqueLetters"):
        searchParameters.sortOrder===6?translate("byUniqueLettersCS"):
        searchParameters.sortOrder===7?translate("nekudos"):
        searchParameters.sortOrder===8?translate("nekudos")+" "+translate("perWord"):
        searchParameters.sortOrder===9?translate("nekudos")+" "+translate("perLetter"):
        searchParameters.sortOrder===10?translate("taamim"):
        searchParameters.sortOrder===11?translate("taamim")+" "+translate("perWord"):
        searchParameters.sortOrder===12?translate("taamim")+" "+translate("perTenua"):
        searchParameters.sortOrder===13?translate("gematria"):
        searchParameters.sortOrder===14?translate("gematria")+" "+translate("perWord"):
        searchParameters.sortOrder===15?translate("gematria")+" "+translate("perLetter"):
        searchParameters.sortOrder===16?translate("atbash"):
        searchParameters.sortOrder===17?translate("atbash")+" "+translate("perWord"):
        searchParameters.sortOrder===18?translate("atbash")+" "+translate("perLetter"):"").concat(searchParameters.sortDirection===1?" ("+(translate("descend"))+")":" ("+(translate("ascend"))+")")
        
        var searchOn= (searchParameters.morphInflections==="L"?translate("letters"):
                      searchParameters.morphInflections==="N"?translate("nekudos"):
                      searchParameters.morphInflections==="T"?translate("taamim"):
                      searchParameters.morphInflections==="LN"?translate("LN"):
                      searchParameters.morphInflections==="LT"?translate("LT"):
                      searchParameters.morphInflections==="NT"?translate("NT"):
                      searchParameters.morphInflections==="LNT"?translate("LNT"):
                      searchParameters.morphInflections==="S"?translate("root"):
                      searchParameters.morphInflections==="G"?translate("gematria"):
                      searchParameters.morphInflections==="A"?translate("atbash"):"")

        var exactnessLevel=(searchParameters.exactnessLevel===0?translate("preciseSearch.caption"):
                          searchParameters.exactnessLevel===1?translate("somewhatExact"):
                          searchParameters.exactnessLevel===2?translate("somewhatFuzzy"):
                          searchParameters.exactnessLevel===3?translate("fuzzySearch.caption"):"")
        var sequence = (searchParameters.isInSequence?translate("yes"):translate("no"))
        var scope = (searchParameters.scope==='any'?translate("no"):translate("yes"))
        var fakeData = [
          {
            id: 1,
            name:<div title={translate("searchPerWord")}><b><Translate id="searchPerWord"/></b></div>,
            isExpandable:true,
            isExpanded: true,
            children: await setUpPerWord(searchParameters.bituiParameters,searchParameters.exactnessLevel,translate,inLeftBar),
          },
          { id:2,
            name:<div title={translate("basicSearchSettings")}><b><Translate id="basicSearchSettings"/></b></div>,
            isExpandable:true,
            isExpanded:true,
            children: [ {
                id:1,
                name: <div title={searchOn}><Translate id="searchOn"/> {searchOn}</div>
              },{
                id:2,
                name: <div title={exactnessLevel}><Translate id="exactness"/>&nbsp;{exactnessLevel}</div>
              },{
                id:3,
                name: <div title={sequence}><Translate id="inOrder.caption"/>? {sequence}</div>
              },{
                id:4,
                name: <div title={scope}><Translate id="everyWord.caption"/>? {scope}</div>
              },{
                id:5,
                name: <div title={sortOrder}><Translate id="sortBy"/>: {sortOrder}</div>
              }                
            ]}
        ]
        var IncludeExclude = seforimToIncludeExclude(filterData,null,null,inLeftBar);
        if(IncludeExclude){
          fakeData[1].children.push(IncludeExclude)
        }
        var pasukSettingsObject={
          id:fakeData.length+1,
          name: <div title={translate("advTabPasukSettings")}><b><Translate id="advTabPasukSettings"/></b></div>,
          isExpandable:true,
          isExpanded:true,
          children:await setUpPasukSettings(searchParameters,inLeftBar),
        }
        if(pasukSettingsObject.children){
          fakeData.push(pasukSettingsObject)
        }
        var placementObject={
          id:fakeData.length+1,
          name:<div title={translate("advTabPlacement")}><b><Translate id="advTabPlacement"/></b></div>,
          isExpanded:true,
          isExpandable:true,
          children:setUpPlacement(searchParameters,placementRowData,translate,inLeftBar),
        }
        if(placementObject.children){
          fakeData.push(placementObject)
        }

        return fakeData;
      }

      export function getFromLocalStorage(key) {
        let ls = {};
        if (global.localStorage) {
          try {
            ls = JSON.parse(global.localStorage.getItem(key));
          } catch (e) {
            ls=null;
          }
        }
        if(ls){
            if(key === "Layouts"){
                return ls.Layouts;
            }
            return ls[key]
        }
        return null;
    }
    
      export function saveToLocalStorage(key, value) {
        if (global.localStorage) {
          global.localStorage.setItem(
            key,
            JSON.stringify({
              [key]: value
            })
          );
        }
    }  
